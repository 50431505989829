export interface HealthService {
  id: string
  userId: string
  description: string
  disposition: HealthServiceDisposition | undefined
  virtualConsults: Service[]
  connectedTests: Service[]
  telehealthConsults: Service[]
  prescriptions: Service[]
  lastUpdatedStep: Service | null
  status: HeathServiceStatus
  updatedAt: string
  createdAt: string
}

export enum HealthServiceDisposition {
  Expired24Hours = 'Expired24Hours',
}

export interface Service {
  id: string
  type: string
  updatedAt: string
  createdAt: string
  discriminator: HealthServiceDiscriminator
}

export type HeathServiceStatus =
  | 'InProgress'
  | 'Completed'
  | 'Cancelled'
  | undefined

export enum HealthServiceType {
  Encounter = 'Encounter',
  VirtualConsult = 'VirtualConsult',
  ConnectedTest = 'ConnectedTest',
  ConnectedTestV1 = 'ConnectedTestV1',
  TelehealthConsultV1 = 'TelehealthConsultV1',
  TelehealthConsult = 'TelehealthConsult',
  TelehealthConsultUnknown = 'TelehealthConsultUnknown',
  Prescription = 'Prescription',
  TreatmentPlan = 'TreatmentPlan',
  Unset = 'Unset',
}

export type HealthServiceDiscriminator =
  | 'VirtualConsult'
  | 'TelehealthConsultV1'
  | 'TelehealthConsult'
  | 'TelehealthConsultUnknown'
  | 'ConnectedTest'
  | 'Prescription'
  | 'Unset'

export type HSType =
  | 'virtualConsults'
  | 'connectedTests'
  | 'telehealthConsults'
  | 'prescriptions'

export enum HSDisposition { //screens for Virtual Consult Early Exit paths
  'Screen 1' = 'Screen 1',
  'Screen 2' = 'Screen 2',
  'Screen 3' = 'Screen 3',
  'Screen 4' = 'Screen 4',
  'Screen 5' = 'Screen 5',
  'Screen 6' = 'Screen 6',
  'Screen 7' = 'Screen 7',
  'Confirm Location/Phone' = 'Confirm Location/Phone',
  'Telehealth Visit' = 'Telehealth Visit',
  'Seek Medical Attention' = 'Seek Medical Attention',
  'More Testing Needed' = 'More Testing Needed', // Not coming in the response
}

export enum EncounterDisposition {
  UNKNOWN = 'UNKNOWN',
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
  CANCELLED = 'CANCELLED',
  TREATMENT_READY = 'TREATMENT_READY',
  MISSED_CALL = 'MISSED_CALL',
  MISSED_CALL_NO_ANSWER = 'MISSED_CALL_NO_ANSWER',
  IN_REVIEW = 'IN_REVIEW',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_CALL = 'IN_CALL',
  COMPLETED = 'COMPLETED',
}

export enum ConsultStatus {
  created = 'created',
  submitted = 'submitted',
  in_progress = 'in_progress',
  cancelled = 'cancelled',
  safe_created = 'safe_created',
  safe_dequeued = 'safe_dequeued',
  safe_cancelled = 'safe_cancelled',
  safe_in_progress = 'safe_in_progress',
  safe_missed = 'safe_missed',
  safe_treatment_ready = 'safe_treatment_ready',
  steadyMD_in_review = 'steadyMD_in_review',
  missed_call_no_answer = 'missed_call_no_answer',
  missed_call_wrong_number = 'missed_call_wrong_number',
  in_call = 'in_call',
  consult_submitted = 'consult_submitted',
  consult_scheduled = 'consult_scheduled',
  session_cancelled = 'session_cancelled',
  treatment_ready = 'treatment_ready',
  treatment_rx_prescribed = 'treatment_rx_prescribed',
  alt_pharm_loc = 'alt_pharm_loc',
  order_created = 'order_created',
  for_pickup = 'for_pickup',
  for_delivery = 'for_delivery',
  completed = 'completed',
  fv_call_missed = 'fv_call_missed',
  fv_completed = 'fv_completed',
  call_missed = 'call_missed',
  no_treatment_required = 'no_treatment_required',
  healthservice_expired = 'healthservice_expired',
  IN_CALL = 'IN_CALL',
  MISSED_CALL = 'MISSED_CALL',
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  ASSIGNED = 'ASSIGNED',
  CANCELLED = 'CANCELLED',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED', // temp will need new encounter status for call finished,

  Easeable_not_assigned = 'Easeable_not_assigned',
  Easeable_in_review = 'Easeable_in_review',
  Easeable_in_call = 'Easeable_in_call',
  Easable_treatment_ready = 'Easable_treatment_ready',

  delivery_created = 'delivery_created', //created or inTransit
  delivery_outForDelivery = 'delivery_outForDelivery', //On its way
  delivery_readyForPickup = 'delivery_readyForPickup', //Arrived
  delivery_delivered = 'delivery_delivered', //Delivered and complete
  delivery_failed = 'delivery_failed', // failure or attemptedDelivery
}

export enum CancellationReason {
  provider_cancelled = 'provider_cancelled',
  rx_cancelled = 'rx_cancelled',
  delivery_cancelled = 'delivery_cancelled',
}

enum CallDetailStatus {
  try_call_member = 'try_call_member',
  member_decline_call = 'member_decline_call',
  member_no_answer = 'member_no_answer',
  call_started = 'call_started',
  call_ended = 'call_ended',
  member_interrupted = 'member_interrupted',
  provider_interrupted = 'provider_interrupted',
}

interface CallEvent {
  eventId: CallDetailStatus
  eventTime: string
}

interface CallDetail {
  type: string
  startTime: string
  endTime: string
  consultId: string
  status: string
  telehealthProvider: string
  callLength: number
  events: CallEvent[]
}

export enum DeliveryStatus {
  quote = 'quote',
  created = 'created',
  confirmed = 'confirmed',
  enroute_to_pickup = 'enroute_to_pickup',
  arrived_at_pickup = 'arrived_at_pickup',
  picked_up = 'picked_up',
  enroute_to_dropoff = 'enroute_to_dropoff',
  arrived_at_dropoff = 'arrived_at_dropoff',
  delivered = 'delivered',
  enroute_to_return = 'enroute_to_return',
  arrived_at_return = 'arrived_at_return',
  returned = 'returned',
  cancelled = 'cancelled',
}

interface ConsultResponseDetails {
  provider: string | null
}

interface ConsultResponseConsult {
  completed_at: string | null
  notes_to_patient: string | null
  provider_answers: ProviderAnswers[] | null
  prescriptions: ConsultPrescription[] | null
}

export interface ProviderAnswers {
  key: string
  value: string
}

interface ConsultResponse {
  details: ConsultResponseDetails | null
  consult: ConsultResponseConsult | null
}

export interface ConsultPrescription {
  _id: string | null
  prescription_id: string | null
  ndc: string | null
  name: string | null
  strength: string | null
  refills: number | null
  days_supply: number | null
  daysSupply: number | null
  sig: string | null
  quantity: number
  dosage_form: string | null
  created_at: string | null
  active_date: string | null
  isOtc: boolean
  pharmacyNCPDPId: string | null
  ncpdpId: string | null
}

export interface SafeProvider {
  firstName: string | null
  middleName: string | null
  lastName: string | null
  title: string | null
}

interface RXData {
  delivery: RXDelivery | null
  details: RXOrderResponseDetails | null
}

interface RXDelivery {
  delivery_status: DeliveryStatus | null
  dropoff_time_estimated: string | null
  tracking_url: string | null
}

interface RXOrderResponse {
  data: RXData | null
  timestamp: number | null
}

interface PharmacyAddress extends Address {
  phone: string | null
}

interface Address {
  line1: string | null
  street_1: string | null
  street_2: string | null
  city: string | null
  state: string | null
  zip: string | null
  country: string | null
}

interface ConsultPharmacy {
  name: string | null
  address: PharmacyAddress | null
}

interface RXOrderResponseDetails {
  pharmacy: ConsultPharmacy | null
}

interface ConsultDataWrapper {
  consult: ConsultData | null
  date: string | null
  title: string | null
}

export interface ConsultData {
  provider: SafeProvider | undefined
  safeConsultId: string | undefined
  _id: string | undefined
  status: ConsultStatus | null
  cancellationReason: CancellationReason | null
  consultRequest: ConsultRequest
  consultResponse: ConsultResponse | null
  isAttestationRequired: boolean
  rxOrderResponse: RXOrderResponse | null
}

interface ConsultRequest {
  data: {
    shipping_address: Address
  }
}
