import { I18nKey } from '../../features/translation/types'
import { InsuranceProviderCompanyData, InsuranceProviderData } from '../register/model/type'

export type SettingsTabs = {
  [index in SettingsTabType]: {
    label_key: I18nKey
    content: () => JSX.Element
    defaultSection: string
  }
}

export enum SettingsTabType {
  ACCOUNT = 'account',
  SECURITY = 'security',
  // SUPPORT = 'support',
  // LEGAL_NOTICES = 'legal-notices',
}

export type AccountSettings = {
  [index in AccountSettingsType]: {
    label_key: I18nKey
    content: () => JSX.Element
  }
}

export enum AccountSettingsType {
  PERSONAL_INFORMATION = 'personal-info',
  MOBILE_NUMBER = 'mobile-number',
  PAYMENT_METHODS = 'payment-method',
  // MY_INSURANCE = 'insurance-settings',
  MY_ADDRESSES = 'my-addresses',
  EMERGENCY_CONTACT = 'emergency-contact',
}

export type SecuritySettings = {
  [index in SecuritySettingsType]: {
    page_title_key: I18nKey
    label_key: I18nKey
    content: () => JSX.Element
  }
}

export enum SecuritySettingsType {
  PASSWORD = 'password',
  MFA = 'mfa-type',
  FORGET_DEVICE = 'forget-device',
}


export interface MenuOption {
  label: string
  onClick: (data: any, event: any) => void
  disabled?: boolean
  isLoading?: boolean
}

export interface PasswordFormValues {
  currentPassword: string
  newPassword: string
  reNewPassword: string
}

export interface ContactFormValues {
  firstName: string
  lastName: string
  email: string
  phone: string
  relationship: string
}
// 615476011
export interface AddressFormValues {
  line1: string
  line2: string
  city: string
  state: string
  zip: string
}

export interface DependentFormValues {
  firstName: string
  lastName: string
  birthDate: Date | null
  sexAtBirth: string
  relationship: string
}

export interface InsuranceTabFormValues {
  insuranceProviderCompany: InsuranceProviderCompanyData | null
  insuranceId: string
  groupNumber: string
  planName: string
  coverageDate: Date | null
  notPolicyHolder: false
  firstName: string
  lastName: string
  phone: string
  dob: Date | null
  relationship: string
  line1: string
  line2: string
  city: string
  state: string
  country: string
  zipCode: string
}
