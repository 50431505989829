import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Drawer as MUIDrawer,
  List,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded'
import React from 'react'
import { hexToRGBA } from '../../libs/helpers'
import { appVersion } from '../../libs/utils'
import { Typography } from '../atoms'

interface DrawerGroup {
  title: string
  id: string
  items: React.FunctionComponent[]
  icon: React.ReactNode
  accordionOnDesktop: boolean
}

interface DrawerProps {
  isOpen: boolean
  isDesktop: boolean
  onDrawerClose: () => void
  anchor?: 'left' | 'right'
  drawerHeader: React.ReactNode
  drawerItems: DrawerGroup[]
}
function getIsEventKeyboard(
  event: React.MouseEvent | React.KeyboardEvent
): event is React.KeyboardEvent {
  return event.type === 'keydown'
}

export const Drawer = ({
  isOpen,
  anchor = 'left',
  onDrawerClose,
  drawerHeader,
  drawerItems,
  isDesktop,
}: DrawerProps) => {
  const classes = useStyles()

  const handleDrawerClose = (e: React.KeyboardEvent | React.MouseEvent) => {
    if (getIsEventKeyboard(e) && (e.key === 'Tab' || e.key === 'Shift')) {
      return
    }
    onDrawerClose()
  }

  const renderFooter = () => {
    if (!isOpen) return null

    return (
      <Typography className={classes.footerText}>
        Powered by Safe Health Systems
      </Typography>
    )
  }

  const getDrawerStyles = () => {
    if (!isDesktop) return

    return `${classes.drawer} ${
      isOpen ? classes.drawerOpen : classes.drawerClose
    }`
  }

  return (
    <MUIDrawer
      anchor={anchor}
      elevation={1}
      open={isDesktop ? true : isOpen}
      onClose={handleDrawerClose}
      variant={isDesktop ? 'permanent' : 'temporary'}
      className={getDrawerStyles()}
      classes={{
        paper: getDrawerStyles(),
      }}
    >
      <Box className={classes.content}>
        <div role="presentation" onKeyDown={handleDrawerClose}>
          {drawerHeader}
          <div className={classes.contentWrapper}>
            {isDesktop ? (
              <DesktopAccordionDrawer
                drawerItems={drawerItems}
                classes={classes}
              />
            ) : (
              <MobileListDrawer
                drawerItems={drawerItems}
                classes={classes}
                handleDrawerClose={handleDrawerClose}
              />
            )}
          </div>
        </div>
        <Box className={classes.footer}>
          {renderFooter()}
          <Typography className={classes.versionText}>
            {appVersion()}
          </Typography>
        </Box>
      </Box>
    </MUIDrawer>
  )
}

const DesktopAccordionDrawer = (props: {
  drawerItems: DrawerGroup[]
  classes: ClassNameMap
}) => {
  const { drawerItems, classes } = props

  const nonAccordionItems = drawerItems.filter(
    (item) => !item.accordionOnDesktop
  )

  const LogoutItem = drawerItems.filter((item) => item.id === 'logout')

  return (
    <>
      {nonAccordionItems.length > 0 && (
        <List style={{ padding: 0 }}>
          {nonAccordionItems.map((group) => {
            if (group.id === 'logout') return null
            return (
              <React.Fragment key={group.id}>
                {group.title && (
                  <Typography className={classes.label}>
                    {group.title}
                  </Typography>
                )}
                {group.items.map((DrawerItem, i) => (
                  <DrawerItem key={`${group.title}-${i}`} />
                ))}
              </React.Fragment>
            )
          })}
        </List>
      )}
      {drawerItems.map((group) => {
        if (!group.accordionOnDesktop) return null

        return (
          <Accordion
            key={group.id}
            style={{ padding: '4px 0px' }}
            elevation={0}
            className={classes.root}
          >
            <AccordionSummary
              expandIcon={
                <ArrowDropDownRoundedIcon className={classes.expandIcon} />
              }
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
                <div className={classes.icon}>{group.icon}</div>
                {group.title && (
                  <Typography className={`${classes.label} desktop`}>
                    {group.title}
                  </Typography>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              {group.items.map((DrawerItem, i) => (
                <DrawerItem key={`${group.id}-${i}`} />
              ))}
            </AccordionDetails>
          </Accordion>
        )
      })}
      {LogoutItem.length > 0 && (
        <List style={{ padding: 0 }}>
          {LogoutItem.map((group) => (
            <React.Fragment key={group.id}>
              {group.items.map((DrawerItem, i) => (
                <DrawerItem key={`${group.title}-${i}`} />
              ))}
            </React.Fragment>
          ))}
        </List>
      )}
    </>
  )
}

const MobileListDrawer = (props: {
  drawerItems: DrawerGroup[]
  classes: ClassNameMap
  handleDrawerClose: (e: React.KeyboardEvent | React.MouseEvent) => void
}) => {
  const { drawerItems, classes, handleDrawerClose } = props

  return (
    <List style={{ padding: 0 }} onClick={handleDrawerClose}>
      {drawerItems.map((group) => (
        <React.Fragment key={group.id}>
          {group.title && (
            <Typography className={classes.label}>{group.title}</Typography>
          )}
          {group.items.map((DrawerItem, i) => (
            <DrawerItem key={`${group.title}-${i}`} />
          ))}
        </React.Fragment>
      ))}
    </List>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    '&::before': {
      opacity: 0,
    },
    '& .MuiAccordionSummary-root': {
      'padding': '0px 32px 0px 24px',
      '&:hover': {
        backgroundColor: hexToRGBA(theme.palette.primary.main, 0.05),
      },
    },
    '& .MuiPaper-elevation': {
      border: 'none !important',
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
  },
  contentWrapper: {
    marginBottom: 24,
    [theme.breakpoints.down(1200)]: {
      paddingLeft: 24,
    },
  },
  footer: {
    width: 275,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 32,
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
  footerText: {
    alignSelf: 'center',
    fontSize: 14,
    color: '#7E7E7E',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: '1s',
    }),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 48,
  },
  label: {
    'margin': '40px 0px 8px',
    'fontSize': 20,
    'fontWeight': 600,
    'color': '#282D37',
    '&.desktop': {
      margin: 0,
      fontWeight: 500,
    },
  },
  icon: {
    minWidth: 40,
    color: '#757575',
  },
  versionText: {
    alignSelf: 'center',
    fontSize: 11,
    color: '#7E7E7E',
    whiteSpace: 'nowrap',
  },
  drawer: {
    display: 'flex',
    top: '0',
    overflowX: 'hidden',
  },
  drawerOpen: {
    width: 275,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(9),
  },
  expandIcon: {
    fontSize: 24,
  },
}))
