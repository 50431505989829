import { makeStyles } from '@material-ui/core/styles'

export const dialogStyles = makeStyles((theme) => ({
  paperRounded: {
    maxWidth: 600,
    maxHeight: 800,
    borderRadius: 16,
    [theme.breakpoints.down(600)]: {
      width: 'unset',
      height: 'unset',
    },
  },
  modal: {
    backgroundColor: '#fff',
    width: 'fit-content',
    height: 'fit-content',
  },
  imageModalWrapper: {
    width: 600,
    height: 800,
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  actionModalWrapper: {
    width: 600,
    // height: 615,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(600)]: {
      width: '100%',
      height: 'unset',
    },
  },
  container: {
    width: '100%',
    height: '100%',
    maxWidth: 568,
    boxSizing: 'border-box',
  },
  closeIconWrapper: {
    cursor: 'pointer',
    position: 'absolute',
    top: 16,
    right: 16,
  },
  iconFilled: {
    'borderRadius': '100%',
    'fontSize': '28px',
    'padding': 6,
    'backgroundColor': 'rgba(0, 0, 0, 0.34)',
    'color': '#FFF',
    'cursor': 'pointer',
    '&.left': {
      marginLeft: '-48px',
      transform: 'rotate(-180deg)',
      [theme.breakpoints.down(600)]: {
        marginLeft: '-12px',
      },
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      padding: 6,
    },
  },
  desc: {
    marginTop: 8,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '22px',
    color: '#757575',
  },
  imageContainer: {
    width: '100%',
  },
  image: {
    height: 300,
    margin: '0px auto',
  },
  controls: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 28,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '48px',
    textAlign: 'left',
    [theme.breakpoints.down(600)]: {
      // position: 'fixed',
      // bottom: '0px',
      // background: 'none',

      padding: 0,
      // width: '100vw',
      // padding: '0px',
      // margin: '0px auto',
      // textAlign: 'left',
      // alignItems: 'flex-start',
      // justifyContent: 'flex-end',
    },
  },
  assetWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '0px auto',
    [theme.breakpoints.down(600)]: {
      height: '80vh',
      width: '90%',
    },
  },
  buttonWrapper: {
    width: '100%',
    [theme.breakpoints.down(600)]: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '90vw',
      position: 'absolute',
      bottom: 48,
    },
  },
  confirmButton: {
    marginTop: 16,
    width: '100%',
    [theme.breakpoints.down(600)]: {
      maxWidth: 'unset',
      marginBottom: '8px',
    },
    [theme.breakpoints.down(389)]: {
      marginTop: 16,
    },
  },
  assetBox: {
    width: '100%',
  },
  img: {
    height: '300px',
    marginBottom: '32px',
    [theme.breakpoints.down(600)]: {
      width: '90%',
      height: 'unset',
    },
  },
}))
