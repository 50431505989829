import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import TakeATest from '../../assets/img/page-banners/take-a-test.png'
import { testTypes } from '../../constants'
import { StoreStepType } from '../../features/shopify/model/types'
import { selectLanguageContent } from '../../features/translation'
import { Typography } from '../../ui'
import { paths } from '../paths'

export const GetTestedPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const i18n = useSelector(selectLanguageContent)

  const getScan = () => {
    history.push(paths.registerTest('intro'))
  }

  const getOrder = () => {
    history.push(paths.shopifyStore(StoreStepType.PRODUCTS))
  }

  const scheduleAppointment = () => {
    history.push(paths.selectTestOrder(testTypes.APPOINTMENT))
  }

  const options = [
    {
      id: 'ihave',
      title: i18n.have_test_kit_option,
      body: 'Get started right now',
      onClick: () => getScan(),
    },
    {
      id: 'order',
      title: i18n.order_test_kit_option,
      body: 'Get a test shipped directly to you',
      onClick: () => getOrder(),
    },
    // {
    //   id: 'schedule',
    //   title: i18n.schedule_appointment_screen_title,
    //   body: 'Get a test done at a lab',
    //   onClick: () => scheduleAppointment(),
    // },
  ]

  return (
    <div className={classes.content}>
      <img src={TakeATest} />
      <Typography
        style={{
          marginTop: 24,
          fontSize: 32,
          textAlign: 'center',
          fontWeight: 500,
        }}
      >
        {i18n.get_tested}
      </Typography>
      <Typography
        style={{
          marginTop: 24,
          fontSize: 16,
          textAlign: 'center',
          fontWeight: 500,
        }}
      >
        {i18n.select_option_for_testing}
      </Typography>
      <Box className={classes.cardsContainer}>
        {options.map((o) => (
          <OptionCard key={o.title} {...o} />
        ))}
      </Box>
    </div>
  )
}

const OptionCard = ({
  title,
  id,
  body,
  onClick,
}: {
  title: string
  id: string
  body: string
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}) => {
  const classes = useStyles()

  return (
    <Box id={id} className={classes.optionCard} onClick={onClick}>
      <Box style={{ padding: '16px' }}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.body}>{body}</Typography>
      </Box>
      <ChevronRightIcon style={{ padding: '16px' }} />
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    maxWidth: '90vw',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
  },
  body: {
    fontSize: '14px',
    fontWeight: 500,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '32px',
  },
  header: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    gridTemplateColumns: '80px 1fr 80px',
    width: '100%',
  },
  optionCard: {
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    width: '500px',
    cursor: 'pointer',
    [theme.breakpoints.down(600)]: {
      width: '90vw',
    },
  },
  optionIcon: {
    fill: theme.palette.primary.main,
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
    gap: 24,
    [theme.breakpoints.down('xs')]: {},
  },
}))
