import { Box, Theme } from '@material-ui/core'
import MUIButton from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import React, { CSSProperties } from 'react'
import { capitalizeString, cleanStringForAria } from '../../../libs/utils'
import { ButtonStyle, ButtonType } from '../../../pages/network/type'
import { Typography } from '../typography'

type Color = 'primary' | 'secondary'
export type Variant = 'contained' | 'text' | 'outlined'

export const getButtonVariant = (variant: ButtonStyle): ButtonType => {
  switch (variant) {
    default:
    case 0:
      return ButtonType.contained
    case 1:
      return ButtonType.outlined
    case 2:
      return ButtonType.text
  }
}

const getButtonColor = (theme: Theme, color: Color) => {
  switch (color) {
    case 'secondary':
      return theme.palette.secondary.main
    case 'primary':
    default:
      return theme.palette.primary.main
  }
}

interface ButtonProps {
  color?: Color
  fullWidth?: boolean
  type?: 'submit' | 'reset' | 'button'
  className?: string
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  component?: any
  to?: string
  isLoading?: boolean
  variant?: Variant
  id?: string
  style?: CSSProperties
}
export const Button: React.FC<ButtonProps> = ({
  children,
  color = 'primary',
  fullWidth = false,
  type = 'button',
  className,
  disabled,
  onClick,
  component,
  to,
  isLoading,
  variant = 'contained',
  id,
  style,
}) => {
  const classes = useStyles({ color, variant })

  if (variant === 'text') {
    return (
      <Box onClick={onClick}>
        <Typography className={classes.textButton} align="center">
          {children}
        </Typography>
      </Box>
    )
  }
  return (
    <MUIButton
      id={id ? id : cleanStringForAria(children?.toString() || '', 'id')}
      classes={{ root: classes.root, label: classes.label }}
      className={className}
      fullWidth={fullWidth}
      type={type}
      disableElevation={true}
      disabled={disabled || isLoading}
      onClick={onClick}
      component={component}
      to={to}
      variant={variant}
      style={style}
      aria-label={
        typeof children === 'string'
          ? cleanStringForAria(children.toString() || '', 'aria')
          : undefined
      }
    >
      {isLoading ? (
        <CircularProgress size={24} className={classes.buttonProgress} />
      ) : (
        <>
          {typeof children === 'string'
            ? capitalizeString(children.toString() || '')
            : children}
        </>
      )}
    </MUIButton>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    'color': ({ color, variant }: { color: Color; variant: Variant }) =>
      variant === 'contained' ? '#fff' : getButtonColor(theme, color),

    'height': 48,
    'fontSize': 16,
    'fontWeight': 700,
    'width': '100%',
    'opacity': 1,
    'textTransform': 'none',
    'transition': 'opacity .2s',
    'backgroundColor': ({
      color,
      variant,
    }: {
      color: Color
      variant: Variant
    }) => (variant === 'contained' ? getButtonColor(theme, color) : '#fff'),
    'border': '2px solid',
    'borderColor': ({ color, variant }: { color: Color; variant: Variant }) =>
      getButtonColor(theme, color),
    '&:hover': {
      color: ({ color, variant }: { color: Color; variant: Variant }) =>
        variant === 'contained' ? getButtonColor(theme, color) : '#fff',
      borderColor: ({ color, variant }: { color: Color; variant: Variant }) =>
        variant === 'contained' ? getButtonColor(theme, color) : '#fff',
      backgroundColor: ({
        color,
        variant,
      }: {
        color: Color
        variant: Variant
      }) => (variant === 'contained' ? '#fff' : getButtonColor(theme, color)),
    },
    [theme.breakpoints.down(600)]: {
      '&:hover': {
        color: ({ color, variant }: { color: Color; variant: Variant }) =>
          variant === 'contained' ? '#fff' : getButtonColor(theme, color),
        borderColor: ({ color, variant }: { color: Color; variant: Variant }) =>
          variant === 'contained' ? getButtonColor(theme, color) : '#fff',
        backgroundColor: ({
          color,
          variant,
        }: {
          color: Color
          variant: Variant
        }) => (variant === 'contained' ? getButtonColor(theme, color) : '#fff'),
      },
    },
    '&.Mui-disabled': {
      color: ({ color, variant }: { color: Color; variant: Variant }) =>
        variant === 'contained' ? '#fff' : getButtonColor(theme, color),
      backgroundColor: ({
        color,
        variant,
      }: {
        color: Color
        variant: Variant
      }) => (variant === 'contained' ? getButtonColor(theme, color) : '#fff'),
      borderColor: ({ color, variant }: { color: Color; variant: Variant }) =>
        variant === 'contained' ? getButtonColor(theme, color) : '#fff',
      opacity: 0.4,
    },
    'flex': 1,
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
  },
  buttonProgress: {
    top: 'calc(50% - 12px)',
    color: 'white',
    left: 'calc(50% - 12px)',
  },
  label: {
    height: '100%',
    letterSpacing: '.25px',
    fontWeight: 600,
  },
  textButton: {
    'cursor': 'pointer',
    'margin': '0px auto',
    'width': 'fit-content',
    'maxHeight': 41,
    'padding': '10px 24px',
    'fontSize': '16px',
    'fontWeight': 600,
    'letterSpacing': '.25px',
    'color': theme.palette.primary.main,
    '& span': {
      whiteSpace: 'noWrap',
    },
  },
}))
