import { makeStyles } from '@material-ui/core/styles'
import { hexToRGBA } from '../../libs/helpers'

const SPACE_BETWEEN = 'space-between'
const NO_BORDER_BOTTOM = '0px solid #FFF'
const WEBKIT_BOX = '-webkit-box'

export const carePlanStyles = makeStyles((theme) => ({
  summaryWrapper: {
    margin: '24px auto 60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 800,
    [theme.breakpoints.down(960)]: {
      width: '75vw',
    },
    [theme.breakpoints.down(600)]: {
      width: '100%',
      margin: '0px auto 60px',
    },
  },
  headerImage: {
    'width': '100%',
    '&.easable': {
      [theme.breakpoints.up(600)]: {
        width: '70%',
      },
    },
  },
  banner: {
    'width': '100%',
    'margin': '24px auto',
    'color': '#282D37',
    'fontSize': 36,
    'fontWeight': 600,
    '&.easable': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 30,
    },
  },
  paperless: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: '44px',
    margin: '0 auto',
    cursor: 'pointer',
  },
  addressContainer: {
    margin: '12px 0px',
    width: '100%',
  },
  title: {
    color: '#54565B',
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 600,
  },
  resources: {
    margin: '24px 0px',
  },
  addressLine1: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: SPACE_BETWEEN,
  },
  delivery: {
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'left',
    color: '#7B7C7D',
  },
  testDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  optionWrapper: {
    minHeight: '44px',
    padding: '10px 16px',
    borderRadius: 12,
    display: 'flex',
    justifyContent: SPACE_BETWEEN,
    border: '1px solid #E6E7EA',
    margin: '16px auto',
    cursor: 'pointer',
    alignItems: 'center',
    backgroundColor: 'white',
    [theme.breakpoints.down(600)]: {
      margin: '0px auto 16px',
    },
  },
  button: {
    width: '100%',
    maxWidth: '100%',
    maxHeight: 45,
    margin: '24px 0px',
  },
  innerWrapper: {},
  declineWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  declineText: {
    fontWeight: 600,
  },
  closeWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: 24,
    cursor: 'pointer',
  },
  dialogContent: {
    maxWidth: '342px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '24px',
    margin: '24px',
  },
  dialogHeader: {
    fontWeight: 500,
  },
  dialogBody: {
    fontSize: '13px',
    fontWeight: 500,
  },
  declineHeaderImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: '#EDEDED',
  },
  declineImage: {
    margin: 16,
  },
  contentWrapper: {
    marginTop: 80,
    maxWidth: '1400px',
    width: '100vw',
  },
  contentContainer: {
    margin: '108px 114px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      margin: '48px',
    },
    [theme.breakpoints.down(600)]: {
      margin: '24px 12px',
    },
  },
  content: {
    width: '100%',
    [theme.breakpoints.down(600)]: {
      width: '90%',
      margin: '0px auto',
    },
  },
  titleBar: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    position: 'absolute',
  },
  pageTitle: {
    color: '#282D37',
    fontSize: '36px',
    fontWeight: 600,
  },
  loading: {
    width: 'calc(100vw - 228px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '250px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 96px)',
    },
    [theme.breakpoints.down(600)]: {
      width: 'calc(100vw - 24px)',
    },
  },
  featuredResource: {
    borderRadius: '16px',
    backgroundColor: '#FFF',
    display: 'flex',
    margin: '16px 0px',
    maxHeight: '340px',
    cursor: 'pointer',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      borderRadius: '8px',
      border: `1px solid ${theme.palette.primary.main}`,
      maxHeight: '372px',
    },
  },
  featuredResourceBody: {
    padding: '32px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  featuredResourceTitle: {
    'fontSize': '36px',
    'lineHeight': '46px',
    'display': WEBKIT_BOX,
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  featuredImage: {
    borderRadius: '16px',
    objectFit: 'cover',
    width: '50%',
    [theme.breakpoints.down(600)]: {
      maxHeight: '220px',
      borderTopLeftRadius: '7px',
      borderTopRightRadius: '7px',
      borderBottomLeftRadius: '0px',
      width: '100%',
    },
  },
  featuredResourceDesc: {
    'marginTop': '16px',
    'display': WEBKIT_BOX,
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    [theme.breakpoints.down('xs')]: {
      'fontSize': '14px',
      'lineHeight': '21px',
      '-webkit-line-clamp': 3,
    },
  },
  resourceListContainer: {
    width: '100%',
    display: 'flex',
    marginTop: '24px',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
    },
  },
  resourceSearchContainer: {
    display: 'flex',
    width: '292px',

    flexDirection: 'column',
    [theme.breakpoints.down(600)]: {
      width: 'calc(100% - 30px)',
    },
  },
  resourceList: {
    display: 'flex',
  },
  img: {
    objectFit: 'contain',
    height: '70px',
    width: '100%',
  },
  noContent: {
    fontWeight: 500,
    margin: '25px auto',
  },
  searchFieldWrapper: {
    display: 'flex',
    padding: '8px 8px 8px 16px',
    alignItems: 'center',
    gap: '8px',
    flexShrink: 0,
    alignSelf: 'stretch',
    borderRadius: '100px',
    border: '1px solid #757575',
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
  searchField: {
    'textTransform': 'none',
    'width': '100%',
    'background': '#FFF',
    '& input': {
      padding: '7px 0',
    },
    '& .MuiInput-underline:after': {
      borderBottom: NO_BORDER_BOTTOM,
    },
    '& .MuiInput-underline:before': {
      borderBottom: NO_BORDER_BOTTOM,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: NO_BORDER_BOTTOM,
    },
  },
  divider: {
    margin: '24px 0px 0px',
    width: '100%',
    borderBottom: 'solid 1px #CCCCCE',
    [theme.breakpoints.down(600)]: {
      margin: '16px 0',
    },
  },
  resourceWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    [theme.breakpoints.down(600)]: {
      gap: 16,
    },
  },
  largeCard: {},
  imageContainer: {
    maxHeight: '528px',
    height: 'calc(35vw + 32px)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    borderTopRightRadius: ({ radius }: { radius: number }) => radius,
    borderTopLeftRadius: ({ radius }: { radius: number }) => radius,
    [theme.breakpoints.down(1200)]: {
      height: '50vw',
    },
    [theme.breakpoints.down(700)]: {
      maxWidth: '90vw',
      height: '90vw',
      margin: '0px auto',
      maxHeight: 'unset',
    },
    [theme.breakpoints.down(500)]: {
      maxWidth: '90vw',
      width: 'unset',
      minWidth: 'unset',
      height: '381px',
    },
  },
  largeImageContainer: {
    maxHeight: 465,
    maxWidth: 800,
    height: '50vw',
    width: '70vw',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: ({ radius }: { radius: number }) => radius,
    [theme.breakpoints.down(700)]: {
      height: 'calc(55vw + 32px)',
    },
    [theme.breakpoints.down(500)]: {
      width: 'unset',
      minWidth: 'unset',
      height: '229px',
    },
  },
  smallImageContainer: {
    height: 224,
    width: 224,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: ({ radius }: { radius: number }) => radius,
    [theme.breakpoints.down(600)]: {
      height: '124px',
      width: '124px',
    },
  },
  image: {
    width: '180px',
  },
  xlCard: {
    maxHeight: '580px',
    maxWidth: '580px',
    width: '40vw',
    height: '40vw',
    [theme.breakpoints.down(850)]: {
      width: '100%',
      height: '264px',
      maxWidth: 'unset',
    },
  },
  xlCardHidden: {
    maxWidth: '100%',
  },
  contrastImage: {
    background:
      'linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 30%, rgba(0,0,0,.8) 100%)',
  },
  transparentButtonCard: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
    gap: 16,
    [theme.breakpoints.down('xs')]: {},
  },
  itemContainer: {
    marginRight: 32,
    width: 224,
    [theme.breakpoints.down(600)]: {
      width: 124,
      marginRight: 16,
    },
  },
  cardHeader: {
    'wordWrap': 'break-word',
    'fontSize': '20px',
    'fontWeight': 600,
    'marginTop': 10,
    '& p': {
      color: '#505358',
      [theme.breakpoints.down(700)]: {
        margin: '0px !important',
      },
    },
    '& span': {
      fontSize: '32px !important',
      [theme.breakpoints.down(960)]: {
        fontSize: '28px !important',
      },
      [theme.breakpoints.down(800)]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down(700)]: {
        fontSize: '20px !important',
      },
    },
  },
  secondaryItems: {
    width: '100%',
    marginTop: '16px',
    display: 'flex',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% + 16px)',
    },
  },
  secondaryText: {
    '& span': {
      fontWeight: 600,
      fontSize: '14px !important',
    },
  },
  dashboardButtonCard: {
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
    gap: 16,
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  resourcesTitle: {
    fontSize: '28px',
    fontWeight: 600,
    color: '#505358',
  },
  topTitle: {
    'fontSize': '20px',
    'fontWeight': 600,
    'margin': '32px 0px',
    'wordWrap': 'break-word',
    '& p': {
      color: '#505358',
      [theme.breakpoints.down('xs')]: {
        margin: '0px !important',
      },
    },
    '& span': {
      fontSize: '32px !important',
      [theme.breakpoints.down(960)]: {
        fontSize: '28px !important',
      },
      [theme.breakpoints.down(800)]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down(700)]: {
        fontSize: '20px !important',
      },
    },
    [theme.breakpoints.down(700)]: {
      margin: '16px 0px',
    },
  },
  dashboardButtonTitle: {
    'fontWeight': 600,
    'wordWrap': 'break-word',
    '& p': {
      margin: '0px !important',
      color: '#505358',
    },
    '& span': {
      fontSize: '32px !important',
      [theme.breakpoints.down(960)]: {
        fontSize: '28px !important',
      },
      [theme.breakpoints.down(800)]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down(700)]: {
        fontSize: '20px !important',
      },
    },
  },
  dashboardButtonBody: {
    'fontSize': '20px',
    'lineHeight': '32px',
    'fontWeight': 500,
    'wordWrap': 'break-word',
    '& p': {
      wordWrap: 'break-word',
      margin: '0px',
      color: '#757575',
    },
    '& span': {
      wordWrap: 'break-word',
      fontSize: '20px !important',
      [theme.breakpoints.down(960)]: {
        lineHeight: '20px',
        fontSize: '16px !important',
      },
      [theme.breakpoints.down(600)]: {
        lineHeight: '18px',
        fontSize: '14px !important',
      },
    },
    [theme.breakpoints.down(1400)]: {
      fontSize: '1.25rem',
      lineHeight: '24px',
    },
    [theme.breakpoints.down(960)]: {
      lineHeight: '24px',
      fontSize: '1rem',
    },
    [theme.breakpoints.down(700)]: {
      '& span': {
        fontSize: '14px !important',
        lineHeight: '19px',
      },
    },
  },
  buttonWrapper: {
    [theme.breakpoints.down(600)]: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
  },
  confirmButton: {
    width: '100%',
    height: '36px',
    [theme.breakpoints.up(600)]: {
      height: 'unset',
      maxWidth: 'fit-content',
    },
  },
  simpleButtonWrapper: {
    maxWidth: '50vw',
  },
  simpleButton: {
    width: '100%',
    [theme.breakpoints.down(600)]: {
      width: '90vw',
    },
  },
  iconFilled: {
    'borderRadius': '100%',
    'fontSize': '71px',
    'padding': 8,
    'backgroundColor': 'rgba(0, 0, 0, 0.8)',
    'color': 'white',
    'cursor': 'pointer',
    '&.large': {
      fontSize: '104px',
      padding: 8,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '32px',
      padding: 8,
    },
  },
  listPageWrapper: {
    margin: '0px auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down(600)]: {
      width: '90vw',
    },
  },
  listContent: {
    marginTop: 65,
    maxWidth: 500,
    width: '100%',
    [theme.breakpoints.down(600)]: {
      margin: '24px auto',
    },
  },
  historyListWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 48,
  },
  historyTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: '#505358',
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
  },
  historyBody: {
    fontSize: 16,
    fontWeight: 500,
    color: '#757575',
    [theme.breakpoints.down(600)]: {
      fontSize: 14,
    },
  },
  historyContentWrapper: {
    width: '100%',
    border: '1px solid #757575',
    borderRadius: 8,
    margin: '8px 0px',
    cursor: 'pointer',
    [theme.breakpoints.up(960)]: {
      '&:hover': {
        backgroundColor: hexToRGBA(theme.palette.primary.main, 0.05),
      },
    },
  },
  historyContent: {
    width: 'calc(100% - 32px)',
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))
