import { Box } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactChild, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { NOTIFICATION_TYPES } from '../../constants'
import { isLoggedIn } from '../../libs/helpers'
import { sendAuditLog } from '../../libs/utils'
import { selectNetworkSettings } from '../../pages/network/model'
import { paths } from '../../pages/paths'
import { resetDialogStore } from '../dialogOld/model'
import {
  hideInactivityNotification,
  showInactivityNotification,
  showNotification,
} from '../notifications/model'
import { selectLanguageContent } from '../translation'

const DEFAULT_LOGOUT_TIMEOUT = 1000 * 60 * 10 // 10 minutes

export const InactivityTimer = ({ children }: { children: ReactChild }) => {
  const history = useHistory()
  const networkSettings = useSelector(selectNetworkSettings)
  const loggedIn = isLoggedIn()
  const i18n = useSelector(selectLanguageContent)
  const [active, setActive] = useState(true)
  const dispatch = useDispatch()

  const timeoutDuration = networkSettings?.timeoutDuration
  const appName = networkSettings?.appName
  const logoutTimeout = timeoutDuration
    ? timeoutDuration * 60 * 1000
    : DEFAULT_LOGOUT_TIMEOUT
  const notificationTimeout =
    // If tenant inactivity is 1 min - set warning to 30sec so it does not always show
    timeoutDuration === 1
      ? logoutTimeout - 30 * 1000
      : logoutTimeout - 60 * 1000

  const promptUser = () => {
    if (!loggedIn) return
    setActive(false)
    dispatch(showInactivityNotification())
  }

  const handleOnIdle = () => {
    if (!loggedIn) return
    dispatch(hideInactivityNotification())
    sendAuditLog('User Timed Out', {
      action_type: 'create',
      date: moment().utc().format('MMM DD, YYYY hh:mm'),
      actor: localStorage.getItem('email') || '',
      group: appName || '',
    })
    dispatch(resetDialogStore())
    sessionStorage.removeItem('id_token')
    sessionStorage.removeItem('access_token')
    history.push(paths.login())
    dispatch(
      showNotification(
        i18n.inactivity_logout_message,
        NOTIFICATION_TYPES.ERROR,
        true
      )
    )
  }

  const handleOnAction = () => {
    if (!loggedIn) return
    if (!active) {
      setActive(true)
      timer.reset()
      dispatch(hideInactivityNotification())
    }
  }

  const timer = useIdleTimer({
    timeout: notificationTimeout,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
    promptTimeout: timeoutDuration === 1 ? 1000 * 30 : 1000 * 60,
    onPrompt: promptUser,
  })

  return (
    <Box
      width="100%"
      height="100%"
      minHeight="100vh"
      display="flex"
      flex={1}
      zIndex={1001}
    >
      {children}
    </Box>
  )
}
