import { Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { FooterItems } from './footerItems'

export const FooterNavigation = () => {
  const classes = useStyles()

  return (
    <>
      <Toolbar className={classes.footerToolbar}>
        <FooterItems isDesktop={false} />
      </Toolbar>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  footerToolbar: {
    color: theme.palette.primary.main,
    background: '#FFF',
    backdropFilter: 'blur(40px)',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 1001,
    paddingBottom: 20,
    [theme.breakpoints.down('xs')]: {
      background: 'rgba(243, 243, 243, 0.6)',
    },
    height: 64,
  },
}))
