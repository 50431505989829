import { Box, TextField } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allowedEmergencyContactRelationships } from '../../../../../../constants'
import { selectLanguageContent } from '../../../../../../features/translation'
import { translateOptions } from '../../../../../../libs/helpers'
import { cleanPhoneNumber } from '../../../../../../libs/utils'
import { Button, MaskedInput, Typography } from '../../../../../../ui'
import { FilledSelect } from '../../../../../../ui/atoms/select'
import { selectProfileData, updateUserData } from '../../../../../profile/model'
import { FormField } from '../../../../components/form-field'
import { settingsStyles } from '../../../../styles'
import { ContactFormValues } from '../../../../type'
import { FormSkeletonLoader } from './skeleton-loader'
import validationSchema from './validation-schema'

export const EmergencyContact = () => {
  const classes = settingsStyles()
  const i18n = useSelector(selectLanguageContent)
  const profileData = useSelector(selectProfileData)
  const [edit, setEdit] = useState(false)

  const dispatch = useDispatch()

  const onPhoneChange = (e: React.ChangeEvent<{ value: string }>) => {
    const cleanNumber = cleanPhoneNumber(e.target.value)
    formik.setFieldValue('phone', cleanNumber)
  }

  const onCancel = () => {
    setEdit(false)
    formik.resetForm()
  }

  const onSubmit = async (values: ContactFormValues) => {
    const updatedUser = {
      ...profileData,
      emergencyContact: {
        firstName: values.firstName,
        lastName: values.lastName,
        relationship: values.relationship,
        email: values.email,
        contact: {
          mobileNumber: values.phone,
        },
      },
    }
    await dispatch(updateUserData(updatedUser, true, () => setEdit(false)))
  }

  const formik = useFormik<ContactFormValues>({
    initialValues: {
      firstName: profileData.emergencyContact?.firstName || '',
      lastName: profileData.emergencyContact?.lastName || '',
      email: profileData.emergencyContact?.email || '',
      phone: profileData.emergencyContact?.contact.mobileNumber || '',
      relationship: profileData.emergencyContact?.relationship || '',
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema(i18n),
    onSubmit,
  })

  if (!profileData._id) return <FormSkeletonLoader />

  return (
    <div className={classes.formWrapper}>
      {!edit && (
        <Box className={classes.editBox} onClick={() => setEdit(true)}>
          <Typography color="primary">
            {i18n.emergency_contact_settings}
          </Typography>
        </Box>
      )}
      <form className={classes.wrapper} onSubmit={formik.handleSubmit}>
        <Box className={classes.formContainer}>
          <FormField
            label={i18n.first_name}
            formik={formik}
            name="firstName"
            required
            disabled={!edit}
          />
          <FormField
            label={i18n.last_name}
            formik={formik}
            name="lastName"
            required
            disabled={!edit}
          />
          <MaskedInput
            mask="+1 (999)-999-9999"
            maskChar=""
            variant="filled"
            label={i18n.phone_number}
            value={formik.values.phone}
            onChange={(e) => onPhoneChange(e)}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone ? formik.errors.phone : ''}
            inputProps={{
              inputMode: 'numeric',
            }}
            disabled={!edit}
            name="phone"
            required
          >
            {(inputProps: any) => <TextField {...inputProps} />}
          </MaskedInput>
          <FormField
            label={i18n.email}
            formik={formik}
            name="email"
            required
            disabled={!edit}
          />
          <FilledSelect
            label={i18n.policy_holder_relationship}
            options={translateOptions(
              allowedEmergencyContactRelationships,
              i18n
            )}
            value={formik.values.relationship}
            fullWidth
            name="relationship"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.relationship && Boolean(formik.errors.relationship)
            }
            helperText={
              formik.touched.relationship ? formik.errors.relationship : ''
            }
            disabled={!edit}
          />
        </Box>
        {edit && (
          <div className={classes.buttonContainer}>
            <Button
              variant="outlined"
              onClick={onCancel}
              className={classes.confirmButton}
            >
              {i18n.cancel_button}
            </Button>
            <Button
              className={classes.confirmButton}
              disabled={!formik.isValid}
              isLoading={formik.isSubmitting}
              type="submit"
            >
              {i18n.save_button}
            </Button>
          </div>
        )}
      </form>
    </div>
  )
}
