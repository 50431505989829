import { Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'

export const useMUITheme = (options?: { breakpoint?: number }) => {
  const { breakpoint = 600 } = options || {}
  const theme = useTheme()
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(breakpoint)
  )
  const primary = theme.palette.primary.main
  const secondary = theme.palette.secondary.main
  const success = theme.palette.success.main
  const error = theme.palette.error.main

  const colors = {
    primary,
    secondary,
    success,
    error,
  }

  return { isMobile, colors }
}
