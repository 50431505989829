import { Box, Dialog, makeStyles } from '@material-ui/core'
import React from 'react'
import { hexToRGBA } from '../../../libs/helpers'
import { Button, Typography } from '../../../ui'
import { DialogContent } from '../../dialog/types'

export interface DialogProps extends DialogContent {
  open: boolean
}

export const SimpleDialog = (props: DialogProps) => {
  const {
    open,
    title,
    body,
    onConfirm,
    confirmationLabel,
    onCancel,
    cancelLabel,
    image,
  } = props
  const classes = dialogStyles()

  return (
    <Dialog open={open} className={classes.root}>
      <div className={classes.actionModalWrapper}>
        <Box className={classes.contentWrapper}>
          <div className={classes.assetWrapper}>
            {image && <img src={image} alt={title} className={classes.img} />}
            <Box className={classes.assetBox}>
              {title && (
                <Typography className={classes.title}>{title}</Typography>
              )}
              {body && <Typography className={classes.body}>{body}</Typography>}
            </Box>
          </div>
        </Box>
        <div className={classes.dialogButtons}>
          <div className={classes.buttonWrapper}>
            {onCancel && (
              <Button
                onClick={() => onCancel()}
                className={classes.confirmButton}
                variant="text"
              >
                {cancelLabel}
              </Button>
            )}
            {onConfirm && (
              <Button
                onClick={() => onConfirm()}
                className={classes.confirmButton}
                variant="contained"
              >
                {confirmationLabel}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const dialogStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      borderRadius: '16px',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: 36,
    color: '#000',
    [theme.breakpoints.down(600)]: {
      fontSize: 24,
    },
  },
  body: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '130%',
    color: '#757575',
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
  },
  actionModalWrapper: {
    width: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(600)]: {
      width: '100%',
      height: 'unset',
    },
  },
  image: {
    height: 300,
    margin: '0px auto',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 32,
    textAlign: 'left',
    [theme.breakpoints.down(600)]: {
      padding: '32px 32px 16px',
    },
  },
  assetWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '0px auto',
  },
  buttonWrapper: {
    padding: '16px 32px',
    gap: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(600)]: {
      justifyContent: 'space-evenly',
      flexDirection: 'column-reverse',
      width: '100%',
      gap: 16,
      padding: '16px 0px 32px',
    },
  },
  confirmButton: {
    marginTop: 0,
    maxWidth: 'fit-content',
    [theme.breakpoints.up(600)]: {
      padding: '10px 24px',
    },
    [theme.breakpoints.down(600)]: {
      padding: '10px 24px',
      marginBottom: '8px',
      maxWidth: 'unset',
      width: '80%',
      minWidth: '80%',
    },
    [theme.breakpoints.down(389)]: {
      marginTop: 16,
    },
  },
  assetBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  img: {
    height: '300px',
    marginBottom: '32px',
    [theme.breakpoints.down(600)]: {
      width: '90%',
      height: 'unset',
    },
  },
  dialogButtons: {
    width: '100%',
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.05),
    [theme.breakpoints.down(600)]: {
      backgroundColor: '#FFF',
    },
  },
}))
