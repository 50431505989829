import { Box } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLanguageContent } from '../../../features/translation'
import { Button, Typography } from '../../../ui'
import { Success } from '../../../ui/atoms/success-checkmark-animation/checkmark-animation'
import { Wrapper } from '../../../ui/templates/app-layout'
import { telehealthStyles } from '../components/styles'

export const BeInTouchPage = (props: any) => {
  const { handleNext } = props
  const classes = telehealthStyles()
  const i18n = useSelector(selectLanguageContent)
  const callNumber = '646-681-5864'
  return (
    <Wrapper>
      <div style={{marginTop: '8vh'}}>
        <Success />
      </div>
      <Box className={classes.textContentContainer}>
        <Typography className={classes.title}>
          {i18n.telehealth_intake_complete_title}
        </Typography>
        <Typography className={classes.body} style={{ maxWidth: 550 }}>
          {i18n.telehealth_intake_complete_message.replace(`%@`, '')}
        </Typography>
        <Typography
          className={classes.incomingPhoneNumber}
          style={{ maxWidth: 550 }}
        >
          {callNumber}
        </Typography>
      </Box>

      <div className={classes.buttonWrapper}>
        <Button
          color="primary"
          className={classes.actionBtn}
          onClick={handleNext}
        >
          {i18n.return_home}
        </Button>
      </div>
    </Wrapper>
  )
}
