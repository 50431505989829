import { Box, Dialog, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import createDOMPurify from 'dompurify'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import api from '../../api'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { clearTestKitFlow, handleError } from '../../libs/helpers'
import { lsClient } from '../../ls-client'
import { Button, Loading } from '../../ui'
import { PageWrapper } from '../../ui/templates/page-wrapper'
import { paths } from '../paths'
import {
  selectError,
  selectFullOrderableTest,
  selectProductAssetDetail,
  selectProductAssetDetailLoading,
  setCollectionsForContinue,
  setLoading,
} from '../scan-qr/model'

const DOMPurify = createDOMPurify(window)

export const ProductAssetDetailPage = () => {
  const dispatch = useDispatch()
  const productAssetDetails = useSelector(selectProductAssetDetail)
  const orderableTest = useSelector(selectFullOrderableTest)
  const isLoading = useSelector(selectProductAssetDetailLoading)
  const classes = useStyles()
  const history = useHistory()
  const error = useSelector(selectError)
  const i18n = useSelector(selectLanguageContent)
  const testId = lsClient.getUserLSByKey('testId')
  const [backDialogOpen, setBackDialogOpen] = useState(false)

  useEffect(() => {
    //Reloads the test data in case of refresh on this page
    if (!productAssetDetails && testId) {
      ;(async function () {
        dispatch(setLoading(true))
        const instructionsReload = await api.getLabTestForContinue(testId, 'en')
        if (instructionsReload && !instructionsReload.error) {
          dispatch(setCollectionsForContinue(instructionsReload))
        } else if (instructionsReload?.error) {
          // try to get translated error by key from localization file or render received error string
          dispatch(
            handleError(
              null,
              i18n[instructionsReload.error] || instructionsReload.error
            )
          )
        }
        dispatch(setLoading(false))
      })()
    }
  }, [productAssetDetails])

  const goNext = () => {
    history.push(paths.instructions())
    if (!lsClient.getUserLSByKey('step')) {
      lsClient.setUserLS('step', '0')
    }
  }

  const cancelTest = () => {
    clearTestKitFlow()
    setBackDialogOpen(false)
    history.push(paths.app.scanQrCode())
  }

  if (error) {
    lsClient.removeUserKeyLS('code')
    history.push(paths.invalidQRCode())
  }

  if (isLoading) return <Loading />

  return (
    <PageWrapper showBack={false} className={classes.assetWrapper}>
      <Box className={classes.cameraContent}>
        <Box className={classes.imageWrapper}>
          <img
            className={classes.img}
            src={productAssetDetails?.imageURL}
            alt={
              orderableTest?.customTestName
                ? orderableTest?.customTestName
                : 'Orderable Test'
            }
          />
        </Box>
        <Box className={classes.content}>
          {orderableTest?.customTestName && (
            <>
              <Typography className={classes.minorHeader}>
                {i18n.test_kit_type}
              </Typography>
              <Typography className={classes.testName}>
                {orderableTest.customTestName}
              </Typography>
              <Typography color="primary" className={classes.altName}>
                {orderableTest.orderableName}
              </Typography>
            </>
          )}
          <Typography className={classes.minorHeader}>
            {i18n.whats_included_label}
          </Typography>
          <Box className={classes.assetBox}>
            <Box className={classes.htmlContainer}>
              {productAssetDetails?.body && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(productAssetDetails.body),
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box className={classes.lgButtonBox}>
          <Button
            variant="outlined"
            onClick={() => setBackDialogOpen(true)}
            className={classes.confirmButton}
          >
            Previous
          </Button>
          <Button onClick={goNext} className={classes.confirmButton}>
            {i18n.continue_button}
          </Button>
        </Box>
      </Box>
      <Dialog open={backDialogOpen}>
        <GoBackWarning
          cancelTest={cancelTest}
          setBackDialogOpen={setBackDialogOpen}
          classes={classes}
          i18n={i18n}
        />
      </Dialog>
    </PageWrapper>
  )
}

const GoBackWarning = (props: {
  cancelTest: () => void
  setBackDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  classes: ClassNameMap
  i18n: I18n
}) => {
  const { cancelTest, setBackDialogOpen, classes, i18n } = props

  return (
    <Box className={classes.dialogWrapper}>
      <Box className={classes.dialogContent}>
        <Box>
          <Typography variant="h2" style={{ marginBottom: 10 }}>
            {i18n.are_you_sure}
          </Typography>
        </Box>
        <Box className={classes.headerWrapper}>
          <Typography
            variant="body1"
            align="center"
            style={{ marginBottom: 30, fontWeight: 500 }}
          >
            {i18n.going_back_will_permanently}
          </Typography>
        </Box>
        <div className={classes.lgButtonBox}>
          <Button
            onClick={() => setBackDialogOpen(false)}
            className={classes.confirmButton}
            style={{ margin: '20px 0px' }}
          >
            {i18n.continue_button}
          </Button>
          <Button
            onClick={cancelTest}
            className={classes.confirmButton}
            style={{ margin: '20px 0px' }}
          >
            {i18n.abandon_test_flow_title}
          </Button>
        </div>
      </Box>
    </Box>
  )
}

const SPACE_BETWEEN = 'space-between'

const useStyles = makeStyles((theme) => ({
  imageWrapper: {
    margin: 'auto',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.down(600)]: {
      marginTop: '-16px',
    },
  },
  dialogWrapper: {
    padding: '30px 10px',
    height: 250,
    width: 350,
    [theme.breakpoints.down(600)]: {
      height: 'unset',
      width: 'unset',
      padding: '30px 0px',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '16px',
  },
  img: {
    objectFit: 'cover',
    width: '100%',
  },
  htmlContainer: {
    width: '90%',
    margin: '0 25px',
    overflow: 'auto',
  },
  content: {
    padding: '24px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    borderRadius: 32,
    backgroundColor: '#FFF',
  },
  minorHeader: {
    color: '#7B7C7D',
    fontSize: 9,
    marginTop: 10,
    fontWeight: 900,
  },
  testName: {
    fontWeight: 400,
    color: '#505358',
    fontSize: 24,
    textAlign: 'left',
    [theme.breakpoints.down(500)]: {
      fontSize: 20,
    },
  },
  altName: {
    fontWeight: 600,
    fontSize: 14,
    textAlign: 'left',
    marginBottom: 14,
    color: '#505358',
  },
  instructionContainer: {
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    alignItems: 'center',
    [theme.breakpoints.down(500)]: {
      margin: 10,
    },
  },
  assetBox: {
    maxHeight: 'calc(100vh - 575px)',
    overflowX: 'hidden',
    border: '1px solid #E5E5E6',
    borderRadius: 16,
    width: '100%',
    minWidth: 320,
    marginTop: 15,
    alignContent: 'left',
    [theme.breakpoints.down(320)]: {
      minWidth: 'unset',
    },
  },
  errorBox: {
    width: '100%',
    paddingTop: 150,
    alignContent: 'left',
    color: '#003C71',
  },
  errorContent: {
    maxWidth: 540,
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    backgroundColor: '#F5F6F7',
    height: 'calc(90vh)',
  },
  assetWrapper: {
    maxWidth: 600,
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
  },
  headerTitle: {
    fontSize: 20,
    color: '#003C71',
    fontWeight: 600,
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    position: 'relative',
  },
  backIconWrapper: {
    transform: 'rotate(45deg)',
    position: 'absolute',
    left: 15,
    cursor: 'pointer',
  },
  backIcon: {
    color: '#003C71',
    cursor: 'pointer',
  },
  cameraScreen: {
    width: '100%',
    display: 'block',
  },
  cameraContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: SPACE_BETWEEN,
    width: '100%',
  },
  lgButtonBox: {
    width: '100%',
    display: 'flex',
    marginTop: '32px',
    justifyContent: 'space-between',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column-reverse',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  confirmButton: {
    minWidth: 'unset',
    maxWidth: 'fit-content',
    padding: '0px 24px',
    width: '100%',
    // margin: '0px auto',
    height: '46px',
    [theme.breakpoints.down(600)]: {
      marginTop: 10,
      marginBottom: 10,
      minWidth: '100%',
      minHeight: '46px',
    },
  },
  backButton: {
    marginBottom: 10,
  },
}))
