import { InputAdornment } from '@material-ui/core'
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input'
import { makeStyles } from '@material-ui/core/styles'
import MUITextField from '@material-ui/core/TextField'
import React, { RefObject } from 'react'
import { cleanStringForAria } from '../../../libs/utils'

type Color = 'primary' | 'secondary'
export interface TextFieldProps {
  value?: any
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  label?: string
  type?: string
  fullWidth?: boolean
  color?: Color
  required?: boolean
  endAdornment?: React.ReactNode
  className?: string
  InputProps?: StandardInputProps
  // eslint-disable-next-line @typescript-eslint/ban-types
  inputProps?: object
  InputLabelProps?: { shrink?: boolean }
  name?: string
  error?: React.ReactNode
  onBlur?: (e: React.FocusEvent) => void
  onFocus?: (e: React.FocusEvent) => void
  noHelperText?: boolean
  readOnly?: boolean
  disabled?: boolean
  isMultiline?: boolean
  autoComplete?: string
  placeholder?: string
  variant?: 'standard' | 'outlined' | 'filled'
  min?: number
  max?: number
  rows?: number
  onKeyDown?: (e: React.KeyboardEvent) => void
  autoFocus?: boolean
  id?: string
  inputRef?: RefObject<HTMLInputElement>
}

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      onChange,
      value,
      label,
      type,
      fullWidth = true,
      color = 'primary',
      required = false,
      endAdornment,
      className,
      InputProps = {},
      inputProps,
      InputLabelProps,
      name,
      error,
      onBlur,
      onFocus,
      noHelperText = false,
      readOnly,
      disabled,
      autoComplete,
      variant = 'standard',
      max,
      min,
      rows = 1,
      placeholder = '',
      onKeyDown,
      isMultiline = false,
      autoFocus = false,
      id,
      inputRef,
    },
    ref
  ) => {
    const classes = useStyles({ color })
    const localClasses = childStyles()

    const FullInputProps: Partial<StandardInputProps> = InputProps
    if (endAdornment) {
      FullInputProps.endAdornment = (
        <InputAdornment position="end">{endAdornment}</InputAdornment>
      )
    }
    if (readOnly) {
      FullInputProps.readOnly = readOnly
    }

    const fullInputProps: Record<string, any> = { ...inputProps }
    if (max) fullInputProps.max = max
    if (min) fullInputProps.min = min
    return (
      <MUITextField
        id={id ? id : cleanStringForAria(name || '', 'id')}
        value={value}
        onChange={onChange}
        label={label}
        type={type}
        color={color}
        classes={classes}
        name={name}
        fullWidth={fullWidth}
        required={required}
        className={
          label || type === 'textarea'
            ? className
            : `${className} ${localClasses.noLabel}`
        }
        InputProps={FullInputProps}
        inputProps={fullInputProps}
        InputLabelProps={InputLabelProps}
        error={Boolean(error)}
        helperText={!noHelperText && error}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled || readOnly}
        autoComplete={autoComplete}
        variant={variant}
        onKeyDown={onKeyDown}
        multiline={isMultiline}
        autoFocus={autoFocus}
        placeholder={placeholder}
        minRows={rows}
        ref={ref}
        inputRef={inputRef}
        aria-label={cleanStringForAria(name || '', 'aria')}
      />
    )
  }
)

const childStyles = makeStyles((_theme) => ({
  noLabel: {
    '& .MuiFilledInput-input': {
      padding: '20px 12px',
    },
  },
}))

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInput-underline.Mui-error:after': {
      borderColor: '#f44336',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'inherit',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: ({ color }: { color: Color }) => theme.palette[color].main,
    },
    '& input:-webkit-autofill': {
      boxShadow: '0 0 0px 1000px #FFF inset',
    },
    '& .MuiFilledInput-root': {
      background: '#FFF',
    },
    '& .MuiFilledInput-underline:before': { borderBottom: 'none' },
    '& .MuiFilledInput-underline:after': { borderBottom: 'none' },
  },
}))
