import { Box } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { HTMLTextComponent } from '../../../../libs/shared-components'
import { Button, Typography } from '../../../../ui'
import { getButtonVariant } from '../../../../ui/atoms/button/button'
import { dashboardCardStyles } from '../../../_styles/dashboardCardStyles'
import { selectNetworkSettings } from '../../../network/model'
import { DashboardCard } from '../../../network/type'

export const BackgroundImageCard = (props: {
  button: DashboardCard
  buttonAction: (path: string, id?: string, type?: number) => void
  startWorkflows: (ids: string[]) => void
}) => {
  const { button, buttonAction, startWorkflows } = props
  const networkSettings = useSelector(selectNetworkSettings)
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24
  const classes = dashboardCardStyles({ radius })

  if (!button.cardImageUrl) return null

  const handleClick = () => {
    if (button.buttonAction?.value === 'Workflow') {
      startWorkflows(button.workflowIds)
    } else if (button.buttonAction?.value === 'RedirectToUrl') {
      if (!button.redirectUrl) return
      buttonAction(button.buttonAction.value, button.redirectUrl)
    } else if (button.buttonAction?.value) {
      buttonAction(button.buttonAction.value)
    }
  }

  return (
    <Box
      key={button.cardTitle}
      className={classes.bgImageButtonContainer}
      onClick={handleClick}
    >
      <img
        src={button.cardImageUrl}
        style={{
          borderRadius: radius,
        }}
      />
      <Box className={classes.bgImageCard}>
        <Typography className={classes.dashboardButtonTitle}>
          <HTMLTextComponent HTMLString={button.cardTitle} />
        </Typography>
        {button.cardBody && (
          <Typography className={classes.dashboardButtonBody}>
            <HTMLTextComponent HTMLString={button.cardBody} />
          </Typography>
        )}
        {button.buttonEnabled && (
          <div className={classes.buttonWrapper}>
            <Button
              style={{
                backgroundColor:
                  button.buttonColor && button.buttonStyle === 0
                    ? button.buttonColor
                    : 'unset',
                borderColor: button.buttonColor ? button.buttonColor : 'unset',
                color: button.buttonLabelColor
                  ? button.buttonLabelColor
                  : 'unset',
              }}
              onClick={handleClick}
              className={classes.confirmButton}
              variant={getButtonVariant(button.buttonStyle)}
            >
              {button.buttonLabel}
            </Button>
          </div>
        )}
      </Box>
    </Box>
  )
}
