import qs from 'querystring'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { paths } from '../pages/paths'
import { getUsersAvailableTenants } from '../pages/tenant-selection/tenantSlice'
import { isLoggedIn } from './helpers'

interface UseAuth0Config {
  closedForLoggedIn?: boolean
  closedForAnonymous?: boolean
}

export function useAuth0({
  closedForAnonymous = true,
  closedForLoggedIn = false,
}: UseAuth0Config) {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [isReady, setReady] = useState(false)
  const [isUserLogged, setIsLogged] = useState(false)

  useEffect(() => {
    const goToHome = () => history.replace(paths.login())
    const goToDashboard = () => history.replace(paths.app.dashboard())

    if (location.hash) {
      const params = qs.parse(location.hash.replace('#', ''))
      if (params.access_token && params.id_token) {
        sessionStorage.setItem('access_token', params.access_token as string)
        sessionStorage.setItem('id_token', params.id_token as string)
      }
    }

    const isLogged = isLoggedIn()

    setReady(true)
    setIsLogged(isLogged)

    // if (closedForAnonymous && !isLogged) goToHome()
    if (closedForLoggedIn && isLogged) {
      dispatch(getUsersAvailableTenants())
      goToDashboard()
    }
  }, [])

  return { isReady, isUserLogged }
}
