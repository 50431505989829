import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import api from '../../../api'
import { StepType } from '../../../constants'
import { setDialog } from '../../../features/dialogOld/model'
import {
  AttemptLimitErrorType,
  DialogType,
} from '../../../features/dialogOld/types'
import { I18n } from '../../../features/translation/types'
import { handleError } from '../../../libs/helpers'
import {
  capitalizeString,
  getCurrentUser,
  sendAuditLog,
} from '../../../libs/utils'
import { lsClient } from '../../../ls-client'
import { Address } from '../../../types/user'
import { InvitedUser } from '../../invite-web-registration/model/type'
import { getNetworkSettings } from '../../network/model'
import { NetworkSettings } from '../../network/type'
import { getProfileData } from '../../profile/model'
import { ProfileData } from '../../profile/type'
import { Term } from '../../terms-and-conds/model/type'
import {
  AccountInformation,
  Contact,
  EligibilityGender,
  EligibilityPrefillData,
  EligibilityTry,
  EligibilityType,
  FormValues,
  InsuranceInformation,
  OnboardingType,
  PolicyInformation,
  ShippingInformation,
  SignUpType,
  UserCreate,
  USPSAddressModel,
} from './type'

interface InitialState {
  signup: OnboardingType
  eligibiltyDialogOpen: boolean
  eligibiltyErrorMessage: string
  verificationError: boolean
  verificationSubmitting: boolean
  loading: boolean
  foundAddress: {
    isAddressExactMatch: boolean
    isAddressFound: boolean
    address: Address
  }
  active: number | undefined
  email: string
  formData: FormValues
  error: string
  consentError: string
  warning: string
}

const initialState: InitialState = {
  signup: OnboardingType.UNSET,
  eligibiltyDialogOpen: false,
  verificationError: false,
  verificationSubmitting: false,
  loading: false,
  eligibiltyErrorMessage: '',
  foundAddress: {
    isAddressExactMatch: false,
    isAddressFound: false,
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: '',
    },
  },
  email: '',
  active: undefined,
  error: '',
  consentError: '',
  warning: '',
  formData: {
    [StepType.ELIGIBILITY]: {
      lastName: '',
      dateOfBirth: null,
      lastFourSSN: '',
      try: 0,
    },
    [StepType.SIGN_UP]: {
      dependentId: '',
      firstName: '',
      lastName: '',
      email: '',
    },
    [StepType.CREATE_PASSWORD]: {
      password: '',
      confirmPassword: '',
    },
    [StepType.ENTER_PHONE_NUMBER]: {
      mobileNumber: '',
      countryCode: '+1',
    },
    [StepType.ACC_INFO]: {
      dob: null,
      sexAtBirth: '',
      race: '',
      ethnicity: '',
    },
    [StepType.SHIPPING_ADDRESS]: {
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
      },
    },
    [StepType.INSURANCE]: {
      insuranceProviderCompany: null,
      insuranceId: '',
      groupNumber: '',
      planName: '',
      coverageDate: null,
      notPolicyHolder: false,
    },
    [StepType.POLICY_INFO]: {
      firstName: '',
      lastName: '',
      phone: '',
      dob: null,
      relationship: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
      },
    },
  },
}

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setVerifError(store, { payload }: { payload: boolean }) {
      store.verificationError = payload
      store.verificationSubmitting = false
    },
    resetVerifError(store) {
      store.verificationError = false
    },
    setVerifSubmitting(store, { payload }: { payload: boolean }) {
      store.verificationSubmitting = payload
    },
    setEligibiltyDialogOpen(store, { payload }: { payload: boolean }) {
      store.eligibiltyDialogOpen = payload
    },
    setEligibilityError(store, { payload }: { payload: string }) {
      store.eligibiltyErrorMessage = payload
    },
    setLoading(store, { payload }: { payload: boolean }) {
      store.loading = payload
    },
    setSignupType(store, { payload }: { payload: OnboardingType }) {
      store.signup = payload
    },
    setEmail(store, { payload }: { payload: string }) {
      store.email = payload
    },
    saveEligibilityInfo(store, { payload }: { payload: EligibilityType }) {
      store.formData[StepType.ELIGIBILITY] = payload
    },
    setEligibilityTry(store, { payload }: { payload: EligibilityTry }) {
      store.formData[StepType.ELIGIBILITY].try = payload
    },
    saveSignupInfo(store, { payload }: { payload: SignUpType }) {
      store.formData[StepType.SIGN_UP] = payload
    },
    saveAddress(store, { payload }: { payload: ShippingInformation }) {
      store.formData[StepType.SHIPPING_ADDRESS].address.state =
        payload.address.state
      store.formData[StepType.SHIPPING_ADDRESS].address.country =
        payload.address.country
      store.formData[StepType.SHIPPING_ADDRESS].address.zip =
        payload.address.zip
      store.formData[StepType.SHIPPING_ADDRESS].address.line1 =
        payload.address.line1?.trimEnd()
      store.formData[StepType.SHIPPING_ADDRESS].address.line2 =
        payload.address.line2?.trimEnd()
      store.formData[StepType.SHIPPING_ADDRESS].address.city =
        payload.address.city?.trimEnd()
    },
    saveAccountInfo(store, { payload }: { payload: AccountInformation }) {
      store.formData[StepType.ACC_INFO] = payload
    },
    saveInsuranceInfo(store, { payload }: { payload: InsuranceInformation }) {
      store.formData[StepType.INSURANCE] = payload
    },
    savePolicyInfo(store, { payload }: { payload: PolicyInformation }) {
      store.formData[StepType.POLICY_INFO] = payload
    },
    setActive(store, { payload }: { payload: number }) {
      store.active = payload
    },
    saveContact(store, { payload }: { payload: Contact }) {
      store.formData[StepType.ENTER_PHONE_NUMBER] = payload
    },
    saveInvitePrefill(store, { payload }: { payload: InvitedUser }) {
      store.formData[StepType.SIGN_UP].firstName = payload.firstName
      store.formData[StepType.SIGN_UP].lastName = payload.lastName
      store.formData[StepType.SIGN_UP].email = payload.email //dependentId
      store.formData[StepType.SIGN_UP].dependentId = payload.dependentId
        ? payload.dependentId
        : ''
    },
    saveEligibilityPrefill(
      store,
      { payload }: { payload: EligibilityPrefillData }
    ) {
      store.formData[StepType.SHIPPING_ADDRESS].address.line1 =
        payload.address.line1
      store.formData[StepType.SHIPPING_ADDRESS].address.line2 =
        payload.address.line2 || ''
      store.formData[StepType.SHIPPING_ADDRESS].address.city =
        payload.address.city
      store.formData[StepType.SHIPPING_ADDRESS].address.state =
        payload.address.state
      store.formData[StepType.SHIPPING_ADDRESS].address.zip =
        payload.address.zip

      store.formData[StepType.ENTER_PHONE_NUMBER].mobileNumber =
        payload.phone || ''
      store.formData[StepType.SIGN_UP].firstName = payload.firstName || ''
      store.formData[StepType.SIGN_UP].lastName = payload.lastName || ''
      store.formData[StepType.ACC_INFO].dob = payload.dateOfBirth
      store.formData[StepType.ACC_INFO].sexAtBirth = payload.gender
        ? EligibilityGender[payload.gender]
        : ''

      store.formData[StepType.ACC_INFO].hasPrefillDOB = Boolean(
        payload.dateOfBirth
      )
      store.formData[StepType.SIGN_UP].hasPrefillLN = Boolean(payload.lastName)
      store.formData[StepType.SIGN_UP].hasPrefillFN = Boolean(payload.firstName)
    },
    saveDependentPrefill(store, { payload }: { payload: ProfileData }) {
      store.formData[StepType.SHIPPING_ADDRESS].address.line1 =
        payload.primaryAddress?.line1 || ''
      store.formData[StepType.SHIPPING_ADDRESS].address.line2 =
        payload.primaryAddress?.line2 || ''
      store.formData[StepType.SHIPPING_ADDRESS].address.city =
        payload.primaryAddress?.city || ''
      store.formData[StepType.SHIPPING_ADDRESS].address.state =
        payload.primaryAddress?.state || ''
      store.formData[StepType.SHIPPING_ADDRESS].address.zip =
        payload.primaryAddress?.zip || ''

      store.formData[StepType.ENTER_PHONE_NUMBER].mobileNumber =
        payload.contact.mobileNumber || ''
      store.formData[StepType.ACC_INFO].dob = payload.birthDate || null
      store.formData[StepType.ACC_INFO].sexAtBirth = payload.gender
        ? EligibilityGender[payload.gender]
        : ''
      store.formData[StepType.ACC_INFO].hasPrefillDOB = Boolean(
        payload.birthDate
      )
    },
    setFoundAddress(store, { payload }: { payload: USPSAddressModel }) {
      store.foundAddress.address.line1 =
        capitalizeString(payload.address2) || ''
      store.foundAddress.address.line2 = payload.address1
        ? capitalizeString(payload.address1) || ''
        : ''
      store.foundAddress.address.city = capitalizeString(payload.city) || ''
      store.foundAddress.address.state = payload.state
      store.foundAddress.address.zip = payload.zip
      store.foundAddress.isAddressExactMatch = payload.isAddressExactMatch
      store.foundAddress.isAddressFound = payload.isAddressFound
    },
    setEligibilityData(store, { payload }: { payload: AccountInformation }) {
      store.formData[StepType.ACC_INFO] = payload
    },
    resetError(store) {
      store.error = initialState.error
      store.warning = initialState.warning
    },
    setError(store, { payload }: { payload: string }) {
      store.error = payload
    },
    setWarning(store, { payload }: { payload: string }) {
      store.warning = payload
    },
    resetRegisterForm: () => initialState,
  },
})

const {
  setVerifSubmitting,
  setFoundAddress,
  saveEligibilityPrefill,
  setLoading,
} = registrationSlice.actions

export const {
  resetVerifError,
  saveEligibilityInfo,
  setEmail,
  saveAccountInfo,
  saveInsuranceInfo,
  savePolicyInfo,
  saveSignupInfo,
  resetRegisterForm,
  resetError,
  saveAddress,
  saveContact,
  saveInvitePrefill,
  setActive,
  setEligibilityError,
  setEligibiltyDialogOpen,
  setEligibilityTry,
  setError,
  setSignupType,
  setWarning,
  saveDependentPrefill,
} = registrationSlice.actions

export const submitUserRegistration =
  (params: UserCreate, callback: () => void) =>
  async (dispatch: any, getStore: any) => {
    dispatch(setError(''))
    dispatch(setLoading(true))
    const { pendingConsents } = getStore().termsAndCondsPage

    const verbiageKeys = pendingConsents?.map((item: Term) => item.key)

    const userData = { ...params, verbiageKeys }

    const { isOK, data, error, errorMessage } =
      await api.submitInviteUserRegistration(userData)

    if (error) {
      dispatch(setLoading(false))
      dispatch(
        setError(
          error || 'A server error occurred. Unable to register at this time'
        )
      )
      return false
    }

    if (isOK && data && data?.idToken) {
      dispatch(setLoading(false))
      sessionStorage.removeItem('refresh')
      sessionStorage.setItem('id_token', data.idToken as string)
      sessionStorage.setItem('access_token', data.accessToken as string)
      localStorage.setItem('email', params.email)
      lsClient.setUserLS('tenantId', params.tenantId)
      sendAuditLog('User log in', {
        action_type: 'create',
        date: moment().utc().format('MMM DD, YYYY hh:mm'),
        actor: params.email,
      })

      const saveDependent = (res: ProfileData) => {
        dispatch(saveDependentPrefill(res))
      }

      dispatch(
        getProfileData(true, params.isDependent ? saveDependent : undefined)
      )
      callback()
    } else {
      dispatch(
        setError(
          errorMessage ||
            'A server error occurred. Unable to register at this time'
        )
      )
      return false
    }
  }

export const getDefaultSignupTenant =
  (callback?: (response: NetworkSettings) => void) => async (dispatch: any) => {
    dispatch(setVerifSubmitting(true))
    const { data, errorMessage, isOK, error } =
      await api.getDefaultSignupTenant()
    dispatch(setVerifSubmitting(false))

    if (errorMessage) {
      dispatch(handleError(errorMessage))
    }

    if (error) {
      dispatch(handleError(error))
    }

    if (data && isOK) {
      localStorage.setItem('tenantId', data)
      dispatch(getNetworkSettings(data, callback))
    }
    // if (!data && isOK === false) {
    //   // dispatch(handleError({ message: 'Unable to get default signup tenant' }))
    //   localStorage.setItem('tenantId', fallbackDefaultTenant)
    //   dispatch(getNetworkSettings(fallbackDefaultTenant, callback))
    // }
  }

export const submitAccessCode =
  (accessCode: string, callback: (response: NetworkSettings) => void) =>
  async (dispatch: any) => {
    dispatch(setVerifSubmitting(true))
    const { data, errorMessage, isOK, error } = await api.submitAccessCode(
      accessCode
    )
    dispatch(setVerifSubmitting(false))

    if (errorMessage) {
      dispatch(setError(errorMessage))
    }

    if (error) {
      dispatch(setEligibilityError(error))
      dispatch(setEligibiltyDialogOpen(true))
    }

    if (data && isOK) {
      localStorage.setItem('tenantId', data)
      dispatch(getNetworkSettings(data, callback))
    }
  }
//onboardingComplete
export const getEligibility =
  (
    payload: {
      tenantId: string
      lastName: string
      dateOfBirth: string
      lastFourSSN: string
    },
    callback: () => void,
    i18n: I18n
  ) =>
  async (dispatch: any, getStore: any) => {
    dispatch(setVerifSubmitting(true))
    const { data, errorMessage, isOK, error } =
      await api.eligibilityVerification(payload)
    dispatch(setVerifSubmitting(false))

    const attempt = getStore().registration.formData[StepType.ELIGIBILITY].try
    if (error) {
      if (attempt < 6) {
        dispatch(setEligibilityTry(attempt + 1))
      }
      dispatch(setEligibilityError(error))
      dispatch(setEligibiltyDialogOpen(true))
    }

    if (data && isOK) {
      dispatch(setEligibilityTry(1))
      dispatch(saveEligibilityPrefill(data))
      callback()
    }
  }

export const completeEligibleOnboarding =
  (
    payload: {
      tenantId: string
      email: string
      is3rdParty: boolean
      isDependent: boolean
    },
    callback?: () => void
  ) =>
  async (dispatch: any, getStore: any) => {
    dispatch(setVerifSubmitting(true))
    const { data, errorMessage, isOK, error } = await api.onboardingComplete(
      payload
    )
    dispatch(setVerifSubmitting(false))

    if (error) {
      console.log('error')
    }

    if (callback) callback()
  }

export const requestRegistrationEmail =
  (
    payload: {
      email: string
      firstName: string
      lastName: string
      tenantId: string
      accountId: string
    },
    reSend: boolean,
    i18n: I18n,
    callback?: () => void
  ) =>
  async (dispatch: any) => {
    dispatch(setError(''))
    dispatch(setVerifSubmitting(true))
    const { data, errorMessage, isOK, error } =
      await api.registrationEmailRequest(payload)
    dispatch(setVerifSubmitting(false))

    if (errorMessage) {
      dispatch(handleError(errorMessage))
    }

    if (error) {
      if (error === 'User already exists!') {
        dispatch(setError(i18n.already_have_account_message))
      } else dispatch(handleError(error))
    }

    if (data && isOK) {
      if (reSend) {
        dispatch(setWarning(i18n.email_verification_resent))
      }
      if (callback) {
        callback()
      }
    }
  }

export const verifyRegistrationEmailCode =
  (
    payload: {
      email: string
      pin: string
      tenantId: string
      accountId: string
    },
    i18n: I18n,
    callback: () => void
  ) =>
  async (dispatch: any, getState: any) => {
    dispatch(setVerifSubmitting(true))
    const { data, errorMessage, isOK, error } =
      await api.verifyRegistrationEmailCode(payload)
    dispatch(setVerifSubmitting(false))

    if (errorMessage) {
      dispatch(setError(errorMessage))
    }

    if (error) {
      if (error === 'Invalid Verification Parameters') {
        dispatch(setError(i18n.verification_code_invalid_error))
      } else dispatch(setError(error))
    }

    if (data && isOK) {
      callback()
    }
  }

export const verifyAddress =
  (
    payload: {
      address1: string | undefined // address1 in this case is Apt. number
      address2: string // address2 in this case is Street address
      city: string
      state: string
      zip: string
    },
    callback: (address: Address) => void
  ) =>
  async (dispatch: any, getState: any) => {
    dispatch(setVerifSubmitting(true))
    const { data, errorMessage, success, error } = await api.verifyAddress(
      payload
    )
    dispatch(setVerifSubmitting(false))

    if (errorMessage) {
      dispatch(handleError(errorMessage))
    }

    if (error) {
      dispatch(handleError(error))
    }

    if (data && success) {
      dispatch(setFoundAddress(data))
      const found = {
        line1: capitalizeString(data.address2) || '',
        line2: data.address1 ? capitalizeString(data.address1) || '' : '',
        city: capitalizeString(data.city) || '',
        state: data.state,
        zip: data.zip,
      }
      callback(found)
    }
  }

//legacy endpoints
export const sendEmailCode = (email: string) => async (dispatch: any) => {
  dispatch(setVerifSubmitting(true))
  const { data, errorMessage, isOK, error } =
    await api.sendEmailVerificationCode(email)
  dispatch(setVerifSubmitting(false))

  if (errorMessage) {
    dispatch(setError(errorMessage))
  }

  if (error) {
    dispatch(setError(error))
  }

  if (data && isOK) {
    // dispatch(getProfileData())
    // return true
  }
  // return false
}

export const verifyEmailCode =
  (code: string) => async (dispatch: any, getState: any) => {
    const email = getCurrentUser() || localStorage.getItem('email')
    if (!email) return

    const payload = {
      email,
      pin: code,
    }

    dispatch(setVerifSubmitting(true))
    const { data, errorMessage, isOK, error } =
      await api.verifyEmailCodeRequest(payload)
    dispatch(setVerifSubmitting(false))

    if (errorMessage) {
      dispatch(setError(errorMessage))
    }

    if (error) {
      dispatch(setError(error))
    }

    if (data && isOK) {
      dispatch(getProfileData())
      return true
    }
    return false
  }

export const registrationReducer = registrationSlice.reducer
export const registrationReducerName = registrationSlice.name
interface RootStore {
  [registrationReducerName]: typeof initialState
}

export const selectRegistrationLoading = ({ registration }: RootStore) =>
  registration.loading

export const selectRegistrationFormData = ({ registration }: RootStore) =>
  registration.formData

export const selectRegisterError = ({ registration }: RootStore) =>
  registration.error

export const selectRegisterWarning = ({ registration }: RootStore) =>
  registration.warning

export const selectVerificationError = ({ registration }: RootStore) =>
  registration.verificationError

export const selectVerificationSubmitting = ({ registration }: RootStore) =>
  registration.verificationSubmitting

export const selectUserAddress = ({ registration }: RootStore) =>
  registration.formData[StepType.SHIPPING_ADDRESS]

export const selectFoundAddress = ({ registration }: RootStore) =>
  registration.foundAddress

export const selectEligibilityErrorDialog = ({ registration }: RootStore) =>
  registration.eligibiltyDialogOpen

export const selectEligibilityErrorMessage = ({ registration }: RootStore) =>
  registration.eligibiltyErrorMessage

export const getCurrentStep = ({ registration }: RootStore) =>
  registration.active

export const getSignupType = ({ registration }: RootStore) =>
  registration.signup

export const getEligibilityAttempt = ({ registration }: RootStore) =>
  registration.formData[StepType.ELIGIBILITY].try
