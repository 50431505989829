import { Box, TextField } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as WarningIcon } from '../../assets/icons/warning-icon.svg'
import { hexToRGBA } from '../../libs/helpers'
import { Typography } from '../../ui'

type CodeType = 'Integer' | 'Alpha-Numeric' | 'Any'

export interface VerificationCodeProps {
  setVerificationCode: (code: string) => void
  setSubmitReady: (prop1: boolean) => void
  clearError: () => void
  verifyError: string
  warning?: string
  submitting: boolean
  type?: CodeType
  simpleErrorView?: boolean
}

export const VerificationCode = (props: VerificationCodeProps) => {
  const {
    setVerificationCode,
    setSubmitReady,
    verifyError,
    warning,
    submitting,
    clearError,
    type = 'Integer',
    simpleErrorView,
  } = props
  const classes = useStyles()
  const [values, setValues] = useState<string[]>(['', '', '', '', '', ''])
  const theme = useTheme()
  const inputContainerRef = useRef<HTMLDivElement>(null)
  const refs = useRef([...new Array(6)])

  useEffect(() => {
    if (verifyError || warning) {
      refs.current.map((i) => (i.value = ''))
      setValues(['', '', '', '', '', ''])
      setSubmitReady(false)
      refs.current?.[0].focus()
    }
  }, [verifyError, warning])

  useEffect(() => {
    checkValues()
  }, [values])

  const checkValues = async () => {
    const codeIsFull =
      type === 'Integer'
        ? values.filter((v: string) => Number.isInteger(v)).length === 6
        : values.filter((v: string) => Boolean(v)).length === 6

    if (!codeIsFull || submitting || verifyError) {
      setSubmitReady(false)
      return
    }

    setVerificationCode(values.join(''))
    setSubmitReady(true)
  }

  const handleInputValue = (_v: number | string, index: number) => {
    const value =
      type === 'Integer'
        ? !Number.isNaN(_v)
          ? Number(_v.toString().slice(0, 1))
          : ''
        : _v
    setValues((prev: any[]) => {
      const updatedValues = [...prev]
      updatedValues[index] = value
      return updatedValues
    })
    refs.current[index].value = value

    if (
      ((type === 'Integer' &&
        Number.isInteger(value) &&
        !Number.isNaN(value)) ||
        type === 'Alpha-Numeric') &&
      index + 1 <= refs.current.length - 1
    ) {
      refs.current[index + 1].focus()
      refs.current[index + 1].setSelectionRange(0, 0)
      clearError()
    }
  }

  const handleChange = (e: any, index: any) => {
    if (e.nativeEvent.inputType === 'insertFromPaste') return
    clearError()
    let _v
    if (type === 'Integer') {
      _v = Number.parseInt(e.target.value, 10)
    } else _v = e.target.value
    handleInputValue(_v, index)
  }

  const onPaste = (e: any) => {
    clearError()
    let code: string[]

    if (type === 'Integer') {
      code = e.clipboardData
        .getData('Text')
        .replaceAll(/[^+\d]/g, '')
        .slice(0, 6)
        .split('')
    } else {
      code = e.clipboardData.getData('Text').slice(0, 6).split('')
    }

    code.forEach((value, index) => handleInputValue(value, index))
  }

  const onKeyDown = async (e: any, index: any) => {
    const currentValue = values[index]
    if (e.keyCode !== 8 && e.keycode !== 46) return

    clearError()

    if (currentValue || index - 1 < 0) return

    refs.current[index - 1].focus()
    values.splice(index, 1)
  }

  const renderErrorMessage = () => {
    if (!verifyError && !warning) return null
    const highlight = warning
      ? theme.palette.warning.main
      : theme.palette.error.main
    const background = warning
      ? hexToRGBA(theme.palette.warning.main, 0.2)
      : hexToRGBA(theme.palette.error.main, 0.2)

    setTimeout(() => {
      clearError()
    }, 3500)

    return (
      <>
        {simpleErrorView && !warning ? (
          <Typography
            style={{ color: highlight, marginTop: '8px' }}
            className={classes.errorText}
          >
            {verifyError || warning}
          </Typography>
        ) : (
          <Box
            className={classes.errorContainer}
            style={{ backgroundColor: background, borderColor: highlight }}
          >
            <WarningIcon style={{ fill: highlight, height: 20 }} />
            <Typography className={classes.errorText}>
              {verifyError || warning}
            </Typography>
          </Box>
        )}
      </>
    )
  }

  return (
    <Box className={classes.contentWrapper}>
      <Box className={classes.input}>
        <div className={classes.inputContainer} ref={inputContainerRef}>
          {refs.current.map((_item, index) => {
            const inputClass = `input-${index + 1}` as keyof typeof classes
            return (
              <Box
                key={inputClass}
                display="flex"
                alignItems="center"
                className={classes[inputClass]}
              >
                {!(index % 3) && index !== 0 ? (
                  <div className={classes.divider} />
                ) : null}
                <TextField
                  inputRef={(ref) => {
                    refs.current[index] = ref
                  }}
                  value={values[index]}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    inputMode: type === 'Integer' ? 'numeric' : 'text',
                  }}
                  autoFocus={index === 0}
                  onChange={(e) => handleChange(e, index)}
                  onPaste={onPaste}
                  onKeyDown={(e) => onKeyDown(e, index)}
                  variant="outlined"
                />
              </Box>
            )
          })}
        </div>
        {renderErrorMessage()}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  'contentWrapper': {
    maxWidth: '500px',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '8px',
    textAlign: 'center',
  },
  'profileBtnsWrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0',
  },
  'continueBtn': {
    'backgroundColor': 'rgb(16, 6, 159)',
    'textTransform': 'capitalize',
    'color': '#fff',
    'height': 48,
    'width': 190,
    'maxWidth': '100%',
    'fontSize': 18,
    '&:hover': {
      backgroundColor: '#2A1ED9',
    },
  },
  'backBtn': {
    'backgroundColor': '#fff',
    'height': 48,
    'width': 112,
    'maxWidth': '100%',
    'fontWeight': 500,
    'fontSize': 18,
    'textTransform': 'capitalize',
    'color': '#767993',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  'input': {
    'marginTop': 8,
    'display': 'flex',
    'flexDirection': 'column',
    '& input': {
      textAlign: 'center',
      fontSize: 36,
      fontWeight: 500,
      height: 57,
      width: 52,
      color: '#505358',
      [theme.breakpoints.down(600)]: {
        height: 20,
        width: 26,
        fontSize: 30,
      },
    },
  },
  'backdropWrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  'errorContainer': {
    width: '100%',
    display: 'flex',
    alignSelf: 'center',
    padding: '18px 28px',
    boxSizing: 'border-box',
    marginTop: 24,
    borderRadius: 8,
    border: '1px solid',
    justifyContent: 'center',
    gap: 8,
    alignItems: 'center',
  },
  'errorText': {
    textAlign: 'center',
    color: '#505358',
    fontWeight: 500,
    fontSize: 14,
    [theme.breakpoints.down(600)]: {
      fontSize: 12,
    },
  },
  'divider': {
    height: 1,
    margin: '0 5px',
    width: 8,
    backgroundColor: '#C4C4C4',
  },
  'input-1': {
    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  'input-2': {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
  },
  'input-3': {
    '& .MuiOutlinedInput-root': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  'input-4': {
    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  'input-5': {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
  },
  'input-6': {
    '& .MuiOutlinedInput-root': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  'inputContainer': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    alignSelf: 'center',
  },
}))
