import {
  Grid,
  IconButton,
  Snackbar,
  SnackbarContent,
  Typography,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined'
import CancelIcon from '@material-ui/icons/Cancel'
import classNames from 'classnames'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NOTIFICATION_TYPES } from '../../constants'
import { hexToRGBA } from '../../libs/helpers'
import { selectLanguageContent } from '../translation'
import {
  hideInactivityNotification,
  hideNotification,
  NotificationIcon,
  selectInactivityNotification,
  selectNotification,
} from './model'

export const Notification = () => {
  const notification = useSelector(selectNotification)
  const inactivityNotification = useSelector(selectInactivityNotification)
  const i18n = useSelector(selectLanguageContent)
  const theme = useTheme()
  const classes = useStyles(theme)
  const dispatch = useDispatch()
  const isTransparency =
    notification.notificationType === NOTIFICATION_TYPES.TRANSPARENCY
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(hideNotification())
  }

  const handleCloseInactivity = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(hideInactivityNotification())
  }

  const handleUpdate = async (event: any) => {
    //update service worker
    if (event) {
      event.preventDefault()
    }
    if (navigator?.serviceWorker) {
      const waitingServiceWorker = await navigator.serviceWorker.ready

      if (waitingServiceWorker.waiting) {
        waitingServiceWorker.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    }
    dispatch(hideNotification())
  }

  const getBackgroundColor = () => {
    switch (notification.notificationType) {
      case NOTIFICATION_TYPES.V2_ERROR:
      case NOTIFICATION_TYPES.ERROR:
        return theme.palette.error.main
      case NOTIFICATION_TYPES.V2_SUCCESS:
      case NOTIFICATION_TYPES.SUCCESS:
        return theme.palette.success.main
      case NOTIFICATION_TYPES.WARNING:
        return theme.palette.warning.main
      case NOTIFICATION_TYPES.TRANSPARENCY:
        return hexToRGBA('#000000', 0.8)
      case NOTIFICATION_TYPES.INFO:
      default:
        return theme.palette.info.main
    }
  }

  const getIcon = () => {
    switch (notification.icon) {
      case NotificationIcon.SHOPPING_CART: {
        return <AddShoppingCartOutlinedIcon style={{ ...getV2Style() }} />
      }

      default:
        return null
    }
  }

  const getV2Style = () => {
    if (notification.notificationType === NOTIFICATION_TYPES.V2_SUCCESS) {
      return {
        backgroundColor: '#BEFDBF',
        color: '#006403',
        borderRadius: '8px',
        borderColor: '#BEFDBF',
        maxWidth: 400,
      }
    }
    if (notification.notificationType === NOTIFICATION_TYPES.V2_ERROR) {
      return {
        backgroundColor: theme.palette.error.main,
        color: '#FFF',
        borderRadius: '8px',
        maxWidth: 400,
      }
    }
  }

  if (notification.notificationType.includes('v2')) {
    return (
      <Snackbar
        open={notification.visible}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={!notification.persist ? 3000 : null}
        style={{ ...getV2Style(), marginTop: 64 }}
      >
        <SnackbarContent
          className={classNames(classes.snackBar)}
          classes={{
            action: classes.contentAction,
          }}
          style={{
            ...getV2Style(),
          }}
          aria-describedby="client-snackbar"
          message={
            <div className={classes.contentMessageV2}>
              {notification.icon && <>{getIcon()}</>}
              <span
                id="client-snackbar"
                className={classNames(
                  `message-${notification.notificationType}`,
                  'message-text'
                )}
              >
                {notification.message}
              </span>

            </div>
          }
          action={
            notification.persist ? (
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.closeIcon}
                onClick={handleClose}
              >
                <CancelIcon className={classes.icon} />
              </IconButton>
            ) : undefined
          }
        />
      </Snackbar>
    )
  }

  return (
    <div>
      {!notification.update && (
        <Snackbar
          open={notification.visible}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={!notification.persist ? 3000 : null}
          style={{
            backgroundColor: isTransparency ? 'none' : '#FFF',
            borderRadius: '8px',
          }}
        >
          <SnackbarContent
            className={classNames(classes.snackBar)}
            classes={{
              action: classes.contentAction,
              message: classes.contentMessage,
              root: classes.root,
            }}
            style={{
              backgroundColor: isTransparency
                ? getBackgroundColor()
                : hexToRGBA(getBackgroundColor(), 0.2),
              borderColor: getBackgroundColor(),
              padding: '8px 16px',
              color: isTransparency ? '#FFF' : '#505358',
            }}
            aria-describedby="client-snackbar"
            message={
              <span
                id="client-snackbar"
                className={classNames(
                  `message-${notification.notificationType}`,
                  'message-text'
                )}
              >
                {notification.message}
              </span>
            }
            action={
              notification.persist ? (
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.closeIcon}
                  onClick={handleClose}
                >
                  <CancelIcon className={classes.icon} />
                </IconButton>
              ) : undefined
            }
          />
        </Snackbar>
      )}
      {inactivityNotification && (
        <Snackbar
          open={inactivityNotification}
          onClose={handleCloseInactivity}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          style={{ backgroundColor: '#FFF', borderRadius: '8px' }}
        >
          <SnackbarContent
            className={classNames(classes.snackBar)}
            classes={{
              action: classes.contentAction,
              message: classes.contentMessage,
              root: classes.root,
            }}
            style={{
              backgroundColor: hexToRGBA(getBackgroundColor(), 0.2),
              borderColor: getBackgroundColor(),
              padding: '12px 16px',
            }}
            aria-describedby="client-snackbar"
            message={
              <span
                id="client-snackbar"
                className={classNames(
                  `message-${notification.notificationType}`,
                  'message-text'
                )}
              >
                {i18n.inactivity_warning_message}
              </span>
            }
          />
        </Snackbar>
      )}
      {notification.update && (
        <Snackbar
          open={notification.visible}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          style={{ backgroundColor: '#FFF', borderRadius: '8px' }}
        >
          <SnackbarContent
            aria-describedby="client-snackbar"
            className={classNames(
              classes.snackBar,
              `message-${notification.notificationType}`
            )}
            message={
              <Grid container spacing={2}>
                <Grid item>
                  <Typography
                    variant="body2"
                    className={classes.updateMessage}
                    id="client-snackbar"
                  >
                    {notification.message}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    onClick={handleUpdate}
                    className={classes.updateNow}
                  >
                    Update Now
                  </Typography>
                </Grid>
              </Grid>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.closeIcon}
                onClick={handleClose}
              >
                <CancelIcon className={classes.icon} />
              </IconButton>,
            ]}
          />
        </Snackbar>
      )}
    </div>
  )
}

export function mapStateToProps(state: any) {
  return {
    ...state,
  }
}

const useStyles = makeStyles((theme) => ({
  'snackBar': {
    padding: '12px 0px',
    borderRadius: '8px',
    width: 'auto',
  },
  'icon': {
    fontSize: 24,
    margin: theme.spacing(1),
  },
  'iconVariant': {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  'updateMessage': {
    display: 'flex',
    alignItems: 'center',
  },
  'message-text': {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    lineHeight: '24px',
    padding: '0!important',
  },
  'message-error': {
    display: 'flex',
    alignItems: 'center',
  },
  'message-warning': {
    display: 'flex',
    alignItems: 'center',
  },
  'message-info': {
    display: 'flex',
    alignItems: 'center',
  },
  'message-success': {
    display: 'flex',
    alignItems: 'center',
  },
  'message-transparency': {
    display: 'flex',
    alignItems: 'center',
  },
  'margin': {
    margin: theme.spacing(1),
  },
  'closeIcon': {
    color: '#FFFFFF',
    padding: '0',
  },
  'stillHereButton': {
    color: '#FFFFFF',
    textTransform: 'none',
  },
  'contentAction': {
    paddingLeft: '30px',
  },
  'contentMessage': {
    padding: 4,
    fontSize: '16px',
    fontWeight: 500,
    margin: '0px auto',
  },
  'contentMessageV2': {
    padding: '4px 24px',
    fontSize: '16px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  'root': {
    flexWrap: 'nowrap',
    color: '#505358',
    border: '1px solid',
    minWidth: 300,
  },
  'updateNow': {
    '&:hover': {
      color: '#ffffff',
      opacity: '80%',
      cursor: 'pointer',
    },
    'textDecoration': 'underline',
    'fontWeight': 'bold',
  },
}))
