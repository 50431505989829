import { Box } from '@material-ui/core'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  allowedGenders,
  ALPHA_REGEX,
  ethnicities,
  races,
} from '../../../../../../constants'
import { selectLanguageContent } from '../../../../../../features/translation'
import { translateOptions } from '../../../../../../libs/helpers'
import { validateEntry } from '../../../../../../libs/utils'
import {
  Button,
  DateTextField,
  FilledTextField,
  Typography,
} from '../../../../../../ui'
import { FilledSelect } from '../../../../../../ui/atoms/select'
import { selectNetworkSettings } from '../../../../../network/model'
import { selectProfileData, updateUserData } from '../../../../../profile/model'
import { settingsStyles } from '../../../../styles'
import { FormSkeletonLoader } from './FormSkeletonLoader'
import { FormValues } from './type'
import validationSchema from './validationSchema'

export const MyProfilePage = () => {
  const classes = settingsStyles()
  const i18n = useSelector(selectLanguageContent)
  const profileData = useSelector(selectProfileData)
  const networkSettings = useSelector(selectNetworkSettings)
  const [dateFormatCorrect, setDateFormatCorrect] = useState(true)
  const [dateValid, setDateValid] = useState(true)

  const [edit, setEdit] = useState(false)

  const dispatch = useDispatch()

  const onCancel = () => {
    setEdit(false)
    formik.resetForm()
  }

  const onSubmit = (values: FormValues) => {
    const { firstName, lastName, dob, email, race, gender, ethnicity } = values
    const payload = {
      ...profileData,
      firstName,
      lastName,
      email,
      birthDate: moment(dob).format('MM/DD/YYYY'),
      race,
      gender,
      ethnicity,
    }
    dispatch(updateUserData(payload))
  }
  const {
    _id,
    firstName,
    lastName,
    birthDate,
    gender,
    race,
    ethnicity,
    email,
  } = profileData
  const formik = useFormik<FormValues>({
    initialValues: {
      firstName,
      lastName,
      email,
      dob: birthDate
        ? new Date(
            moment(birthDate.toString().split('Z')[0]).format('MM/DD/YYYY')
          )
        : null,
      gender,
      race,
      ethnicity,
    },
    onSubmit,
    enableReinitialize: true,
    validationSchema: validationSchema(i18n, networkSettings),
  })

  const renderFirstName = () => (
    <FilledTextField
      label={i18n.first_name}
      value={formik.values.firstName}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      disabled={!edit}
      onKeyDown={(event) => validateEntry(event, ALPHA_REGEX)}
      error={formik.touched.firstName && Boolean(formik.errors.firstName)}
      helperText={formik.touched.firstName ? formik.errors.firstName : ''}
      name="firstName"
      required
    />
  )

  const renderLastName = () => (
    <FilledTextField
      label={i18n.last_name}
      value={formik.values.lastName}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      disabled={!edit}
      onKeyDown={(event) => validateEntry(event, ALPHA_REGEX)}
      error={formik.touched.lastName && Boolean(formik.errors.lastName)}
      helperText={formik.touched.lastName ? formik.errors.lastName : ''}
      name="lastName"
      required
    />
  )

  const renderEmail = () => (
    <FilledTextField
      label={i18n.email}
      value={formik.values.email}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.email && Boolean(formik.errors.email)}
      helperText={formik.touched.email ? formik.errors.email : ''}
      name="email"
      type="email"
      disabled
      required
    />
  )

  const updateDate = (value: string) => {
    setDateFormatCorrect(
      Boolean(value.match(/(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/))
    )
    setDateValid(moment(value, 'MM/DD/YYYY', true).isValid())
    formik.setFieldValue('dob', value)
  }

  const renderDOB = () =>
    networkSettings?.collectDateOfBirth && (
      <DateTextField
        label={i18n.date_of_birth}
        value={moment(formik.values.dob).format('MM/DD/YYYY')}
        disabled={!edit || networkSettings.enableAccountVerificationScreen}
        i18n={i18n}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched.dob) && Boolean(formik.errors.dob)}
        helperText={(formik.touched.dob && formik.errors.dob) || ''}
        name="dob"
        handleChange={updateDate}
        inputProps={{
          inputMode: 'numeric',
        }}
        required={networkSettings.collectDateOfBirthRequired}
      />
    )

  const renderGender = () =>
    networkSettings?.collectSex && (
      <FilledSelect
        label={i18n.sex}
        options={translateOptions(allowedGenders, i18n)}
        value={formik.values.gender}
        fullWidth
        name="gender"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={!edit}
        error={formik.touched.gender && Boolean(formik.errors.gender)}
        helperText={formik.touched.gender ? formik.errors.gender : ''}
        required={networkSettings.collectSexRequired}
      />
    )

  const renderRace = () =>
    networkSettings?.collectRace && (
      <FilledSelect
        label={i18n.race}
        options={translateOptions(races, i18n)}
        value={formik.values.race}
        fullWidth
        name="race"
        disabled={!edit}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.race && Boolean(formik.errors.race)}
        helperText={formik.touched.race ? formik.errors.race : ''}
        required={networkSettings.raceRequired}
      />
    )

  const renderEthnicity = () =>
    networkSettings?.collectEthicity && (
      <FilledSelect
        label={i18n.ethnicity}
        options={translateOptions(ethnicities, i18n)}
        value={formik.values.ethnicity}
        fullWidth
        name="ethnicity"
        disabled={!edit}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.ethnicity && Boolean(formik.errors.ethnicity)}
        helperText={formik.touched.ethnicity ? formik.errors.ethnicity : ''}
        required={networkSettings.ehtnicityRequired}
      />
    )

  const renderForm = () => {
    if (!networkSettings || !_id) return <FormSkeletonLoader />

    return (
      <div className={classes.pageWrapper}>
        {!edit && (
          <Box className={classes.editBox} onClick={() => setEdit(true)}>
            <Typography color="primary">
              {i18n.edit_personal_information}
            </Typography>
          </Box>
        )}
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.form}>
            {renderFirstName()}
            {renderLastName()}
            {renderDOB()}
            {renderEmail()}
            {renderGender()}
            {renderEthnicity()}
            {renderRace()}
          </div>
          {edit && (
            <div className={classes.buttonContainer}>
              <Button
                variant="outlined"
                onClick={onCancel}
                className={classes.confirmButton}
              >
                {i18n.cancel_button}
              </Button>
              <Button
                className={classes.confirmButton}
                disabled={
                  !formik.isValid ||
                  (networkSettings.collectDateOfBirth && !dateFormatCorrect) ||
                  !dateValid
                }
                isLoading={formik.isSubmitting}
                type="submit"
              >
                {i18n.save_button}
              </Button>
            </div>
          )}
        </form>
      </div>
    )
  }

  return <div>{renderForm()}</div>
}
