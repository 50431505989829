import '../../../fonts.css'
import { Box, Grid, Theme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HeaderTransparencyMask } from '../../../features/application/atoms/app-header-mask'
import { TreatmentPlanSnackbar } from '../../../features/dashboard-status-card/dashboard-status-card'
import { getOrderStatus } from '../../../features/dashboard-status-card/model'
import {
  getHealthServiceList,
  selectHealthServiceIsLoading,
  selectHealthServiceList,
} from '../../../features/health-service-lite/model'
import { StatusMessage } from '../../../features/order-status-snackbar/orderStatusView'
import { selectLanguageContent } from '../../../features/translation'
import { usePullDown } from '../../../libs/hooks/useSwipe'
import { lsClient } from '../../../ls-client'
import { PageLoading } from '../../../ui'
import {
  selectDashboardButtons,
  selectDashboardCards,
  selectNetworkSettings,
  selectNetworkSettingsLoading,
} from '../../network/model'
import { DashboardButton, DashboardCard } from '../../network/type'
import {
  selectLatestResult,
  selectProfileData,
  selectUserAvatar,
  selectUserCode,
} from '../../profile/model'
import { TenantSelectionDialog } from '../../tenant-selection/tenant-selection-page'
import { selectActiveTenant } from '../../tenant-selection/tenantSlice'
import { getOrderResults, selectOrderList } from '../../test-details/model'
import {
  CareService,
  DashboardActionItem,
  Passport,
  TestHistory,
  UserItem,
} from '../components'
import { DashboardCardItem } from '../components/dashboardCardItem'
import { getPassportStatus, pageMounted, selectLoading } from '../model'

export const DashboardStyle = () => {
  const isLoading = useSelector(selectLoading)
  const networkLoading = useSelector(selectNetworkSettingsLoading)
  const healthServiceIsLoading = useSelector(selectHealthServiceIsLoading)
  const profileData = useSelector(selectProfileData)
  const networkSettings = useSelector(selectNetworkSettings)
  const dashboardButtons = useSelector(selectDashboardButtons)
  const dashboardCards = useSelector(selectDashboardCards)
  const testlist = useSelector(selectOrderList)
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  const activeTenant = useSelector(selectActiveTenant)
  const [tenantSelectionOpen, setTenantSelectionOpen] = useState(false)
  const { accountId, email, fullName, firstName, _id: userId } = profileData
  const userdata = lsClient.getUserLS()
  const tenantId = userdata?.tenantId
  const loading =
    isLoading || networkLoading || !userId || healthServiceIsLoading
  const userAvatar = useSelector(selectUserAvatar)
  const userCode = useSelector(selectUserCode)
  const lastAssessment = useSelector(selectLatestResult)
  const healthServiceList = useSelector(selectHealthServiceList)
  const dispatch = useDispatch()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(700))
  const swipeHandlers = usePullDown()

  useEffect(() => {
    //Ensure the scroll is at the top when returning from other views
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (accountId && email && activeTenant) {
      dispatch(pageMounted(accountId, email))
    }
  }, [dispatch, accountId, activeTenant])

  useEffect(() => {
    if (tenantId && userId) {
      dispatch(getHealthServiceList(userId, 'InProgress', email))
      dispatch(getOrderStatus())
      dispatch(getOrderResults())
    }
  }, [tenantId, userId])

  // eslint-disable-next-line unicorn/prefer-add-event-listener
  window.onpageshow = function (event) {
    if (event.persisted) {
      dispatch(getHealthServiceList(userId, 'InProgress', email))
    }
  }

  const desktopCardBuilder = (cards: DashboardCard[], columns: number) => {
    let index = 0
    let leftHeight = 0
    let rightHeight = 0
    const transparentCard = cards.filter((c) => c.style === 8)
    const standardCards = cards.filter((c) => c.style !== 8)
    const isSingleColumn = columns === 1
    const desktopColumns = [] as React.ReactNode[][]
    for (let i = 0; i < columns; i++) {
      desktopColumns.push([] as React.ReactNode[])
    }
    standardCards?.forEach((b: DashboardCard, i: number) => {
      if (!b.enabled) return
      if (leftHeight > rightHeight) {
        index = 1
        rightHeight += getCardHeight(b.style)
      } else {
        index = 0
        leftHeight += getCardHeight(b.style)
      }

      desktopColumns[index]?.push(
        <DashboardCardItem
          activeHealthService={
            Boolean(healthServiceList[0]) &&
            healthServiceList[0].status !== 'Completed'
          }
          button={b}
          i18n={i18n}
          key={`${b.cardTitle}-${i}`}
          xl={isSingleColumn}
        />
      )
    })

    return (
      <div
        className={
          isSingleColumn
            ? `${classes.cardContainerWrapper} single`
            : classes.cardContainerWrapper
        }
      >
        <div className={classes.cardWrapper}>
          {desktopColumns.map((col, i) => (
            <div className={classes.columns} key={`column-${i}`}>
              {col}
            </div>
          ))}
        </div>
        {transparentCard[0] && (
          <DashboardCardItem
            activeHealthService={false}
            button={transparentCard[0]}
            i18n={i18n}
            key={`${transparentCard[0].cardTitle}-copyright`}
          />
        )}
      </div>
    )
  }

  const mobileLayout = () => {
    return (
      <Box className={classes.parentWrapper} {...swipeHandlers}>
        <HeaderTransparencyMask position="top" />
        {/* <HeaderTransparencyMask position='bottom'/> */}
        {networkSettings?.enableProfileSectionDashboard && (
          <UserItem
            i18n={i18n}
            userAvatar={userAvatar}
            firstName={firstName}
            isMobile={isMobile}
          />
        )}
        {healthServiceList[0] && (
          <TreatmentPlanSnackbar healthServiceList={healthServiceList} />
        )}
        {networkSettings?.enableSnackbar && <StatusMessage />}
        {/* {networkSettings?.dashboardSettings.enableTestOrders && (
          <CareService i18n={i18n} networkSettings={networkSettings} />
        )} */}
        {dashboardCards && dashboardCards?.length > 0 ? (
          <div className={classes.cardWrapper}>
            {dashboardCards.map((b: DashboardCard, i) => (
              <DashboardCardItem
                activeHealthService={
                  Boolean(healthServiceList[0]) &&
                  healthServiceList[0].status !== 'Completed'
                }
                button={b}
                i18n={i18n}
                key={`${b.cardTitle}-${i}`}
              />
            ))}
          </div>
        ) : (
          <>
            {dashboardButtons?.length &&
              dashboardButtons.map(
                (b: DashboardButton) =>
                  b.visible && (
                    <DashboardActionItem
                      activeHealthService={
                        Boolean(healthServiceList[0]) &&
                        healthServiceList[0].status !== 'Completed'
                      }
                      button={b}
                      i18n={i18n}
                      key={b.cardTitle ? b.cardTitle : b.title}
                    />
                  )
              )}
          </>
        )}
      </Box>
    )
  }

  const desktopLayout = () => {
    return (
      <Box className={classes.parentWrapper}>
        <HeaderTransparencyMask position="top" />
        <Box className={classes.desktopWrapper}>
          {networkSettings?.enableProfileSectionDashboard && (
            <UserItem
              i18n={i18n}
              userAvatar={userAvatar}
              firstName={firstName}
            />
          )}
          {healthServiceList[0] && (
            <TreatmentPlanSnackbar healthServiceList={healthServiceList} />
          )}
          {networkSettings?.enableSnackbar && <StatusMessage />}
          {dashboardCards && dashboardCards.length > 0 ? (
            <>
              {desktopCardBuilder(
                dashboardCards,
                dashboardCards.length > 1 ? 2 : 1
              )}
            </>
          ) : (
            <>
              <Box className={classes.largeCardsContainer}>
                {dashboardButtons?.[0]?.visible && !healthServiceList[0] && (
                  //Large Button - current functionality for desktop - Large button is always 1st, or Primary dashboard button
                  <Box className={classes.cardsBox}>
                    <DashboardActionItem
                      activeHealthService={Boolean(healthServiceList[0])}
                      button={dashboardButtons[0]}
                      i18n={i18n}
                      xl
                    />
                  </Box>
                )}
                <Box style={{ width: '100%' }}>
                  <Grid container>
                    {dashboardButtons?.[1]?.visible && (
                      //Medium Button - current functionality for desktop - smaller button is always 2nd, or Secondary dashboard button
                      //**Tertiary or any additional buttons not shown on desktop / tablet sizing
                      <Grid item xs={12} sm={healthServiceList[0] ? 6 : 12}>
                        <div
                          className={
                            healthServiceList[0]
                              ? classes.mr1
                              : classes.cardMargin
                          }
                        >
                          <DashboardActionItem
                            activeHealthService={
                              Boolean(healthServiceList[0]) &&
                              healthServiceList[0].status !== 'Completed'
                            }
                            button={dashboardButtons[1]}
                            i18n={i18n}
                          />
                        </div>
                      </Grid>
                    )}
                    {networkSettings?.isSAFEPassportEnabled && (
                      <Grid item xs={12} sm={healthServiceList[0] ? 6 : 12}>
                        <div
                          className={
                            healthServiceList[0] ? classes.ml1 : classes.mt1
                          }
                        >
                          <Passport
                            i18n={i18n}
                            fullName={fullName}
                            userCode={userCode}
                            lastAssessment={lastAssessment}
                            networkSettings={networkSettings}
                          />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
              {networkSettings?.dashboardSettings.enableTestHistory &&
                testlist.length > 0 && (
                  <Box className={classes.testHistoryList}>
                    {testlist.map((t, i) => (
                      <TestHistory
                        key={t._id}
                        i18n={i18n}
                        isMobile={isMobile}
                        test={t}
                        networkSettings={networkSettings}
                        isLatest={i === 0}
                      />
                    ))}
                  </Box>
                )}
            </>
          )}
          {/* )} */}
          {/* {networkSettings?.dashboardSettings.enableTestOrders && (
            <CareService i18n={i18n} networkSettings={networkSettings} />
          )} */}
        </Box>
      </Box>
    )
  }

  if (loading) return <PageLoading />

  return (
    <>
      {isMobile ? mobileLayout() : desktopLayout()}
      <TenantSelectionDialog
        open={tenantSelectionOpen}
        dialogOpen={setTenantSelectionOpen}
      />
    </>
  )
}

const getCardHeight = (style: number) => {
  //estimated card height + flex gap
  switch (style) {
    case 5:
    case 6:
      return 360 + 48
    case 7:
      return 630 + 48
    case 4:
      return 785 + 48
    case 2:
    case 3:
    case 9:
    case 10:
    case 11:
      return 260 + 48
    default:
      return 0
  }
}

const useStyles = makeStyles((theme) => ({
  parentWrapper: {
    margin: '0px 0px 48px',
    [theme.breakpoints.up(1200)]: {
      margin: '0px 0px 84px 48px',
    },
  },
  desktopWrapper: {
    marginRight: '0px',
    maxWidth: '1100px',
    [theme.breakpoints.down(960)]: {
      maxWidth: '100vw',
    },
  },
  largeCardsContainer: {
    display: 'flex',
    [theme.breakpoints.down(850)]: {
      flexDirection: 'column',
    },
  },
  columns: {
    minWidth: 'calc(50% - 24px)',
    display: 'flex',
    gap: 48,
    flexDirection: 'column',
    [theme.breakpoints.down(960)]: {
      minWidth: 'calc(50% - 12px)',
      gap: 24,
    },
  },
  cardWrapper: {
    display: 'flex',
    gap: 48,
    margin: '24px 0px 64px',
    [theme.breakpoints.down(960)]: {
      margin: '24px 0px 48px',
      gap: 24,
    },
    [theme.breakpoints.down(700)]: {
      width: '100%',
      flexDirection: 'column',
      margin: '24px 0px 48px',
    },
  },
  mr1: {
    marginRight: '8px',
  },
  ml1: {
    marginLeft: '8px',
  },
  mt1: {
    marginTop: '12px',
  },
  cardsBox: {
    marginRight: '24px',
    [theme.breakpoints.down(850)]: {
      marginRight: '0px',
    },
  },
  cardMargin: {
    marginBottom: '12px',
    [theme.breakpoints.down(850)]: {
      marginBottom: '0px',
    },
  },
  linkButton: {
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textTransform: 'none',
  },
  cardButton: {
    maxWidth: 124,
    flex: 0,
  },
  takeTestImage: {
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 172,
      height: 164,
    },
  },
  cardContainerWrapper: {
    'width': '100%',
    'display': 'flex',
    'gap': 64,
    'flexDirection': 'column',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    '&.single': {
      alignItems: 'flex-start',
    },
  },
  dashboardButtonCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  dashboardButtonTitle: {
    fontSize: 24,
    color: '#FFF',
    marginBottom: 8,
  },
  dashboardButtonBody: {
    fontSize: 14,
    color: '#FFF',
    lineHeight: '19px',
  },
  testHistoryList: {
    'padding': '8px',
    'marginLeft': '-8px',
    'marginTop': '16px',
    'display': 'flex',
    'overflowY': 'scroll',
    '-webkit-mask-image':
      'linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 3%, rgba(255,255,255,1) 10%)',
    'maskImage':
      'linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 3%, rgba(255,255,255,1) 10%)',
  },
}))
