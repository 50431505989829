import { styled } from '@material-ui/core/styles'
import createDOMPurify from 'dompurify'
import React, { ReactChild } from 'react'

const DOMPurify = createDOMPurify(window)

export const HTMLTextComponent = (props: {
  HTMLString: string | null
  className?: string
}) => {
  const { HTMLString, className } = props
  if (!HTMLString) return null
  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noopener')
    }
  })

  return (
    <span
      className={className}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(HTMLString),
      }}
    />
  )
}

// export const ButtonWrapper = ({ children }: { children: ReactChild }) => {
//   return <ButtonWrapper>{children}</ButtonWrapper>
// }

export const NativeButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#FFF',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  padding: '32px 0px',
  position: 'relative',
  width: '100%',
  zIndex: 10,
  [theme.breakpoints.down(600)]: {
    position: 'fixed',
    left: 0,
    bottom: '16px',
    padding: '0px',
    width: '100%',
    maxWidth: '90vw',
  },
}))

export const XLResponsiveContentWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 1800,
  margin: '0px auto',
}))
