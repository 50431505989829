import { makeStyles } from '@material-ui/core/styles'

export const settingsStyles = makeStyles((theme) => ({
  tabWrapper: {
    display: 'flex',
    width: '100%',
    marginTop: '24px',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      marginTop: '8px',
    },
  },
  settingsList: {
    width: 240,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    [theme.breakpoints.down(600)]: {
      width: '100%',
      gap: 0,
    },
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 32,
    gridRowGap: 24,
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  personalInfoWrapper: {
    boxSizing: 'border-box',
    padding: '40px 0 16px',
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
  },
  title: {
    marginBottom: '24px',
  },
  activeTab: {
    width: '100%',
    margin: '10px 24px',
    [theme.breakpoints.down(600)]: {
      margin: '0px',
      height: 'calc(100vh - 64px)',
      overflow: 'auto',
    },
  },
  item: {
    'cursor': 'pointer',
    '&.Mui-selected': {
      'backgroundColor': 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& span': {
        fontWeight: 500,
        color: theme.palette.primary.main,
      },
    },
    '& span': {
      fontWeight: 500,
    },
  },
  wrapper: {
    boxSizing: 'border-box',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
  },
  formContainer: {
    display: 'grid',
    gridColumnGap: 36,
    justifyContent: 'space-between',
    width: '100%',
    gridTemplateColumns: '1fr',
    gridRowGap: 24,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    justifyContent: 'flex-end',
    gap: 24,
    [theme.breakpoints.down(600)]: {
      zIndex: 101,
      backgroundColor: '#FFF',
      padding: '24px 0px',
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      flexDirection: 'column',
      gap: 24,
    },
  },
  confirmButton: {
    maxWidth: 'fit-content',
    padding: '0px 24px',
    [theme.breakpoints.down(600)]: {
      padding: '5px 24px',
      maxWidth: '90vw',
    },
  },
  editBox: {
    marginTop: '-50px',
    alignSelf: 'flex-end',
    cursor: 'pointer',
    marginBottom: '26px',
    [theme.breakpoints.down(600)]: {
      marginTop: '0px',
    },
  },
  mobileEdit: {
    marginTop: '-50px',
    alignSelf: 'flex-end',
    cursor: 'pointer',
    [theme.breakpoints.down(600)]: {
      marginTop: '0px',
    },
  },
  forgetWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    gap: 26,
    width: '100%',
  },
}))
