import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { Theme, useMediaQuery } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config'
import { hexToRGBA } from '../../../libs/helpers'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { useAuth0 } from '../../../libs/useAuth0'
import { applicationMounted } from '../model'
import { FooterNavigation } from '../molecules/footer-navigation'
import { ApplicationDrawer } from './application-drawer'
import { ApplicationHeader } from './application-header'
import { ApplicationRefreshToken } from './application-refresh-token'

export const ApplicationLayout = ({ route }: RouteConfigComponentProps) => {
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up(1200))
  const isSmallDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(960)
  )
  const { primary } = useMUITheme().colors
  const { isReady, isUserLogged } = useAuth0({
    closedForAnonymous: true,
    closedForLoggedIn: false,
  })

  useEffect(() => {
    if (!isReady || !isUserLogged) return
    dispatch(applicationMounted())
  }, [isReady])

  if (!route || !isReady || !isUserLogged) return null
  return (
    <LayoutWrapper style={{ backgroundColor: hexToRGBA(primary, 0.05) }}>
      <ApplicationHeader
        isDesktop={isDesktop}
        isSmallDesktop={isSmallDesktop}
      />
      <ApplicationDrawer
        isDesktop={isDesktop}
        isSmallDesktop={isSmallDesktop}
      />
      <ContentWrapper>{renderRoutes(route.routes)}</ContentWrapper>
      <Global styles={styles} />
      {!isSmallDesktop && <FooterNavigation />}
    </LayoutWrapper>
  )
}
const ContentWrapper = styled.main`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding: 32px 20px;
  z-index: 1;
  position: relative;
  @media (max-width: 599px) {
    align-items: center;
    padding: 0px 32px;
  }
  @media (min-width: 600px) {
    padding: 32px;
  }
  @media (min-width: 1200px) {
    padding: 32px 48px 0px 275px;
  }
`
const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
`

const styles = css`
  #root {
    min-height: 100vh;
    display: flex;
  }
`
