export interface WorkflowItem {
  id: string
  name: string
  description: string
}

export enum FlowEngineModules {
  INTRO = 'intro',
  NEXT = 'next',
  PROCESSING = 'processing',
}
