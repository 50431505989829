import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ChevronRightSharp'
import { Typography } from '../../ui'
import { I18n } from '../translation/types'
import {
  AddressField,
  ButtonField,
  ClinicianNotesArray,
  CredentialHeaderField,
  DateField,
  FVFPrescriptionList,
  LinkField,
  ListFieldText,
  PhamacyListField,
  PhoneField,
  ResultField,
  SAFEPrescriptionList,
  SmallTextArrayField,
  SmallTextField,
  SummaryField,
  TestField,
  TextField,
  TitleField,
} from './fields'
import { TestDetailFields } from './types'

export const displayFields = (
  fields: TestDetailFields[],
  i18n: I18n,
  details: any
) => {
  const classes = useStyles()
  const steps = [] as any
  fields.forEach((field: TestDetailFields, i: number) => {
    if (field.type === 'text') {
      steps.push(
        <TextField
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'test') {
      steps.push(
        <TestField
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'smallText') {
      steps.push(
        <SmallTextField
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'smallTextArray') {
      steps.push(
        <SmallTextArrayField
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'listText') {
      steps.push(
        <ListFieldText
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'notesArray') {
      steps.push(
        <ClinicianNotesArray
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'title') {
      steps.push(
        <TitleField
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'FVFprescriptionList') {
      steps.push(
        <FVFPrescriptionList
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'SAFEprescriptionList') {
      steps.push(
        <SAFEPrescriptionList
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'dateAndTime') {
      steps.push(
        <DateField
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'result') {
      steps.push(
        <ResultField
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'pharmaciesList') {
      steps.push(
        <PhamacyListField
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'address') {
      steps.push(
        <AddressField
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'phone') {
      steps.push(
        <PhoneField
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'link') {
      steps.push(
        <LinkField
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'button') {
      steps.push(
        <ButtonField
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'credential') {
      steps.push(
        <CredentialHeaderField
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'summary') {
      steps.push(
        <SummaryField
          key={`${i}-${field.type}`}
          i18n={i18n}
          field={field}
          test={details}
        />
      )
    } else if (field.type === 'group') {
      steps.push(
        <Box className={classes.optionWrapper} key={`${i}-${field.type}`}>
          <Box className={classes.testDescription}>
            {displayFields(field.fields, i18n, details)}
          </Box>
        </Box>
      )
    } else if (field.type === 'groupNoPaper') {
      steps.push(
        <Box className={classes.paperless} key={`${i}-${field.type}`}>
          {displayFields(field.fields, i18n, details)}
        </Box>
      )
    } else if (field.type === 'accordion') {
      steps.push(
        <Box
          className={`${classes.paperless} noBorder`}
          key={`${i}-${field.type}`}
        >
          <Accordion
            TransitionProps={{ timeout: 250 }}
            elevation={0}
            variant="outlined"
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  color="primary"
                  className={classes.expandIcon}
                />
              }
              style={{ padding: 0 }}
            >
              <div>
                <Typography className={classes.title}>
                  {field.plainTextName}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails
              style={{
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
              }}
            >
              {displayFields(field.fields, i18n, details)}
            </AccordionDetails>
          </Accordion>
        </Box>
      )
    }
  })
  return steps
}

const SHADOW = '4px 4px 8px rgba(0,0,0,0.2)'

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1220px',
    },
  },
  paperless: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'flex-start',
    'minHeight': '44px',
    'margin': '0px auto',
    'width': '100%',
    'borderBottom': '1px solid #A4A4A4',
    'padding': '24px 0px',
    '&.noBorder': {
      borderBottom: 'none',
    },
  },
  testDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  optionWrapper: {
    'minHeight': '44px',
    'padding': '10px 16px',
    'borderRadius': 12,
    'display': 'flex',
    'justifyContent': 'space-between',
    // border: '1px solid #E6E7EA',
    'margin': '16px auto',
    'cursor': 'pointer',
    'alignItems': 'center',
    'backgroundColor': '#FFF',
    '-webkit-box-shadow': SHADOW,
    '-moz-box-shadow': SHADOW,
    'box-shadow': SHADOW,
    [theme.breakpoints.down(600)]: {
      margin: '0px auto 16px',
    },
  },
  accordion: {
    'width': '100%',
    'border': 'none',
    '&::before': {
      opacity: 0,
    },
    '&.MuiAccordion-root': {
      padding: '0px !important',
    },
    '&.MuiAccordion-rounded:last-child': {
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
    },
  },
  testName: {
    color: '#54565B',
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
  },
  title: {
    color: '#505358',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 600,
  },
  expandIcon: {
    transform: 'rotate(90deg)',
  },
}))
