import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { addDays, differenceInHours, differenceInMinutes } from 'date-fns'
import React from 'react'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { Button, Typography } from '../../../ui'
import { StatusSnackbarProps } from '../types'

export const SimpleView = (props: StatusSnackbarProps) => {
  const { networkSettings, healthService, step, endHealthService, i18n } = props
  const buttonFlex = step.mobileButtonFlex || 'row'
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 16
  const classes = useStyles({ radius, buttonFlex })
  const { isMobile } = useMUITheme()

  const getTimeRemaining = () => {
    const expires = addDays(new Date(healthService.createdAt), 1)
    const remainingHours = differenceInHours(expires, new Date())
    const remainingMinutes = differenceInMinutes(expires, new Date())
    return (
      <>
        <Typography align="center" className={classes.hours}>
          {remainingHours > 0
            ? `${remainingHours}hr`
            : `${remainingMinutes}min`}
        </Typography>
        <Typography align="center" className={classes.remaining}>
          {i18n.remaining_label}
        </Typography>
      </>
    )
  }

  const mobileView = () => (
    <>
      <Box className={classes.content}>
        <Box className={classes.textContainer}>
          <Box className={classes.messageHeaderContainer}>
            <Typography variant="h2" className={classes.messageHeader}>
              {step.header}
            </Typography>
          </Box>
          <Typography variant="h2" className={classes.messageBody}>
            {step.body}
          </Typography>
        </Box>
        {step.graphic === 'IMAGE' ? (
          <div onClick={() => endHealthService()}>{step.image()}</div> // Remove this onClick once dev complete
        ) : (
          <Box className={classes.imageContainer}>
            <Box className={classes.timeContainer}>{getTimeRemaining()}</Box>
          </Box>
        )}
      </Box>
      {step.actions.length > 0 && (
        <Box className={classes.buttonContainer}>
          {step.actions.map((action) => (
            <React.Fragment key={action.title}>
              {action.variant === 'text' ? (
                <Box
                  onClick={(event) => {
                    event.stopPropagation()
                    action.function()}
                  }
                  className={
                    action.smallButton ? classes.smallButton : classes.button
                  }
                >
                  <Typography
                    className={
                      action.smallButton ? classes.smallButton : classes.button
                    }
                  >
                    {action.title}
                  </Typography>
                </Box>
              ) : (
                <Button
                  onClick={(event) => {
                    event.stopPropagation()
                    action.function()}
                  }
                  variant={action.variant}
                  disabled={action.disabled}
                  className={
                    action.smallButton ? classes.smallButton : classes.button
                  }
                >
                  {action.title}
                </Button>
              )}
            </React.Fragment>
          ))}
        </Box>
      )}
    </>
  )

  const desktopView = () => (
    <Box className={classes.content}>
      {step.graphic === 'IMAGE' ? (
        <div onClick={() => endHealthService()}>{step.image()}</div> // Remove this onClick once dev complete
      ) : (
        <Box className={classes.imageContainer}>
          <Box className={classes.timeContainer}>{getTimeRemaining()}</Box>
        </Box>
      )}
      <Box className={classes.textContainer}>
        <Box className={classes.messageHeaderContainer}>
          <Typography variant="h2" className={classes.messageHeader}>
            {step.header}
          </Typography>
        </Box>
        <Typography variant="h2" className={classes.messageBody}>
          {step.body}
        </Typography>
        {step.actions.length > 0 && (
          <Box className={classes.buttonContainer}>
            {step.actions.map((action) => (
              <Button
                key={action.title}
                onClick={(event) => {
                  event.stopPropagation()
                  action.function()}
                }
                variant={action.variant}
                disabled={action.disabled}
                className={classes.button}
              >
                {action.title}
              </Button>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )

  return isMobile ? mobileView() : desktopView()
}

const useStyles = makeStyles((theme) => ({
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.up(600)]: {
      height: 126,
      width: 126,
    },
  },
  hours: {
    color: '#FFF',
    fontWeight: 600,
    fontSize: 48,
    lineHeight: '48px',
    [theme.breakpoints.down(600)]: {
      fontSize: 20,
      lineHeight: '20px',
    },
  },
  remaining: {
    color: '#FFF',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '24px',
    [theme.breakpoints.down(600)]: {
      fontSize: 10,
      lineHeight: '10px',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    marginTop: '16px',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(600)]: {
      marginTop: '0px',
    },
  },
  messageHeaderContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down(960)]: {
      alignItems: 'center',
    },
  },
  textContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginLeft: 48,
    [theme.breakpoints.down(960)]: {
      marginLeft: 16,
    },
    [theme.breakpoints.down(600)]: {
      marginLeft: 0,
      width: '75%',
    },
  },
  imageContainer: {
    'backgroundColor': theme.palette.primary.main,
    'padding': '12px',
    '-webkit-mask-image':
      '-webkit-radial-gradient(circle, white 100%, black 100%)',
    'borderRadius': ({
      radius,
      buttonFlex,
    }: {
      radius: number
      buttonFlex: 'row' | 'column'
    }) => radius,
    [theme.breakpoints.down(600)]: {
      height: 40,
      width: 40,
    },
  },
  headerIcon: {
    marginTop: '-6px',
    height: '36px',
    [theme.breakpoints.down(600)]: {
      height: '18px',
    },
  },
  button: {
    'margin': '0px auto',
    'maxWidth': 'fit-content',
    'maxHeight': 46,
    'padding': '8px 20px',
    'fontSize': '16px',
    'fontWeight': 600,
    '& span': {
      padding: '2px 4px',
      whiteSpace: 'noWrap',
    },
    '&.MuiButton-outlined': {
      'color': '#505358',
      'borderColor': '#505358',
      '&:hover': {
        backgroundColor: '#505358',
        borderColor: '#505358',
        color: '#FFF !important',
      },
    },
    '&.MuiButton-contained': {
      'backgroundColor': '#505358',
      'borderColor': '#505358',
      '&:hover': {
        borderColor: '#505358',
        backgroundColor: '#FFF',
        color: '#505358 !important',
      },
    },
    [theme.breakpoints.down(600)]: {
      marginTop: '8px',
      fontSize: '14px',
      fontWeight: 600,
      maxWidth: 'unset',
      maxHeight: '33px',
    },
  },
  smallButton: {
    'maxHeight': '42px',
    'maxWidth': '45%',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      fontWeight: 500,
      maxHeight: '33px',
      lineHeight: '14px',
      marginTop: 8,
    },
    '&.MuiButton-outlined': {
      color: '#505358',
      borderColor: '#505358',
    },
    '&.MuiButton-contained': {
      backgroundColor: '#505358',
      borderColor: '#505358',
    },
  },
  messageHeader: {
    fontWeight: 600,
    color: '#505358',
    fontSize: '32px',
    lineHeight: '40px',
    [theme.breakpoints.down(960)]: {
      fontSize: '28px',
      lineHeight: '32px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  messageBody: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#757575',
    marginTop: 12,
    [theme.breakpoints.down(960)]: {
      fontSize: '18px',
      lineHeight: '24px',
      marginTop: 6,
    },
    [theme.breakpoints.down(600)]: {
      lineHeight: '16px',
      fontSize: '14px',
    },
  },
}))
