import { Box, Link as MUILink } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { VerificationCode } from '../../../../../../features/code-verification/code-verification'
import {
  getSavedDeviceValues,
  userHasDeviceCookie,
} from '../../../../../../features/cookies/cookie-helper'
import { selectLanguageContent } from '../../../../../../features/translation'
import {
  addMFAPreference,
  forgetMFADevice,
  resetError,
  selectDevice,
  selectUserError,
  selectUserLoading,
  selectUserWarning,
  sendPhoneVerifyCode,
  verifyPhone,
} from '../../../../../../features/user/model/userTenantSlice'
import { lsClient } from '../../../../../../ls-client'
import {
  Button,
  Checkbox,
  ContentLayout,
  Typography,
} from '../../../../../../ui'
import { selectNetworkSettings } from '../../../../../network/model'
import { paths } from '../../../../../paths'
import {
  selectProfileData,
  updateUserContact,
} from '../../../../../profile/model'
import {
  AccountSettingsType,
  SecuritySettingsType,
  SettingsTabType,
} from '../../../../type'

export const PhoneVerify = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [cookies, setCookie, removeCookie] = useCookies()
  const i18n = useSelector(selectLanguageContent)
  const profileData = useSelector(selectProfileData)
  const error = useSelector(selectUserError)
  const warning = useSelector(selectUserWarning)
  const loading = useSelector(selectUserLoading)
  const [submitReady, setSubmitReady] = useState(false)
  const [code, setCode] = useState('')
  const rememberedDevice = userHasDeviceCookie(profileData.email)
  const updatePhoneRequest = lsClient.getUserLSByKey('contact_update')
  const tenantId = lsClient.getUserLSByKey('tenantId')

  useEffect(() => {
    return () => {
      setCode('')
      setSubmitReady(false)
      sessionStorage.removeItem('codeSent')
    }
  }, [])

  const submitVerifyCode = () => {
    const { tenantId, accountId, email, _id } = profileData
    const data = {
      _id,
      mobileNumber: updatePhoneRequest,
      mobileCountryCode: '+1',
      tenantId,
      accountId,
      email,
      code,
    }
    dispatch(verifyPhone(data, onSubmit))
  }

  const forgetDevice = () => {
    const callback = () => {
      removeCookie(`${btoa(profileData.email)}_safe_device`, { path: '/' })
      lsClient.removeUserKeyLS('contact_update')
      backToSettings()
    }
    const accessToken = sessionStorage.getItem('access_token')
    const deviceParams = getSavedDeviceValues(profileData.email)
    const apiParams = {
      ...deviceParams,
      accessToken,
      username: profileData.email,
    }
    dispatch(forgetMFADevice(apiParams, callback, i18n))
  }

  const onSubmit = () => {
    const callback = () => {
      lsClient.removeUserKeyLS('contact_update')
      backToSettings()
    }
    const data = {
      tenantId,
      _id: profileData._id,
      contact: {
        ...profileData.contact,
        mobileCountryCode: '+1',
        mobileNumber: updatePhoneRequest,
      },
    }
    dispatch(
      updateUserContact(data, true, rememberedDevice ? forgetDevice : callback)
    )
  }

  const sendCode = () => {
    const { _id } = profileData
    const data = {
      _id,
      mobileNumber: updatePhoneRequest,
      mobileCountryCode: '+1',
      tenantId,
      contact: {
        mobileCountryCode: '+1',
        mobileNumber: updatePhoneRequest,
      },
    }
    dispatch(sendPhoneVerifyCode(data, true, i18n))
  }

  const backToSettings = () =>
    history.push(
      paths.settingsTab(
        SettingsTabType.ACCOUNT,
        AccountSettingsType.MOBILE_NUMBER
      )
    )

  const clearErrors = () => {
    if (
      error === i18n.attempt_limit_reached ||
      error.includes('not a valid phone number')
    ) {
      setTimeout(() => {
        dispatch(resetError())
        lsClient.removeUserKeyLS('contact_update')
        backToSettings()
      }, 3500)
    } else dispatch(resetError())
  }

  return (
    <Box className={classes.pageWrapper}>
      <ContentLayout className={classes.form}>
        <Typography className={classes.title} variant="h2">
          {i18n.mfa_initial_setup_title}
        </Typography>
        <Typography className={classes.body}>
          {i18n.contact_changed_verify_description}
        </Typography>
        <VerificationCode
          setVerificationCode={setCode}
          setSubmitReady={setSubmitReady}
          verifyError={error}
          warning={warning}
          clearError={clearErrors}
          submitting={false}
          type="Integer"
          simpleErrorView={true}
        />
        <MUILink
          role="button"
          color="primary"
          className={classes.pageLink}
          onClick={() => sendCode()}
        >
          {i18n.sms_verification_resend}
        </MUILink>
        <div className={classes.buttonWrapper}>
          <Button
            color="primary"
            variant="outlined"
            className={classes.actionBtn}
            onClick={backToSettings}
          >
            {i18n.cancel_button}
          </Button>
          <Button
            color="primary"
            className={classes.actionBtn}
            disabled={!submitReady || loading}
            onClick={submitVerifyCode}
          >
            {i18n.save_button}
          </Button>
        </div>
      </ContentLayout>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    maxWidth: '900px',
  },
  body: {
    color: '#505358',
    fontSize: '16px',
    fontFamily: 'Avenir Next, Avenir Next Rounded medium',
    fontWeight: 500,
    lineHeight: '22px',
    alignSelf: 'flex-start',
    [theme.breakpoints.down(600)]: {
      margin: ' 0px 20px',
    },
  },
  checkboxWrapper: {
    marginLeft: '18px',
  },
  title: {
    fontWeight: 500,
    marginBottom: '16px',
    alignSelf: 'flex-start',
  },
  buttonWrapper: {
    marginTop: '75px',
    width: '100%',
    paddingBottom: '32px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  form: {
    width: '100%',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    maxWidth: '750px',
  },
  actionBtn: {
    '&:hover': {
      borderColor: '#003C72',
      color: '#003C72',
    },
    'fontSize': '16px',
    'textTransform': 'none',
    'letterSpacing': '2.25px',
    'maxWidth': 'fit-content',
    'width': '100%',
    'justifySelf': 'center',
    'maxHeight': 48,
    'paddingTop': 10,
    'paddingBottom': 10,
    'marginLeft': 24,
    '@media (max-width: 300px)': {
      minWidth: 'unset',
    },
  },
  pageLink: {
    textAlign: 'center',
    marginTop: '48px',
    cursor: 'pointer',
    fontWeight: 600,
  },
}))
