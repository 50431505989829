import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { NOTIFICATION_TYPES, testTypes } from '../../../constants'
import { useDialog } from '../../../features/dialog/application-dialog'
import { WorkflowItem } from '../../../features/flowcontrol/types'
import { useFlowControl } from '../../../features/flowcontrol/use-flow-control'
import { createHealthService } from '../../../features/health-service-lite/model'
import { showNotification } from '../../../features/notifications/model'
import { StoreStepType } from '../../../features/shopify/model/types'
import { I18n } from '../../../features/translation/types'
import { lsClient } from '../../../ls-client'
import { DashboardCard } from '../../network/type'
import { paths } from '../../paths'
import { selectProfileData } from '../../profile/model'
import { setActive } from '../../register-a-test-module/model'
import { RegisterTestStepType } from '../../register-a-test-module/types'
import {
  BackgroundImageCard,
  BenefitCard,
  CarouselCard,
  GradientCard,
  PassportCard,
  SimpleButton,
  StandardLargeCard,
  StandardMediumCard,
  TestHistoryCard,
  TransparentCard,
} from './CardsV2'

export const DashboardCardItem = (props: {
  activeHealthService: boolean
  button: DashboardCard
  i18n: I18n
  xl?: boolean
}) => {
  const { button, i18n, xl = false, activeHealthService } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(selectProfileData)
  const tenantId = localStorage.getItem('tenantId')
  const { actions, state } = useFlowControl()
  const { openDialog, closeDialog } = useDialog()

  if (!button.enabled) return null
  if (activeHealthService && button.buttonAction?.value === 'HealthService')
    return <></>

  const showActiveServiceMessage = () => {
    dispatch(
      showNotification(
        'Please complete your open Health Service before beginning a new one.',
        NOTIFICATION_TYPES.V2_SUCCESS
      )
    )
  }

  const startWorkflows = (workflowIds: string[] | undefined) => {
    if (activeHealthService) {
      showActiveServiceMessage()
      return
    }
    if (!workflowIds || workflowIds.length === 0) {
      sessionStorage.removeItem('workflowListIds')
      history.push(paths.flowList())
      return
    }

    if (workflowIds.length === 1) {
      const id = workflowIds[0]
      const workflow: any = state.flowsList.find(
        (f: WorkflowItem) => f.id === id
      )
      if (workflow) {
        sessionStorage.removeItem('workflowListIds')
        lsClient.setUserLS('flowId', workflow.id)
        lsClient.setUserLS(
          'flowTitle',
          workflow.name.replaceAll('’', "'") || 'unknown service'
        )
        const params = {
          tenantId,
          accountId: user.accountId,
          UserId: user._id,
          Description: 'Starting Health Service',
        }
        const onCompletion = (healthServiceId: string) => {
          lsClient.setUserLS('healthServiceId', healthServiceId)
          actions.startFlow(id)
        }
        dispatch(createHealthService(params, onCompletion))
      }
    } else {
      sessionStorage.setItem('workflowListIds', workflowIds.join(', '))
      history.push(paths.flowList())
    }
  }
  const beginHealthService = (
    type: 'HealthService' | 'Workflow',
    workflowId?: string,
    workflowTitle?: string
  ) => {
    if (activeHealthService) {
      showActiveServiceMessage()
      return
    }
    if (type === 'Workflow') {
      history.push(paths.flowList())
    } else {
      const params = {
        tenantId,
        accountId: user.accountId,
        UserId: user._id,
        Description: 'Starting Health Service',
      }
      const onCompletion = (healthServiceId: string) => {
        lsClient.setUserLS('healthServiceId', healthServiceId)
        history.push(paths.symptomCheckerIntro())
      }
      dispatch(createHealthService(params, onCompletion))
    }
  }

  const openURL = (url: string) => {
    closeDialog()
    window.open(url, '_blank')
  }

  const buttonAction = (
    to: string,
    id?: string,
    type?: number,
    text?: string
  ) => {
    if (!to) return null
    switch (to) {
      case 'TakeaTest':
      case 'GetTested': {
        history.push(paths.app.getTested())
        break
      }
      case 'HealthService': {
        beginHealthService('HealthService')
        break
      }
      case 'RegisterATest': {
        dispatch(setActive(3))
        history.push(paths.registerTest(RegisterTestStepType.SCAN_CODE))
        break
      }
      case 'CarePlans': {
        history.push(paths.treatmentPlanHistory())
        break
      }
      case 'Workflow': {
        if (id) {
          lsClient.setUserLS('flowId', id)
          lsClient.setUserLS('flowTitle', text ?? 'unknown service')
          actions.startFlow(id)
          break
        }
        history.push(paths.flowList())
        // beginHealthService('Workflow')
        break
      }
      case 'PurchaseHistory': {
        history.push(paths.shopifyStore(StoreStepType.ORDER_HISTORY))
        break
      }
      case 'StartVirtualConsult':
      case 'SymptomChecker': {
        history.push(paths.symptomCheckerIntro())
        break
      }
      case 'Resources': {
        if (id) {
          lsClient.navigation.setBackPath('return_to_dashboard')
          history.push(
            paths.resourceDetails(type === 1 ? 'video' : 'article', id)
          )
          break
        }
        history.push(paths.resources())
        break
      }
      case 'RedirectToUrl': {
        if (!id) return
        const content = {
          title: i18n.leaving_the_app,
          body: i18n.leaving_the_app_instruction,
          onCancel: () => closeDialog(),
          cancelLabel: i18n.not_right_now,
          onConfirm: () => openURL(id),
          confirmationLabel: i18n.continue_to_site,
        }
        openDialog(content)
        break
      }
      // case 'ImportHealthRecords': {
      //   history.push(paths.resources())
      //   break
      // }
      case 'OrderTestKit': {
        history.push(paths.shopifyStore(StoreStepType.PRODUCTS))
        break
      }
      default:
        return null
    }
  }

  const linkPath = (to: string | undefined) => {
    if (!to) return ''
    switch (to) {
      case 'TakeaTest':
      case 'GetTested': {
        return paths.app.getTested()
      }
      case 'HealthService': {
        lsClient.setUserLS('isHealthService', 'Y')
        return paths.symptomCheckerIntro()
      }
      case 'StartVirtualConsult':
      case 'SymptomChecker': {
        return paths.symptomCheckerIntro()
      }
      case 'Resources': {
        return paths.resources()
      }
      // case 'ImportHealthRecords': {
      //   return paths.resources()
      // }
      case 'OrderTestKit': {
        return paths.selectTestOrder(testTypes.ORDER)
      }
      default:
        return ''
    }
  }
  switch (button.style) {
    //Traditional Button - Contained (0) / Outline (1)
    case 0:
    case 1: {
      return (
        <SimpleButton
          activeHealthService={activeHealthService}
          button={button}
          xl={xl}
          linkPath={linkPath(button.buttonAction?.value)}
        />
      )
    }
    //Button with gradient background
    case 2: {
      return (
        <GradientCard
          activeHealthService={activeHealthService}
          button={button}
          xl={xl}
          buttonAction={buttonAction}
          startWorkflows={startWorkflows}
        />
      )
    }
    //Button with background image
    case 3: {
      return (
        <BackgroundImageCard
          button={button}
          buttonAction={buttonAction}
          startWorkflows={startWorkflows}
        />
      )
    }
    //Large Button with image
    case 4: {
      return (
        <StandardLargeCard
          activeHealthService={activeHealthService}
          button={button}
          xl={xl}
          linkPath={linkPath(button.buttonAction?.value)}
          buttonAction={buttonAction}
          startWorkflows={startWorkflows}
        />
      )
    }
    //Medium Button with image
    case 5: {
      return (
        <StandardMediumCard
          button={button}
          buttonAction={buttonAction}
          startWorkflows={startWorkflows}
        />
      )
    }
    //Standard Carousel Card
    case 6: {
      return (
        <CarouselCard
          button={button}
          xl={false}
          buttonAction={buttonAction}
          startWorkflows={startWorkflows}
        />
      )
    }
    //Large Carousel Card
    case 7: {
      return (
        <CarouselCard
          button={button}
          xl={true}
          buttonAction={buttonAction}
          startWorkflows={startWorkflows}
        />
      )
    }
    //Transparent Card
    case 8: {
      return (
        <TransparentCard
          activeHealthService={activeHealthService}
          button={button}
          xl={xl}
          linkPath={linkPath(button.buttonAction?.value)}
        />
      )
    }
    //Benefit Card
    case 9: {
      return (
        <BenefitCard
          button={button}
          buttonAction={buttonAction}
          startWorkflows={startWorkflows}
        />
      )
    }
    //Passport Card
    case 10: {
      return (
        <PassportCard
          button={button}
          linkPath={linkPath(button.buttonAction?.value)}
        />
      )
    }
    //Test History Card
    case 11: {
      return <TestHistoryCard />
    }
    default: {
      return <></>
    }
  }
}
