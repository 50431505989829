import { format, isValid } from 'date-fns'
import moment from 'moment'
import qs from 'querystring'
import { emailRegex, NOTIFICATION_TYPES, weekDays } from '../constants'
import { showNotification } from '../features/notifications/model'
import { I18n } from '../features/translation/types'
import { lsClient } from '../ls-client'
import { NetworkSettings } from '../pages/network/type'
import { paths } from '../pages/paths'
import { ProfileData } from '../pages/profile/type'
import { AvailablePickUp } from '../pages/shipping/types'
import { AlphaChannel } from '../types/general'
import { Address } from '../types/user'

const zlib = require('react-zlib-js')

export function isLoggedIn() {
  const { hash } = window.location
  if (hash) {
    const params = qs.parse(hash.replace('#', ''))
    return Boolean(params.access_token && params.id_token)
  }
  const accessToken = sessionStorage.getItem('access_token')
  const idToken = sessionStorage.getItem('id_token')
  return Boolean(accessToken && idToken)
}

export function logout() {
  // localStorage.clear()
  sessionStorage.removeItem('id_token')
  sessionStorage.removeItem('access_token')
  // window.location.href = paths.inviteMessaging('error')
  window.location.href = paths.login()
}

export const clearTestKitFlow = () => {
  lsClient.removeUserKeyLS('code')
  lsClient.removeUserKeyLS('scan_timer_start')
  lsClient.removeUserKeyLS('wait_timer_start')
  lsClient.removeUserKeyLS('scan_capture_time')
  lsClient.removeUserKeyLS('step')
  lsClient.removeUserKeyLS('testId')
  lsClient.removeUserKeyLS('testType')
  lsClient.removeUserKeyLS('kitId')
  lsClient.removeUserKeyLS('skuId')
  lsClient.removeUserKeyLS('orderId')
  lsClient.removeUserKeyLS('proctor')
}

export function createProviderName(provider: any) {
  if (!provider) return ''
  const { firstName } = provider
  const { lastName } = provider
  const { primaryCredentialsText } = provider
  if (!(firstName && lastName)) return ''
  const name = [firstName, lastName].join(' ')
  if (!primaryCredentialsText) return name
  return [name, primaryCredentialsText].join(', ')
}

export function concatAddress(addressObject: any) {
  const { line1, line2, city, state, zip, country } = addressObject

  return [line1, line2, city, state, zip, country].filter(Boolean).join(', ')
}

export const isBase64 = (string: string) => {
  return /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/gi.test(
    string
  )
}

export const isArrayBuffer = (value: any) => {
  return (
    value &&
    value.buffer instanceof ArrayBuffer &&
    value.byteLength !== undefined
  )
}

export const isFacilityOpenNow = (workingHour: []) => {
  let currentDayEndTime = ''
  const isWorkingNow = workingHour.some((date: any) => {
    if (date?.from && date?.to) {
      const startTime = moment(date?.from, 'H:mm A').format()
      const endTime = moment(date?.to, 'H:mm A').format()
      const isWorkingHours = moment().isBetween(
        startTime,
        endTime,
        'hours',
        '[]'
      )

      if (isWorkingHours) {
        const split = date?.weekDays.split('-')
        const startDay = split[0]?.trim() || ''
        const endDay = split[1]?.trim() || split[0]?.trim() || ''

        const currentDayN = weekDays.indexOf(moment().format('dddd'))
        const startDayN = weekDays.indexOf(startDay)
        const endDayN = weekDays.indexOf(endDay)

        if (currentDayN >= startDayN && currentDayN <= endDayN) {
          currentDayEndTime = date.to
          return true
        }
      }
    }
    return false
  })
  return isWorkingNow && currentDayEndTime
}

export const isFedExOpenNow = (hours: []) => {
  let isWorkingHours = false

  const isWorkingDay = hours.some((date: any) => {
    if (date && date?.hours) {
      const isWorkingDay =
        moment().format('ddd').toLowerCase() === date.dayOfWeek.toLowerCase()

      if (isWorkingDay) {
        date.hours.forEach((time: any) => {
          const workingNow = moment().isBetween(
            time?.begins,
            time?.ends,
            'hours',
            '[]'
          )

          if (workingNow) {
            isWorkingHours = true
          }
        })
        return true
      }
      return false
    }

    return false
  })

  return isWorkingDay && isWorkingHours
}

export const getLastDropOffTime = (dates: []) => {
  let res = '-'

  if (dates && dates.length > 0) {
    dates.forEach((date: any) => {
      const currentDay =
        moment().format('ddd').toLowerCase() === date.dayOfWeek.toLowerCase()
      if (currentDay && date?.hours) {
        const lastEndDate = date.hours[date.hours.length - 1].ends
        res = moment(lastEndDate).format('LT')
      }
    })
  }

  return res
}

export const formatCardNumber = (value: string) => {
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
  const onlyNumbers = value.replace(/[^\d]/g, '')

  return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter((group) => Boolean(group)).join(' ')
  )
}

export const ccExpiresFormat = (value: string) => {
  return value
    .replace(
      /[^0-9]/g,
      '' // To allow only numbers
    )
    .replace(
      /^([2-9])$/g,
      '0$1' // To handle 3 > 03
    )
    .replace(
      /^(1{1})([3-9]{1})$/g,
      '0$1/$2' // 13 > 01/3
    )
    .replace(
      /^0{1,}/g,
      '0' // To handle 00 > 0
    )
    .replace(
      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
      '$1/$2' // To handle 113 > 11/3
    )
}

export function formatDateAndTime(UTCTime: string) {
  if (!UTCTime) return ''
  const date = format(new Date(UTCTime), 'MMM. d, y')
  const time = format(new Date(UTCTime), 'h:mm a')

  return `${date} @ ${time}`
}

export function formatDate(
  UTCTime: string,
  withTime?: boolean,
  withTimeDivider?: string
) {
  if (!UTCTime) return ''
  const date = moment(UTCTime.split('Z')[0]).format('MM/DD/YYYY')
  const time = moment(UTCTime).format('h:mm a')

  if (withTime) {
    return `${date} ${withTimeDivider ? withTimeDivider : ''}${time}`
  }
  return `${date}`
}

export const handleError =
  (error?: any, customMessage?: any) => (dispatch: any) => {
    const defaultMessage = 'Oops! Something went wrong. Please try again.'

    if (error && !customMessage) {
      console.log('ERROR', error)
      if (
        error.response?.data &&
        (error.response.data.message ||
          error.response.data.errorMessage ||
          error.response.data.title ||
          error.response.data.Message)
      ) {
        dispatch(
          showNotification(
            error.response.data.message ||
              error.response.data.errorMessage ||
              error.response.data.title ||
              error.response.data.Message ||
              defaultMessage,
            NOTIFICATION_TYPES.ERROR
          )
        )
      } else if (error?.data?.errorMessage) {
        dispatch(
          showNotification(
            error.data.errorMessage || defaultMessage,
            NOTIFICATION_TYPES.ERROR
          )
        )
      } else if (error?.message) {
        dispatch(
          showNotification(
            error.message || defaultMessage,
            NOTIFICATION_TYPES.ERROR
          )
        )
      } else {
        dispatch(showNotification(defaultMessage, NOTIFICATION_TYPES.ERROR))
      }
    } else {
      console.log('ERROR')
      dispatch(
        showNotification(
          customMessage?.message ||
            customMessage.errorMessage ||
            customMessage ||
            defaultMessage,
          NOTIFICATION_TYPES.ERROR
        )
      )
    }
  }

export enum AvailableDaysMapping {
  TODAY = 'Today',
  TOMORROW = 'Tomorrow',
  THE_DAY_AFTER_TOMORROW = 'The day after tomorrow',
}

export const checkDateAvailability = (
  pickupAvailability: AvailablePickUp,
  compareDay: string
) => {
  const { pickupDate, available, scheduleDay } = pickupAvailability
  let isAvailableDate = null
  let matchScheduleDay = false
  const compareDates = (date: Date) =>
    new Date(pickupDate).setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0)

  if (compareDay === AvailableDaysMapping.TODAY) {
    isAvailableDate = compareDates(new Date())
    matchScheduleDay = scheduleDay === 'SAME_DAY'
  } else if (compareDay === AvailableDaysMapping.TOMORROW) {
    const tomorrowDate = new Date()
    tomorrowDate.setDate(new Date().getDate() + 1)
    isAvailableDate = compareDates(tomorrowDate)
    matchScheduleDay = scheduleDay === 'FUTURE_DAY'
  } else if (compareDay === AvailableDaysMapping.THE_DAY_AFTER_TOMORROW) {
    const theDayAfterTomorrowDate = new Date()
    theDayAfterTomorrowDate.setDate(new Date().getDate() + 2)
    isAvailableDate = compareDates(theDayAfterTomorrowDate)
    matchScheduleDay = scheduleDay === 'FUTURE_DAY'
  }

  return isAvailableDate && matchScheduleDay && available
}

export const getAvailableDaysForPickUp = (
  availablePickups: AvailablePickUp[]
) =>
  availablePickups
    .map((pickupAvailability, index) => {
      if (pickupAvailability.countryRelationship !== 'DOMESTIC') return null

      if (
        checkDateAvailability(pickupAvailability, AvailableDaysMapping.TODAY)
      ) {
        return { label: AvailableDaysMapping.TODAY, value: index }
      }
      if (
        checkDateAvailability(pickupAvailability, AvailableDaysMapping.TOMORROW)
      ) {
        return { label: AvailableDaysMapping.TOMORROW, value: index }
      }
      if (
        checkDateAvailability(
          pickupAvailability,
          AvailableDaysMapping.THE_DAY_AFTER_TOMORROW
        )
      ) {
        return {
          label: AvailableDaysMapping.THE_DAY_AFTER_TOMORROW,
          value: index,
        }
      }
      return null
    })
    .filter((item) => item !== null)

export const validateReadyTime = (
  value: string,
  startTime: string,
  endTime: string
) => {
  const formattedValue = moment(moment(value).format('HH:mm'), 'HH:mm')
  const start = moment(startTime, 'HH:mm')
  const end = moment(endTime, 'HH:mm')

  return formattedValue.isBetween(start, end, 'minutes', '[]')
}

export const validateLatestTime = (
  value: string,
  startTime: string,
  endTime: string,
  readyValue: any
) => {
  const formattedValue = moment(moment(value).format('HH:mm'), 'HH:mm')
  const formattedReadyValue = moment(
    moment(readyValue).format('HH:mm'),
    'HH:mm'
  )

  return (
    validateReadyTime(value, startTime, endTime) &&
    formattedValue.isSameOrAfter(formattedReadyValue, 'minutes')
  )
}

export const translateOptions = (
  options: { label: string; value: any }[],
  i18n: I18n
) => {
  return options.map(({ label, value }) => ({ label: i18n[label], value }))
}

export const validateDate = (date: Date) => (isValid(date) ? date : new Date())

export const checkRequiredData = (
  profileData: ProfileData,
  networkSettings: NetworkSettings
): boolean => {
  const { birthDate, gender, race, ethnicity } = profileData

  const {
    collectDateOfBirthRequired,
    collectSexRequired,
    collectAddressRequired,
    raceRequired,
    ehtnicityRequired,
  } = networkSettings

  return (
    (collectDateOfBirthRequired && !birthDate) ||
    (collectSexRequired && !gender) ||
    (raceRequired && !race) ||
    (ehtnicityRequired && !ethnicity)
  )
}

export const formatTimeUnit = (timeUnit: number) =>
  timeUnit < 10 ? `0${timeUnit}` : timeUnit

export const hexToRGBA = (hex: string, alpha: AlphaChannel) => {
  // if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex))
  const r = Number.parseInt(hex.slice(1, 3), 16),
    g = Number.parseInt(hex.slice(3, 5), 16),
    b = Number.parseInt(hex.slice(5, 7), 16)

  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
}

export const lightenDarkenHexColor = (color: string, percent: number) => {
  let R = Number.parseInt(color.slice(1, 3), 16)
  let G = Number.parseInt(color.slice(3, 5), 16)
  let B = Number.parseInt(color.slice(5, 7), 16)

  R = (R * (100 + percent)) / 100
  G = (G * (100 + percent)) / 100
  B = (B * (100 + percent)) / 100

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  R = Math.round(R)
  G = Math.round(G)
  B = Math.round(B)

  let RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16)
  let GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16)
  let BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16)

  return '#' + RR + GG + BB
}

export const isValidPasswordLength = (password: string) => password.length >= 8
export const isValidPasswordLower = (password: string) =>
  /([a-z])/.test(password)
export const isValidPasswordUpper = (password: string) =>
  /([A-Z])/.test(password)
export const isValidPasswordDigit = (password: string) => /(\d)/.test(password)
export const isValidPasswordSpecial = (password: string) =>
  /([^A-Za-z0-9])/.test(password)
export const noInvalidCharacter = (password: string) =>
  /^[\w!@#$%^&\-*]*$/.test(password)

export const isValidCodeLength = (verificationCode: string) =>
  verificationCode.length !== 6

export const isValidEmailAddress = (email: string) => emailRegex.test(email)

export const decompressGzipString = (str: string) => {
  const buf = Buffer.from(str, 'base64')
  const decompressedBuf = zlib.unzipSync(buf)
  return JSON.parse(decompressedBuf.toString('utf8'))
}

export const displayEndCallInfo = (message?: string) => (dispatch: any) => {
  if (message) {
    // dispatch(resetCalls())
    dispatch(showNotification(message, 'info'))
  }

  return true
}

export const isAddressExists = (
  address: Address | undefined | null
): boolean => {
  if (!address) return false
  return Boolean(
    address.line1 &&
      // address.line2 &&
      address.zip &&
      address.city &&
      address.state
    // address.country
  )
}

export const isAppRunningInstalled = () => {
  return (
    typeof window !== 'undefined' &&
    window.matchMedia('(display-mode: standalone)').matches
  )
}
