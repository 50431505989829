import ListItemIcon from '@material-ui/core/ListItemIcon'
import { makeStyles } from '@material-ui/core/styles'
import EmailIcon from '@material-ui/icons/Email'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import HelpRoundedIcon from '@material-ui/icons/HelpRounded'
import HomeIcon from '@material-ui/icons/Home'
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined'
import SettingsIcon from '@material-ui/icons/Settings'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded'
import { useHistory } from 'react-router-dom'
import { ReactComponent as RateReview } from '../../../assets/icons/rate-review.svg'
import { ReactComponent as Vaccinations } from '../../../assets/icons/vaccinations-icon.svg'
import { ReactComponent as VideoLibrary } from '../../../assets/icons/video-library.svg'
import { paths } from '../../../pages/paths'
import { Typography } from '../../../ui'
import { StoreStepType } from '../../shopify/model/types'
import { I18n } from '../../translation/types'

const NavItem = (props: {
  icon: any
  label: string
  path?: string
  footer: boolean
  hide?: boolean
  light?: boolean
}) => {
  const { icon, label, path, footer, hide = false, light = false } = props
  const history = useHistory()
  const classes = useStyles()
  if (hide) return null
  const handleClick = () => {
    if (path) history.push(path)
  }
  return (
    <div
      onClick={() => handleClick()}
      className={classes.footerNavButtonWrapper}
    >
      {footer && <div className={classes.icon}>{icon}</div>}
      <Typography className={light ? `${classes.label} light` : classes.label}>
        {label}
      </Typography>
    </div>
  )
}

export const NavigationItems = (
  i18n: I18n,
  isDesktop: boolean,
  light?: boolean,
  hasDependents?: boolean
) => ({
  HOME: () => (
    <NavItem
      key={'HOME'}
      path={paths.app.dashboard()}
      icon={<HomeIcon />}
      label={i18n.footer_home}
      footer={!isDesktop}
      light={light}
    />
  ),
  CARE_PLANS: () => (
    <NavItem
      key={'CARE_PLANS'}
      path={paths.treatmentPlanHistory()}
      icon={<FolderSharedIcon />}
      label={i18n.footer_care_plans}
      footer={!isDesktop}
      light={light}
    />
  ),
  DEPENDENTS: () => (
    <NavItem
      key={'DEPENDENTS'}
      path={paths.dependents()}
      icon={<HomeIcon />}
      label={i18n.footer_dependents}
      footer={!isDesktop}
      hide={!hasDependents}
      light={light}
    />
  ),
  ORDER: () => (
    <NavItem
      key={'ORDER'}
      path={paths.shopifyStore(StoreStepType.PRODUCTS)}
      icon={<ShoppingBasketIcon />}
      label={i18n.shop}
      footer={!isDesktop}
      light={light}
    />
  ),
  CONTACT: () => (
    <NavItem
      key={'CONTACT'}
      path={paths.contactSupport()}
      icon={<RateReview />}
      label={i18n.footer_contact}
      footer={!isDesktop}
      light={light}
    />
  ),
  HELP_CENTER: () => (
    <NavItem
      key={'HELP_CENTER'}
      path={paths.faqs()}
      icon={<HelpRoundedIcon />}
      label={i18n.footer_help_center}
      footer={!isDesktop}
      light={light}
    />
  ),
  VACCINATIONS: () => (
    null
    // <NavItem
    //   key={'VACCINATIONS'}
    //   path={paths.app.dashboard()}
    //   icon={<Vaccinations />}
    //   label={i18n.footer_vaccinations}
    //   footer={!isDesktop}
    //   light={light}
    // />
  ),
  TEST_HISTORY: () => (
    <NavItem
      key={'TEST_HISTORY'}
      path={paths.testResults()}
      icon={<HomeIcon />}
      label={i18n.footer_test_history}
      footer={!isDesktop}
      light={light}
    />
  ),
  ORDER_HISTORY: () => (
    <NavItem
      key={'ORDER_HISTORY'}
      path={paths.shopifyStore(StoreStepType.ORDER_HISTORY)}
      icon={<ShoppingCartRoundedIcon />}
      label={i18n.footer_purchases}
      footer={!isDesktop}
      light={light}
    />
  ),
  RESOURCES: () => (
    <NavItem
      key={'RESOURCES'}
      icon={<VideoLibrary />}
      path={paths.resources()}
      label={i18n.footer_resources}
      footer={true}
      light={light}
    />
  ),
  SETTINGS: () => (
    <NavItem
      key={'SETTINGS'}
      path={paths.settingsTab('account', 'personal-info')}
      icon={<SettingsIcon />}
      label={i18n.footer_settings}
      footer={!isDesktop}
      light={light}
    />
  ),
})

export enum FooterConfigurationOptions {
  HOME = 'HOME',
  CARE_PLANS = 'CARE_PLANS',
  DEPENDENTS = 'DEPENDENTS',
  ORDER = 'ORDER',
  CONTACT = 'CONTACT',
  HELP_CENTER = 'HELP_CENTER',
  VACCINATIONS = 'VACCINATIONS',
  TEST_HISTORY = 'TEST_HISTORY',
  ORDER_HISTORY = 'ORDER_HISTORY',
  RESOURCES = 'RESOURCES',
  SETTINGS = 'SETTINGS',
}
const useStyles = makeStyles((theme) => ({
  label: {
    'fontSize': 12,
    'fontWeight': 500,
    'whiteSpace': 'nowrap',
    [theme.breakpoints.up(960)]: {
      fontSize: 16,
    },
    '&.light': {
      color: '#FFF',
    },
  },
  icon: {
    color: '#7E7E7E',
  },
  footerNavButtonWrapper: {
    padding: '0px 10px',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
