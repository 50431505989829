import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import Alert from '../../../assets/icons/alert-red.svg'
import TimeExpired from '../../../assets/img/treatment-plan/time_expired.svg'
import { ConsultStatus } from '../../health-service-lite/types'
import { I18n } from '../../translation/types'
import { Graphic, OrderStatusStep, Variant } from '../types'
import { Images } from './statusHelper'

export const statusCards = (
  i18n: I18n,
  classes: ClassNameMap,
  actions: any,
  loadingTest: boolean
) =>
  [
    {
      view: ConsultStatus.safe_created,
      type: 'SIMPLE',
      header: i18n.unassigned_video_status_card_title,
      body: i18n.unassigned_video_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).UpcomingImage,
      mobileButtonFlex: 'column',
      actions: [
        {
          title: i18n.telehealth_cancel_consult,
          function: () => actions.onCancel(),
          variant: 'text' as Variant,
          smallButton: false,
          disabled: false,
        },
        {
          title: i18n.telehealth_return_to_waiting_room_button,
          function: () => actions.returnToWaitingRoom(),
          variant: 'contained' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.safe_dequeued,
      type: 'SIMPLE',
      header: i18n.in_review_status_card_title,
      body: '',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).UpcomingImage,
      actions: [
        {
          title: i18n.telehealth_return_to_waiting_room_button,
          function: () => actions.returnToWaitingRoom(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.steadyMD_in_review,
      type: 'SIMPLE',
      header: i18n.in_review_status_card_title,
      body: i18n.in_review_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).InReviewImage,
      actions: [],
    },
    {
      view: ConsultStatus.IN_CALL,
      type: 'SIMPLE',
      header: i18n.in_call_status_card_title,
      body: i18n.in_call_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).PreparingImage,
      actions: [],
    },
    {
      view: ConsultStatus.CLOSED,
      type: 'SIMPLE',
      header: i18n.in_call_status_card_title,
      body: i18n.in_call_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).ConnectingImage,
      actions: [],
    },
    {
      view: ConsultStatus.CANCELLED,
      type: 'SIMPLE',
      header: i18n.telehealth_session_cancelled_card_title,
      body: i18n.telehealth_session_cancelled_card_message,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).CancelledImage,
      actions: [],
    },
    {
      view: ConsultStatus.MISSED_CALL,
      type: 'SIMPLE',
      header: i18n.missed_call_status_card_title,
      body: i18n.missed_call_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).ConsultMissedImage,
      actions: [
        {
          title: i18n.request_new_telehealth_visit,
          function: () => actions.requestNewVisit(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.safe_treatment_ready,
      type: 'SIMPLE',
      header: i18n.treatment_ready_status_card_title,
      body: i18n.treatment_ready_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).PlanReadyImage,
      sanityImage: 'ReviewSummaryIcon',
      actions: [
        {
          title: i18n.treatment_ready_status_card_action,
          function: () => actions.gotoTreatmentPlan(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
        {
          title: 'Start New Health Service',
          function: () => actions.onFinish(),
          variant: 'contained' as Variant,
          smallButton: true,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.NOT_ASSIGNED,
      type: 'SIMPLE',
      header: i18n.unassigned_phone_status_card_title,
      body: i18n.unassigned_phone_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).UpcomingImage,
      actions: [
        {
          title: i18n.telehealth_cancel_consult,
          function: () => actions.onCancel(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.consult_submitted,
      type: 'SIMPLE',
      header: i18n.unassigned_phone_status_card_title,
      body: i18n.unassigned_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).ConsultationImage,
      actions: [
        {
          title: i18n.telehealth_cancel_consult,
          function: () => actions.onCancel(),
          variant: 'text' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.consult_scheduled,
      type: 'SIMPLE',
      header: 'Telehealth Consult Scheduled',
      body: 'You can cancel your appointment using the link below.',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).FVConsultScheduledImage,
      actions: [
        {
          title: i18n.telehealth_cancel_consult,
          function: () => actions.onCancel(),
          variant: 'text' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.IN_PROGRESS,
      type: 'SIMPLE',
      // headerIcon: Alert,
      header: i18n.default_status_card_title,
      body: i18n.default_status_card_text,
      graphic: 'TIMER' as Graphic,
      bgColor: '#22A2FF',
      image: () => null,
      mobileButtonFlex: 'row',
      actions: [
        {
          title: i18n.cancel_button,
          function: () => actions.onCancel(),
          variant: 'outlined' as Variant,
          smallButton: true,
          disabled: false,
        },
        {
          title: i18n.continue_button,
          function: () => actions.continueService(),
          variant: 'contained' as Variant,
          smallButton: true,
          disabled: loadingTest,
        },
      ],
    },
    {
      view: ConsultStatus.created,
      type: 'SIMPLE',
      header: 'Connecting with your care team',
      body: 'Hang tight! The care team is reviewing your information and will call you soon.',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).ConnectingImage,
      actions: [],
    },
    {
      view: ConsultStatus.fv_call_missed,
      type: 'SIMPLE',
      header: 'Oops! Looks like you missed your provider’s phone calls',
      body: 'A care plan cannot be provided without consulting with a provider. If you still wish to receive a telehealth consult, please press below to continue your request',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).FVCallMissedImage,
      actions: [
        {
          title: i18n.continue_telehealth_request,
          function: () => actions.continueService(),
          variant: 'contained' as Variant,
          smallButton: true,
          disabled: loadingTest,
        },
      ],
    },
    {
      view: ConsultStatus.call_missed,
      type: 'SIMPLE',
      header: 'Oops! Looks like you missed your provider’s phone calls',
      body: 'A treatment plan cannot be provided without consulting with a provider. If you still feel unwell, you can start a new consultation.',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).CallMissedImage,
      actions: [],
    },
    {
      view: ConsultStatus.no_treatment_required,
      type: 'SIMPLE',
      header: 'Your provider has determined that you don’t require treatment',
      body: 'If your symptoms worsen, let us know',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).NoTreatmentImage,
      actions: [],
    },
    {
      view: ConsultStatus.cancelled,
      type: 'SIMPLE',
      header: 'Your prescription was cancelled',
      body: 'This medication is unavailable in your area. Please contact your Primary Care Provider.',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).CancelledImage,
      actions: [],
    },
    {
      view: ConsultStatus.treatment_rx_prescribed,
      type: 'SIMPLE',
      header: 'Your care plan is ready to review',
      body: "Click to see your provider's recommendations.",
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).FVCarePlanImage,
      actions: [
        {
          title: 'Review Care Plan',
          function: () => actions.reviewCarePlan(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.alt_pharm_loc,
      type: 'SIMPLE',
      header: 'Medication was sent to a different pharmacy',
      body: 'View new pharmacy information in your care plan.',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).AltPharmLocImage,
      actions: [
        {
          title: 'Review Care Plan',
          function: () => actions.reviewCarePlan(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.fv_completed,
      type: 'SIMPLE',
      header: 'Your prescription details will be avavilable soon...',
      body: 'Your prescription is being sent to a local pharmacy. Wait times may vary. Check back here for updates.',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).FVAvailableSoonImage,
      actions: [],
    },
    {
      view: ConsultStatus.for_delivery,
      type: 'PROGRESS',
      header: 'A delivery request has been created!',
      body: 'You will be notified when your delivery is on its way.',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).DeliveryImage,
      actions: [
        {
          title: i18n.review_care_plan,
          function: () => actions.gotoTreatmentPlan(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
        {
          title: 'Start New Health Service',
          function: () => actions.onFinish(),
          variant: 'contained' as Variant,
          smallButton: true,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.delivery_failed,
      type: 'SIMPLE',
      header: 'Your prescription cannot currently be filled',
      body: 'We experienced a problem when trying to fill or deliver your prescription. Please contact your pharmacy for further information.',
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).DeliveryImage,
      actions: [],
      sanityImage: 'care_status_canceled',
    },
    {
      view: ConsultStatus.healthservice_expired,
      type: 'SIMPLE',
      headerIcon: TimeExpired,
      header: i18n.expired_status_card_title,
      body: i18n.expired_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).ExpiredImage,
      actions: [],
    },
    {
      view: ConsultStatus.Easeable_not_assigned,
      type: 'SIMPLE',
      header: i18n.easable_telehealth_consult_scheduled_title,
      body: i18n.easable_telehealth_consult_scheduled_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).EasableVideoScheduledImage,
      actions: [
        {
          title: i18n.cancel_appointment,
          function: () => actions.onCancel(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.Easeable_in_review,
      type: 'SIMPLE',
      header: i18n.in_review_status_card_title,
      body: i18n.in_review_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).EasableHourGlassImage,
      actions: [],
    },
    {
      view: ConsultStatus.Easeable_in_call,
      type: 'SIMPLE',
      header: i18n.prescription_soon_title,
      body: i18n.easable_local_pharmacy,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).EasableHourGlassImage,
      actions: [],
    },
    {
      view: ConsultStatus.Easable_treatment_ready,
      type: 'SIMPLE',
      header: i18n.treatment_plan_in_review_title,
      body: i18n.prescription_not_needed_message,
      graphic: 'IMAGE' as Graphic,
      image: Images(classes).EasableTreatmentReadyImage,
      actions: [
        {
          title: i18n.review_care_plan,
          function: () => actions.gotoTreatmentPlan(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
        {
          title: 'Start New Health Service',
          function: () => actions.onFinish(),
          variant: 'contained' as Variant,
          smallButton: true,
          disabled: false,
        },
      ],
    },
  ] as OrderStatusStep[]
