import './index.css'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Application } from './application'
import { FlowProvider } from './features/flowcontrol/use-flow-control'
import { history } from './libs/history'
import { persistor, store } from './redux'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'

const flowEngineAPI = process.env.REACT_APP_FLOWENGINE_API_URL || ''
const flowBuilderAPI = process.env.REACT_APP_FLOWBUILDER_API_URL || ''

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <FlowProvider apiUrl={flowEngineAPI} apiUrlBuilder={flowBuilderAPI}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Application />
              </MuiPickersUtilsProvider>
            </FlowProvider>
          </Router>
        </PersistGate>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>,
  document.querySelector('#root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
// serviceWorker.register()
