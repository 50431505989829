import styled from '@emotion/styled'
import {
  AppBar,
  IconButton,
  Theme,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import React from 'react'

interface HeaderProps {
  onMenuClick: () => void
  middleContent: React.ReactNode
  rightContent: React.ReactNode
  className?: string
}
export const Header = ({
  onMenuClick,
  middleContent,
  rightContent,
  className,
}: HeaderProps) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))

  return (
    <>
      <AppBar className={className ? className : classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {/* {!isMobile && (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={onMenuClick}
              >
                <MenuIcon />
              </IconButton>
            </>
          )} */}
          <MiddleContentWrapper>{middleContent}</MiddleContentWrapper>
          <RightContentWrapper>{rightContent}</RightContentWrapper>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: theme.palette.primary.main,
    background: 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(40px)',
    paddingLeft: 275,
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,60,113,0.05)',
    [theme.breakpoints.down(1200)]: {
      paddingLeft: 0,
    },
    // [theme.breakpoints.down(600)]: {
    //   background: 'rgba(243, 243, 243, 0.6)',
    // },
  },
  toolbar: {
    padding: '0 35px',
    [theme.breakpoints.down(600)]: {
      padding: '0 10px 0 18px',
    },
    height: 64,
  },
}))

const MiddleContentWrapper = styled.div`
  flex-grow: 1;
`
const RightContentWrapper = styled.div`
  display: flex;
  align-items: center;
`
