import { AccountSettings, AccountSettingsType } from '../../../type'
import { Address } from './address'
import { PaymentMethods } from './credit-cards'
import { EmergencyContact } from './emergency-contact'
import { MyInsurance } from './Insurance/index'
import { MobileNumberView } from './mobile-number'
import { MyProfilePage } from './my-profile'

export const accountSettingsMenuItems: AccountSettings = {
  [AccountSettingsType.PERSONAL_INFORMATION]: {
    label_key: 'personal_information_profile_section',
    content: MyProfilePage,
  },
  [AccountSettingsType.MOBILE_NUMBER]: {
    label_key: 'mobile_number_tab',
    content: MobileNumberView,
  },
  [AccountSettingsType.PAYMENT_METHODS]: {
    label_key: 'payment_method_settings',
    content: PaymentMethods,
  },
  // [AccountSettingsType.MY_INSURANCE]: {
  //   label_key: 'insurance_settings',
  //   content: MyInsurance,
  // },
  [AccountSettingsType.MY_ADDRESSES]: {
    label_key: 'my_address',
    content: Address,
  },
  [AccountSettingsType.EMERGENCY_CONTACT]: {
    label_key: 'emergency_contact_settings',
    content: EmergencyContact,
  },
}
