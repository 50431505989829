/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { Dispatch } from 'redux'
import api from '../../../api'
import { NOTIFICATION_TYPES } from '../../../constants'
import { handleError, isLoggedIn } from '../../../libs/helpers'
import { history } from '../../../libs/history'
import { sendAuditLog } from '../../../libs/utils'
import { lsClient } from '../../../ls-client'
import { MFASettings } from '../../../pages/network/type'
import { paths } from '../../../pages/paths'
import {
  SecuritySettingsType,
  SettingsTabType,
} from '../../../pages/settings/type'
import {
  createDevicePassword,
  createWebDeviceId,
} from '../../cookies/cookie-helper'
import { hideNotification, showNotification } from '../../notifications/model'
import { I18n } from '../../translation/types'
import {
  ChallengeType,
  DeviceMetadata,
  MFAOptions,
  MFASetupData,
} from '../types/mfa'

interface UserTenantSlice {
  isLoading: boolean
  permission: string
  latestResult: any
  isLoggedIn: boolean
  loginSessionId: string
  mfaType: ChallengeType | undefined
  error: string
  warningMessage: string
  totpSetupCode: string
  mfaSetupData: MFASetupData | undefined
  deviceMetadata: DeviceMetadata | undefined
  loginMFASettings: MFASettings | undefined
  buffer: {
    accessToken: string
    idToken: string
  }
}
const initialStore: UserTenantSlice = {
  isLoading: false,
  permission: '',
  latestResult: {},
  isLoggedIn: isLoggedIn(),
  loginSessionId: '',
  mfaType: undefined,
  error: '',
  warningMessage: '',
  totpSetupCode: '{}',
  mfaSetupData: undefined,
  deviceMetadata: undefined,
  loginMFASettings: undefined,
  buffer: {
    accessToken: '',
    idToken: '',
  },
}

const getMFAErrorCode = (code: number) => {
  switch (code) {
    case 1:
      return 'mfa_error_invalid_code_message'

    case 2:
      return 'mfa_error_too_many_attempts_message'

    case 3:
      return 'mfa_error_code_expired_message'

    case 0:
    default:
      return ''
  }
}

export const userTenantSlice = createSlice({
  name: 'userTenant',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setLoggedIn(store, { payload }: { payload: boolean }) {
      store.isLoggedIn = payload
    },
    setUserPermission(store, { payload }: { payload: any }) {
      store.permission = payload
    },
    setLatestResult(store, { payload }: { payload: any }) {
      store.latestResult = payload
    },
    setLoginSessionId(store, { payload }: { payload: string }) {
      store.loginSessionId = payload
    },
    setMFASetup(store, { payload }: { payload: MFASetupData }) {
      store.mfaSetupData = payload
    },
    setMFAType(store, { payload }: { payload: ChallengeType }) {
      store.mfaType = payload
    },
    setLoginSettings(store, { payload }: { payload: MFASettings }) {
      store.loginMFASettings = payload
    },
    setTOTPConfig(store, { payload }: { payload: string }) {
      store.totpSetupCode = payload
    },
    setDeviceMeta(store, { payload }: { payload: DeviceMetadata }) {
      store.deviceMetadata = payload
    },
    doLogout() {},
    setError(store, { payload }: { payload: any }) {
      store.error = payload
    },
    setWarning(store, { payload }: { payload: string }) {
      store.warningMessage = payload
    },
    holdTokens(
      store,
      { payload }: { payload: { accessToken: string; idToken: string } }
    ) {
      store.buffer = payload
    },
    clearHold(store) {
      store.buffer = initialStore.buffer
    },
    resetError(store) {
      store.warningMessage = ''
      store.error = ''
    },
    // resetTestStore: () => initialStore,
  },
})
const {
  holdTokens,
  setLoading,
  setUserPermission,
  setLatestResult,
  doLogout,
  setDeviceMeta,
  setError,
  setLoginSessionId,
  setLoginSettings,
  setMFASetup,
  setMFAType,
  setTOTPConfig,
  setWarning,
} = userTenantSlice.actions

export const { resetError, clearHold, setLoggedIn } = userTenantSlice.actions

export const loginUser =
  (payload: { email: string; password: string }) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const { isOK, data, error, code } = await api.login(payload)
    dispatch(setLoading(false))

    if (error) {
      dispatch(handleError({ message: error }))
    }

    if (
      data?.challengeName?.value === ChallengeType.SMS_MFA ||
      data?.challengeName?.value === ChallengeType.SOFTWARE_TOKEN_MFA
    ) {
      // Cognito MFA Challenge
      dispatch(setMFAType(data.challengeName.value))
      dispatch(setLoginSessionId(data.sessionId))
      dispatch(setLoginSettings(data.user))
      localStorage.setItem('email', payload.email)
      history.push(paths.mfAuth())
    } else if (data?.challengeName?.value === ChallengeType.SELECT_MFA_TYPE) {
      dispatch(setMFASetup(data))
      history.push(paths.setupMFA())
    }

    if (isOK && data && data?.idToken) {
      localStorage.setItem('email', payload.email)
      if (data.user?.enableGoogleAuth || data.user?.enableSMSVerification) {
        dispatch(setLoginSettings(data.user))
        dispatch(
          holdTokens({ accessToken: data.accessToken, idToken: data.idToken })
        )
        sendAuditLog('User log in', {
          action_type: 'create',
          date: moment().utc().format('MMM DD, YYYY hh:mm'),
          actor: payload.email,
        })
        history.push(paths.setupMFA())
      } else {
        dispatch(hideNotification())
        sessionStorage.setItem('id_token', data.idToken as string)
        sessionStorage.setItem('access_token', data.accessToken as string)
        dispatch(setLoggedIn(true))
        sendAuditLog('User log in', {
          action_type: 'create',
          date: moment().utc().format('MMM DD, YYYY hh:mm'),
          actor: payload.email,
        })
        history.push(paths.app.dashboard())
        return true
      }
    }

    return false
  }

export const authorizedDeviceLogin =
  (payload: any, challengeType: MFAOptions) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const { isOK, data, error, code } = await api.loginUserWithRememberedDevice(
      payload
    )
    dispatch(setLoading(false))

    if (error) {
      dispatch(handleError({ message: error }))
    }

    if (
      data?.challengeName?.value === ChallengeType.SMS_MFA ||
      data?.challengeName?.value === ChallengeType.SOFTWARE_TOKEN_MFA
    ) {
      // Cognito MFA Challenge
      dispatch(setMFAType(data.challengeName.value))
      dispatch(setLoginSessionId(data.sessionId))
      dispatch(setLoginSettings(data.user))
      localStorage.setItem('email', payload.username)
      history.push(paths.mfAuth())
    } else if (data?.challengeName?.value === ChallengeType.SELECT_MFA_TYPE) {
      dispatch(setMFASetup(data))
      history.push(paths.setupMFA())
    }

    if (isOK && (data?.idToken || data?.authenticationResult?.idToken)) {
      dispatch(hideNotification())
      data.idToken
        ? sessionStorage.setItem('id_token', data.idToken as string)
        : sessionStorage.setItem(
            'id_token',
            data.authenticationResult.idToken as string
          )
      data.idToken
        ? sessionStorage.setItem('access_token', data.accessToken as string)
        : sessionStorage.setItem(
            'access_token',
            data.authenticationResult.accessToken as string
          )
      data.idToken
        ? sessionStorage.setItem('refresh_token', data.refreshToken as string)
        : sessionStorage.setItem(
            'refresh_token',
            data.authenticationResult.refreshToken as string
          )
      dispatch(setLoggedIn(true))
      lsClient.setUserLS('mfa', challengeType)
      sendAuditLog('User MFA log in with remembered device', {
        action_type: 'create',
        date: moment().utc().format('MMM DD, YYYY hh:mm'),
        actor: payload.email,
      })
      const prevPath = localStorage.getItem('path')
      if (prevPath) {
        localStorage.removeItem('path')
        history.push(prevPath)
      } else {
        history.push(paths.app.dashboard())
      }
      return true
    }

    return false
  }

export const submitMFAChallenge =
  (
    params: any,
    challengeType: ChallengeType | undefined,
    i18n: I18n,
    rememberDeviceCallback?: (authResponse: any) => void
  ) =>
  async (dispatch: any) => {
    if (!challengeType) return
    dispatch(setError(''))
    dispatch(setLoading(true))

    const { data, errorMessage, error, isOK } = await api.mfaChallenge(
      params,
      challengeType
    )
    dispatch(setLoading(false))
    if (
      isOK &&
      (data?.idToken || data?.mfaResponse?.authenticationResult?.idToken)
    ) {
      dispatch(hideNotification())
      dispatch(
        setDeviceMeta(
          data.idToken
            ? data.newDeviceMetadata
            : data.mfaResponse.authenticationResult?.newDeviceMetadata
        )
      )
      data.idToken
        ? sessionStorage.setItem('id_token', data.idToken as string)
        : sessionStorage.setItem(
            'id_token',
            data.mfaResponse.authenticationResult.idToken as string
          )
      data.idToken
        ? sessionStorage.setItem('access_token', data.accessToken as string)
        : sessionStorage.setItem(
            'access_token',
            data.mfaResponse.authenticationResult.accessToken as string
          )
      data.idToken
        ? sessionStorage.setItem('refresh_token', data.refreshToken as string)
        : sessionStorage.setItem(
            'refresh_token',
            data.mfaResponse.authenticationResult.refreshToken as string
          )
      if (challengeType === ChallengeType.SMS_MFA) {
        lsClient.setUserLS('mfa', MFAOptions.SMS)
      } else if (challengeType === ChallengeType.SOFTWARE_TOKEN_MFA) {
        lsClient.setUserLS('mfa', MFAOptions.TOTP)
      }
      if (rememberDeviceCallback) {
        const payload = {
          type:
            challengeType === ChallengeType.SMS_MFA
              ? MFAOptions.SMS
              : MFAOptions.TOTP,
          deviceGroupKey: data.idToken
            ? data.newDeviceMetadata?.deviceGroupKey
            : data.mfaResponse?.authenticationResult?.newDeviceMetadata
                ?.deviceGroupKey,
          deviceKey: data.idToken
            ? data.newDeviceMetadata?.deviceKey
            : data.mfaResponse?.authenticationResult?.newDeviceMetadata
                ?.deviceKey,
          username: params.username,
          deviceName: createWebDeviceId(),
          devicePass: createDevicePassword(),
          accessToken: data.idToken
            ? data.accessToken
            : data.mfaResponse?.authenticationResult?.accessToken,
        }
        dispatch(rememberMFADevice(payload, rememberDeviceCallback))
      } else {
        dispatch(setLoggedIn(true))
        sendAuditLog('User log in with Cognito MFA', {
          action_type: 'create',
          date: moment().utc().format('MMM DD, YYYY hh:mm'),
          actor: params.username,
        })
        history.push(paths.app.dashboard())
        return true
      }
    }

    if (error) {
      const messageKey = getMFAErrorCode(error) as keyof I18n
      dispatch(
        setError(
          i18n[messageKey] ||
            data?.errorMessage ||
            errorMessage ||
            error ||
            'A server error occurred. Unable to verify your Multi Factor code at this time'
        )
      )
      dispatch(setLoading(false))
      return false
    }
  }

export const rememberMFADevice =
  (params: any, rememberDeviceCallback: (authResponse: any) => void) =>
  async (dispatch: Dispatch) => {
    dispatch(setError(''))
    dispatch(setLoading(true))

    const { data, errorMessage, error, isOK } = await api.confirmMFADevice(
      params
    )
    dispatch(setLoading(false))

    if (isOK && data?.item2) {
      dispatch(setLoggedIn(true))
      const cookie = {
        type: params.type,
        deviceGroupKey: params.deviceGroupKey,
        deviceName: params.deviceName,
        devicePass: params.devicePass,
        deviceKey: params.deviceKey,
        passwordVerifier: data.item2,
      }
      rememberDeviceCallback(cookie)
    }

    if (error) {
      dispatch(
        setError(
          data?.errorMessage ||
            errorMessage ||
            'A server error occurred. Unable to remember device at this time'
        )
      )
      history.push(paths.app.dashboard())
      return false
    }
  }

export const forgetMFADevice =
  (params: any, forgetDeviceCallback: () => void, i18n: I18n) =>
  async (dispatch: any) => {
    dispatch(setError(''))
    dispatch(setLoading(true))

    const { data, errorMessage, error, isOK } = await api.forgetMFADevice(
      params
    )
    dispatch(setLoading(false))
    if (isOK) {
      dispatch(
        showNotification(i18n.device_forgotten, NOTIFICATION_TYPES.SUCCESS)
      )
      forgetDeviceCallback()
    }

    if (error) {
      dispatch(
        setError(
          data?.errorMessage ||
            errorMessage ||
            'A server error occurred. Unable to forget device at this time'
        )
      )
    }
  }

export const getMFASetupCode =
  (tokens?: { accessToken: string; idToken: string }) =>
  async (dispatch: Dispatch) => {
    dispatch(setError(''))
    dispatch(setLoading(true))

    const { data, errorMessage, error, isOK } = await api.getMFATOTPAppId(
      tokens
    )

    if (isOK && data?.secretCode) {
      dispatch(setTOTPConfig(data.secretCode))
      dispatch(setLoading(false))
    }

    if (error) {
      dispatch(
        setError(
          data?.errorMessage ||
            errorMessage ||
            'A server error occurred. Unable to request new code at this time'
        )
      )
      dispatch(setLoading(false))
      return false
    }
  }

export const verifyTOTP =
  (
    mfaCode: string,
    username: string,
    forgetDeviceCallback?: () => void,
    next?: () => void,
    tokens?: { accessToken: string; idToken: string }
  ) =>
  async (dispatch: any, getStore: any) => {
    const sessionId = tokens?.accessToken
      ? tokens?.accessToken
      : sessionStorage.getItem('access_token') || ''
    const i18n = getStore().translation.languageContent as I18n

    if (!sessionId) {
      dispatch(setError('A server error occurred'))
    }
    const params = {
      mfaCode,
      sessionId,
      username,
    }

    dispatch(setError(''))
    dispatch(setLoading(true))

    const { data, errorMessage, error, isOK } = await api.verifyTOTP(
      params,
      tokens
    )
    dispatch(setLoading(false))
    if (isOK && data?.verifyTOTPResponse?.status?.value === 'SUCCESS') {
      dispatch(
        addMFAPreference(
          '1',
          next
            ? next
            : () =>
                history.push(
                  paths.settingsTab(
                    SettingsTabType.SECURITY,
                    SecuritySettingsType.MFA
                  )
                ),
          forgetDeviceCallback,
          tokens
        )
      )
    }

    if (error) {
      dispatch(
        setError(
          i18n.mfa_error_invalid_code_message ||
            error ||
            'TThe verification code you entered does not match. Please try again.'
        )
      )
      return false
    }
  }

export const addMFAPreference =
  (
    type: string,
    success: () => void,
    forgetDeviceCallback?: () => void,
    tokens?: { accessToken: string; idToken: string }
  ) =>
  async (dispatch: any, getStore: any) => {
    const i18n = getStore().translation.languageContent as I18n
    const sessionId = tokens?.accessToken
      ? tokens?.accessToken
      : sessionStorage.getItem('access_token') || ''
    const params = {
      sessionId,
    }
    dispatch(setError(''))
    dispatch(setLoading(true))

    const { data, errorMessage, error, isOK } = await api.setupMFAChoice(
      params,
      type,
      tokens
    )

    if (isOK && data?.httpStatusCode === 200) {
      const email = localStorage.getItem('email')
      dispatch(resetError())
      if (forgetDeviceCallback) {
        forgetDeviceCallback()
      }
      dispatch(showNotification(i18n.changes_saved, NOTIFICATION_TYPES.SUCCESS))
      if (type === '0') {
        if (tokens?.accessToken && email) {
          lsClient.setUserLSbyEmail('mfa', MFAOptions.SMS, email)
        } else lsClient.setUserLS('mfa', MFAOptions.SMS)
      } else if (type === '1') {
        if (tokens?.accessToken && email) {
          lsClient.setUserLSbyEmail('mfa', MFAOptions.TOTP, email)
        } else lsClient.setUserLS('mfa', MFAOptions.TOTP)
      }
      dispatch(setLoading(false))
      success()
    }

    if (error) {
      dispatch(
        setError(
          data?.errorMessage ||
            errorMessage ||
            'A server error occurred. Unable to request new code at this time'
        )
      )
      dispatch(setLoading(false))
      return false
    }
  }
export const sendPhoneVerifyCode =
  (params: any, reSend: boolean, i18n: I18n) => async (dispatch: Dispatch) => {
    dispatch(setError(''))
    dispatch(setLoading(true))

    const { data, errorMessage, error, isOK } =
      await api.requestPhoneVerificationV2(params)
    dispatch(setLoading(false))
    if (errorMessage) {
      sessionStorage.removeItem('codeSent')
      dispatch(
        setError(
          data?.errorMessage ||
            errorMessage ||
            'A server error occurred. Unable to send code to your phone number at this time'
        )
      )
      return false
    }

    if (data && isOK) {
      sessionStorage.setItem('codeSent', '1')
      if (reSend) {
        dispatch(setWarning(i18n.email_verification_resent))
      }
      return true
    }
  }

export const verifyPhone =
  (params: any, onSuccess?: () => void) =>
  async (dispatch: Dispatch, getStore: any) => {
    dispatch(setError(''))
    dispatch(setLoading(true))
    const i18n = getStore().translation.languageContent as I18n
    dispatch(setLoading(false))
    const { data, errorMessage, error, isOK } =
      await api.submitPhoneVerificationV2(params)

    if (error) {
      dispatch(
        setError(
          data?.errorMessage ||
            errorMessage ||
            'A server error occurred. Unable to verify phone number at this time'
        )
      )
      return false
    }

    if (data && isOK) {
      if (onSuccess) {
        onSuccess()
      }
      // eslint-disable-next-line no-else-return
    } else if (data && !isOK) {
      if (errorMessage === 'Wrong code') {
        dispatch(setError(i18n.verification_code_invalid_error))
      } else if (errorMessage === 'Attempt limit has been reached!') {
        dispatch(setError(i18n.attempt_limit_reached))
      }
      return false
    }
  }

export const getUserPermission =
  () => async (dispatch: Dispatch, getStore: any) => {
    dispatch(setLoading(true))
    const { data, error } = await api.getUserPermission()
    dispatch(setLoading(false))
    if (data) {
      dispatch(setUserPermission(data))
    } else if (error) {
      dispatch(setError('Sorry, we are unable find permissions for this user'))
    }
  }

export const getLatestResult =
  (userId: string) => async (dispatch: Dispatch, getStore: any) => {
    dispatch(setLoading(true))
    //VCB1
    const result = await api.getLatestResult()
    dispatch(setLoading(false))
    if (result) {
      dispatch(setLatestResult(result))
    }

    //VCB2
    // const {data, error} = await api.getLatestResult(userId)
    // if (data) {

    //   dispatch(setLatestResult(data))
    // }
    // else {
    //     dispatch(setError("Sorry, we are unable get the latest result for this user"))
    //     dispatch(setLoading(false))
    //   }

    // else if (error) {
    //   dispatch(setError("Sorry, we are unable get the latest result for this user"))
    //   dispatch(setLoading(false))
    // }
  }

export const logoutUser = () => async (dispatch: any) => {
  sessionStorage.removeItem('access_token')
  sessionStorage.removeItem('id_token')
  sessionStorage.removeItem('refresh_token')

  localStorage.removeItem('children_list')
  dispatch(setLoggedIn(false))
  await dispatch(doLogout())
}

export const userTenantSliceReducer = userTenantSlice.reducer
export const userTenantReducerName = userTenantSlice.name
interface RootStore {
  [userTenantReducerName]: typeof initialStore
}

export const selectUserPermission = ({ userTenant }: RootStore) =>
  userTenant.permission

export const selectLatestResult = ({ userTenant }: RootStore) =>
  userTenant.latestResult

export const selectIsLoggedIn = ({ userTenant }: RootStore) =>
  userTenant.isLoggedIn

export const selectLoginSessionId = ({ userTenant }: RootStore) =>
  userTenant.loginSessionId

export const selectTOTPSetupCode = ({ userTenant }: RootStore) =>
  userTenant.totpSetupCode

export const selectChallengeType = ({ userTenant }: RootStore) =>
  userTenant.mfaType

export const selectUserLoading = ({ userTenant }: RootStore) =>
  userTenant.isLoading

export const selectMFASetup = ({ userTenant }: RootStore) =>
  userTenant.mfaSetupData

export const selectDevice = ({ userTenant }: RootStore) =>
  userTenant.deviceMetadata

export const selectLoginMFASettings = ({ userTenant }: RootStore) =>
  userTenant.loginMFASettings

export const selectUserError = ({ userTenant }: RootStore) => userTenant.error

export const selectUserWarning = ({ userTenant }: RootStore) =>
  userTenant.warningMessage

export const selectToken = ({ userTenant }: RootStore) => userTenant.buffer
