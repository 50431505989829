import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config'
import { isLoggedIn } from '../../../../libs/helpers'
import { useAuth0 } from '../../../../libs/useAuth0'
import { lsClient } from '../../../../ls-client'
import {
  getNetworkSettings,
  selectNetworkSettings,
} from '../../../../pages/network/model'
import { getProfileData } from '../../../../pages/profile/model'
import { TenantSelectionDialog } from '../../../../pages/tenant-selection/tenant-selection-page'
import {
  selectActiveTenant,
  selectTenantList,
} from '../../../../pages/tenant-selection/tenantSlice'
import { SignupFooter } from './signup-footer'
import { SignupHeader } from './signup-header'

export const SignUpLayout = ({ route }: RouteConfigComponentProps) => {
  const networkSettings = useSelector(selectNetworkSettings)
  const tenantList = useSelector(selectTenantList)
  const [tenantSelectionOpen, setTenantSelectionOpen] = useState(false)
  const reduxTenant = useSelector(selectActiveTenant)
  const tenant =
    lsClient.getUserLSByKey('tenantId') ||
    localStorage.getItem('tenantId') ||
    reduxTenant

  const dispatch = useDispatch()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))

  const { isReady, isUserLogged } = useAuth0({
    closedForAnonymous: true,
    closedForLoggedIn: false,
  })

  useEffect(() => {
    if (!isReady || !isUserLogged) return
    dispatch(getProfileData())
  }, [isReady, isUserLogged])

  useEffect(() => {
    if (!isReady || !isUserLogged) return

    if (!tenant && isLoggedIn() && tenantList) {
      setTenantSelectionOpen(true)
    }
    if (tenant && !networkSettings) {
      dispatch(getNetworkSettings())
    }
  }, [isReady, networkSettings, tenant, tenantList])

  if (!route) return null

  return (
    <LayoutWrapper>
      <SignupHeader />
      <ContentWrapper>{renderRoutes(route.routes)}</ContentWrapper>
      <SignupFooter />
      <TenantSelectionDialog
        open={tenantSelectionOpen}
        dialogOpen={setTenantSelectionOpen}
      />
      <Global styles={styles} />
    </LayoutWrapper>
  )
}
const ContentWrapper = styled.main`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
`
const LayoutWrapper = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const styles = css`
  #root {
    min-height: 100vh;
    display: flex;
  }
`
