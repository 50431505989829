import { LinearProgress, makeStyles } from '@material-ui/core'
import React from 'react'
import { lightenDarkenHexColor } from '../../libs/helpers'
import { getColor } from '../../libs/utils'
import { ColorWithHex } from '../../types/general'

export const ProgressBar = (props: {
  progress?: number
  progressColor?: ColorWithHex
}) => {
  const { progress, progressColor = 'primary' } = props
  const classes = useStyles({ color: progressColor })
  if (!progress) return null

  return (
    <LinearProgress
      className={classes.root}
      variant="determinate"
      value={progress}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    'backgroundColor': ({ color }: { color: ColorWithHex }) =>
      lightenDarkenHexColor(getColor(color, theme), 60),
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: ({ color }: { color: ColorWithHex }) =>
        getColor(color, theme),
    },
  },
}))
