import { Box, Icon, styled, Theme } from '@material-ui/core'
import { Button, Typography } from '../../..'

export const PageWrapper = styled(Box)(
  (props: { backgroundColor?: string; theme: Theme }) => ({
    width: '100%',
    backgroundColor: props.backgroundColor ? props.backgroundColor : '#FFF',
  })
)

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 124,
  justifyContent: 'center',
  maxWidth: 540,
  margin: '0 auto',
  textAlign: 'center',
  [theme.breakpoints.down(600)]: {
    width: '90%',
  },
}))

export const Title = styled(Typography)(
  (props: { backgroundColor?: string; theme: Theme }) => ({
    fontSize: 32,
    fontWeight: 500,
    marginTop: 48,
    marginBottom: 8,

    color: props.backgroundColor ? '#FFF' : '#505358',
  })
)

export const Message = styled(Typography)(
  (props: { backgroundColor?: string; theme: Theme }) => ({
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 24,
    color: props.backgroundColor ? '#FFF' : '#757575',
  })
)

export const ButtonWrapper = styled(Box)(
  (props: { backgroundColor?: string; theme: Theme }) => ({
    display: 'flex',
    backgroundColor: props.backgroundColor ? props.backgroundColor : '#FFF',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '32px 0px',
    position: 'relative',
    width: '100%',
    zIndex: 10,
    [props.theme.breakpoints.down(600)]: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      padding: '12px 0px 24px',
      width: '100%',
    },
  })
)

export const WrappedButton = styled(Button)(
  (props: { backgroundColor?: string; theme: Theme }) => ({
    'margin': '10px auto',
    'width': '100%',
    'borderColor': props.backgroundColor ? '#FFF' : props.backgroundColor,
    'backgroundColor': props.backgroundColor ? '#FFF' : props.backgroundColor,
    'color': props.backgroundColor ? props.backgroundColor : '#FFF',
    '&:hover': {
      borderColor: props.backgroundColor ? '#FFF' : props.backgroundColor,
    },
    [props.theme.breakpoints.down(600)]: {
      width: '90%',
    },
  })
)

export const StyledIcon = styled(Icon)(
  (props: { backgroundColor?: string; theme: Theme }) => ({
    fontSize: 100,
    margin: 48,
    color: props.backgroundColor ? '#FFF' : props.theme.palette.primary.main,
  })
)

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: '#282D37',
  marginBottom: 32,
  fontSize: 36,
  fontWeight: 600,
}))
