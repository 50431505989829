import Box from '@material-ui/core/Box/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  getSavedDeviceValues,
  userHasDeviceCookie,
} from '../../../../../../features/cookies/cookie-helper'
import { selectLanguageContent } from '../../../../../../features/translation'
import {
  forgetMFADevice,
  sendPhoneVerifyCode,
} from '../../../../../../features/user/model/userTenantSlice'
import { AccountSelectionOptions } from '../../../../../../features/user/types/mfa'
import { cleanPhoneNumber } from '../../../../../../libs/utils'
import { lsClient } from '../../../../../../ls-client'
import {
  Button,
  MaskedInput,
  TextField,
  Typography,
} from '../../../../../../ui'
import { TextFieldProps } from '../../../../../../ui/atoms/text-field/text-field'
import { paths } from '../../../../../paths'
import {
  selectProfileData,
  updateUserContact,
} from '../../../../../profile/model'
import { AccountSettingsType, SettingsTabType } from '../../../../type'
import { phoneValidation } from '../../validation-schema'

export const MobileNumber = () => {
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  const dispatch = useDispatch()
  const history = useHistory()

  const profileData = useSelector(selectProfileData)
  const [cookies, setCookie, removeCookie] = useCookies()
  const [edit, setEdit] = useState(false)
  const rememberedDevice = userHasDeviceCookie(profileData.email)
  const tenantId = lsClient.getUserLSByKey('tenantId')

  const onPhoneChange = (e: React.ChangeEvent<{ value: string }>) => {
    const cleanNumber = cleanPhoneNumber(e.target.value)
    formik.setFieldValue('phone', cleanNumber)
  }

  const sendCode = () => {
    const { _id } = profileData
    const data = {
      _id,
      mobileNumber: formik.values.phone,
      mobileCountryCode: '+1',
      tenantId,
      contact: {
        mobileCountryCode: '+1',
        mobileNumber: formik.values.phone,
      },
    }
    dispatch(sendPhoneVerifyCode(data, false, i18n))
  }

  const onCancel = () => {
    setEdit(false)
    formik.setFieldValue('phone', profileData.contact.mobileNumber)
  }

  const onNext = () => {
    sendCode()
    lsClient.setUserLS('contact_update', formik.values.phone)
    history.push(
      paths.settingsTabOption(
        SettingsTabType.ACCOUNT,
        AccountSettingsType.MOBILE_NUMBER,
        AccountSelectionOptions.VERIFY_PHONE
      )
    )
  }

  const forgetDevice = () => {
    const callback = () => {
      setEdit(false)
      removeCookie(`${btoa(profileData.email)}_safe_device`, { path: '/' })
    }
    const accessToken = sessionStorage.getItem('access_token')
    const deviceParams = getSavedDeviceValues(profileData.email)
    const apiParams = {
      ...deviceParams,
      accessToken,
      username: profileData.email,
    }
    dispatch(forgetMFADevice(apiParams, callback, i18n))
  }

  const onSubmit = () => {
    const callback = () => {
      setEdit(false)
    }
    const data = {
      tenantId,
      _id: profileData._id,
      contact: {
        ...profileData.contact,
        mobileCountryCode: '+1',
        mobileNumber: formik.values.phone,
      },
    }
    dispatch(
      updateUserContact(data, true, rememberedDevice ? forgetDevice : callback)
    )
  }

  const formik = useFormik({
    initialValues: { phone: profileData.contact.mobileNumber },
    onSubmit,
    validationSchema: phoneValidation(i18n),
    enableReinitialize: true,
    validateOnMount: true,
  })

  const submitDisabled =
    Boolean(formik.errors.phone) ||
    formik.values.phone === profileData.contact.mobileNumber

  const renderPhone = () => (
    <MaskedInput
      mask="(999) 999-9999"
      maskChar=""
      value={formik.values.phone}
      variant="filled"
      onChange={(e) => onPhoneChange(e)}
      label={i18n.phone_number}
      name={'phone'}
      disabled={!edit}
      onBlur={formik.handleBlur}
      error={formik.touched.phone && Boolean(formik.errors.phone)}
      helperText={(formik.touched.phone && formik.errors.phone) || ''}
      required
    >
      {(inputProps: TextFieldProps) => <TextField {...inputProps} />}
    </MaskedInput>
  )

  return (
    <div className={classes.forgetWrapper}>
      {!edit && (
        <Box className={classes.editBox} onClick={() => setEdit(true)}>
          <Typography color="primary">{i18n.edit_mobile_number}</Typography>
        </Box>
      )}
      <Typography>{i18n.mobile_number_instruction}</Typography>
      <div className={classes.fieldWrapper}>{renderPhone()}</div>
      {edit && (
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            onClick={onCancel}
            className={classes.confirmButton}
          >
            {i18n.cancel_button}
          </Button>
          <Button
            onClick={onNext}
            className={classes.confirmButton}
            disabled={submitDisabled}
          >
            {i18n.save_button}
          </Button>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  forgetWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    gap: 26,
    width: '100%',
  },
  fieldWrapper: {
    width: '50%',
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    justifyContent: 'flex-end',
    [theme.breakpoints.down(600)]: {
      position: 'fixed',
      bottom: '24px',
      left: 0,
      width: '100%',
      flexDirection: 'column',
    },
  },
  confirmButton: {
    maxWidth: 'fit-content',
    [theme.breakpoints.down(600)]: {
      maxWidth: '90vw',
    },
  },
  editBox: {
    marginTop: '-50px',
    alignSelf: 'flex-end',
    cursor: 'pointer',
    [theme.breakpoints.down(600)]: {
      marginTop: '0px',
    },
  },
}))
