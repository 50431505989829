import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { allowedPurposes } from '../../constants'
import { useDialog } from '../../features/dialog/application-dialog'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { translateOptions } from '../../libs/helpers'
import { useAgent } from '../../libs/hooks/useAgent'
import { getCurrentUser } from '../../libs/utils'
import { lsClient } from '../../ls-client'
import { Button, FilledSelect, FilledTextField, RenderAlert } from '../../ui'
import {
  ButtonWrapper,
  PageTitle,
  PageWrapper,
} from '../../ui/templates/generic-screens/helpers/styled-components'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { selectUser } from '../invite-web-registration/model/inviteRegistrationSlice'
import { getNetworkSettings, selectNetworkSettings } from '../network/model'
import { paths } from '../paths'
import { selectProfileData } from '../profile/model'
import { adminDirectSupport, sendMessage } from './model'

const APP_VERSION = process.env.REACT_APP_VERSION

export const ContactSupportPage = (props: any) => {
  const { isDialogWindow, setMessageSent } = props
  const classes = useStyles()
  const profileData = useSelector(selectProfileData)
  const userEmail = getCurrentUser()
  const userParams = useSelector(selectUser)
  const [purpose, setPurpose] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const networkSettings = useSelector(selectNetworkSettings)
  const [error, setError] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const tenant = lsClient.getUserLSByKey('tenantId') || userParams?.tenantId
  const lang = lsClient.getUserLSByKey('lang') || 'en'
  const { openDialog, closeDialog } = useDialog()
  const { device, browser } = useAgent()

  useEffect(() => {
    if (!networkSettings && tenant) {
      dispatch(getNetworkSettings(tenant))
    }
  }, [tenant])

  const invitedUserFullName = userParams?.firstName
    ? `${userParams.firstName} ${userParams.lastName}`
    : undefined
  const submitZendesk = async (content: string) => {
    setLoading(true)
    try {
      const res = (await dispatch(
        sendMessage(
          userParams?.email || profileData.email,
          invitedUserFullName || profileData.fullName,
          purpose,
          message,
          content
        )
      )) as unknown
      setLoading(false)
      handleResponse(res)
    } catch {
      setError(true)
    }
  }

  const handleResponse = (res: any) => {
    if (res && !isDialogWindow) {
      openDialog({
        title: i18n.message_sent,
        body: i18n.will_be_in_touch,
        onConfirm: handleBack,
        confirmationLabel: i18n.return,
      })
    } else if (res && isDialogWindow) {
      setMessageSent(true)
    } else {
      setError(true)
    }
  }

  const adminSettingSupport = async (content: string) => {
    setLoading(true)
    try {
      const res = (await dispatch(
        adminDirectSupport(
          userParams?.email || profileData.email,
          invitedUserFullName || profileData.fullName,
          purpose,
          message,
          content
        )
      )) as unknown
      setLoading(false)
      handleResponse(res)
    } catch {
      setError(true)
    }
  }

  const handleBack = () => {
    const backpath = lsClient.navigation.getBackPath()
    closeDialog()
    if (backpath) {
      lsClient.navigation.removeBackPath()
      history.push(backpath)
    } else history.push(paths.app.dashboard())
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const meta = `\nApp version: ${APP_VERSION}\nSystem version: Web app - ${device} - ${browser}\nTenant: ${tenant}\nName: ${
      profileData.fullName || invitedUserFullName
    }\nEmail: ${userEmail || userParams?.email}\nLanguage: ${lang}`
    networkSettings?.enableZendesk
      ? submitZendesk(meta)
      : adminSettingSupport(meta)
  }

  const handleChangePurpose = (value: string) => {
    setPurpose(value)
  }
  const handleChangeMessage = (value: string) => {
    setMessage(value)
  }

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={handleBack}
      color="#505358"
    >
      <PageWrapper>
        <Box className={classes.content}>
          <PageTitle>{i18n.contact_support}</PageTitle>
          <Form
            handleChangePurpose={handleChangePurpose}
            handleChangeMessage={handleChangeMessage}
            i18n={i18n}
          />
          <ButtonWrapper>
            <Button
              disabled={isLoading || !purpose || !message}
              className={classes.btn}
              onClick={(e) => handleSubmit(e)}
            >
              {i18n.feedback_send}
            </Button>
          </ButtonWrapper>

          <RenderAlert
            open={error}
            onClose={() => setError(false)}
            type="error"
            text={i18n.something_went_wrong_message}
          />
        </Box>
      </PageWrapper>
    </ModuleHeader>
  )
}
const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    margin: '48px auto',
    maxWidth: '500px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '10px',
    [theme.breakpoints.down(600)]: {
      width: '90%',
      margin: '0px auto',
    },
  },
  btn: {
    maxHeight: 50,
    marginTop: 24,
    marginBottom: 30,
    [theme.breakpoints.down(600)]: {
      width: '90%',
    },
  },
  backIcon: {
    marginTop: 5,
    cursor: 'pointer',
  },
  formWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gridGap: '16px',
    maxWidth: '600px',
  },
}))

const Form = ({
  handleChangePurpose,
  handleChangeMessage,
  i18n,
}: {
  handleChangePurpose: any
  handleChangeMessage: any
  i18n: I18n
}) => {
  const classes = useStyles()
  const [value, setValue] = useState('')
  const [purpose, setPurpose] = useState('')
  const onChangePurpose = (e: any) => {
    const selectedPurpose = _.find(allowedPurposes, ['value', e.target.value])
    setPurpose(selectedPurpose ? selectedPurpose.value : '')
    handleChangePurpose(selectedPurpose ? selectedPurpose.value : '')
  }

  const onChangeMessage = (e: any) => {
    const text = e.target.value
    setValue(text)
    handleChangeMessage(text)
  }

  return (
    <Box className={classes.formWrapper}>
      <FilledSelect
        options={translateOptions(allowedPurposes, i18n)}
        onChange={onChangePurpose}
        required
        value={purpose}
        label={i18n.select_purpose}
      />
      <FilledTextField
        label={''}
        required
        type="textarea"
        onChange={onChangeMessage}
        placeholder={i18n.feedback_placeholder}
        isMultiline={true}
        rows={15}
        value={value}
      />
    </Box>
  )
}
