import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { hexToRGBA } from '../../../libs/helpers'
import { getColor } from '../../../libs/utils'
import { ColorWithHex } from '../../../types/general'
import { Checkbox, RoundedCheckbox } from '../../../ui'

interface QuestionnaireCheckboxProps {
  type: 'checkbox' | 'radio'
  color?: ColorWithHex
  checked?: boolean
  onChange?: (e: React.ChangeEvent) => void
  label?: string
  id: string
}
export const QuestionnaireCheckbox: React.FC<QuestionnaireCheckboxProps> = ({
  onChange,
  color = 'primary',
  checked = false,
  label,
  type,
  id,
}) => {
  const classes = useStyles({ color })

  return (
    <FormControlLabel
      id={id}
      control={
        type === 'checkbox' ? (
          <Checkbox color={color} checked={checked} onChange={onChange} />
        ) : (
          <RoundedCheckbox
            color={color}
            checked={checked}
            onChange={onChange}
          />
        )
      }
      label={label}
      labelPlacement={'end'}
      className={
        checked
          ? `${classes.questionnaireCheckbox} checked`
          : classes.questionnaireCheckbox
      }
    />
  )
}

const useStyles = makeStyles((theme) => ({
  questionnaireCheckbox: {
    'display': 'flex',
    'justifyContent': 'flex-start',
    'alignItems': 'flex-start',
    'padding': '12px 12px 12px 24px',
    'borderRadius': '8px',
    'margin': '0px auto',
    'width': '90%',
    'backgroundColor': '#fff',
    'transition': 'all .03s ease-in-out',
    '& span:nth-child(1)': {
      padding: 0,
    },
    '& span:nth-child(2)': {
      fontWeight: 500,
    },
    '&.checked': {
      transition: 'all .03s ease-in-out',
      backgroundColor: ({ color }: { color: ColorWithHex }) =>
        hexToRGBA(getColor(color, theme), 0.1),
    },
    [theme.breakpoints.up(960)]: {
      '&:hover': {
        transition: 'all .03s ease-in-out',
        backgroundColor: ({ color }: { color: ColorWithHex }) =>
          hexToRGBA(getColor(color, theme), 0.05),
      },
    },
  },
}))
