import { Dialog } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDialog } from '../../features/dialog/application-dialog'
import { selectLanguageContent } from '../../features/translation'
import { ContactSupportPage } from './page'

export const SupportDialog = (props: {
  open: boolean
  setSupportDialogOpen: any
}) => {
  const { open, setSupportDialogOpen } = props
  const [messageSent, setMessageSent] = useState(false)
  const { openDialog, closeDialog } = useDialog()
  const i18n = useSelector(selectLanguageContent)

  const handleBack = () => {
    closeDialog()
    setSupportDialogOpen(false)
  }

  useEffect(() => {
    if (messageSent) {
      openDialog({
        title: i18n.message_sent,
        body: i18n.will_be_in_touch,
        onConfirm: handleBack,
        confirmationLabel: i18n.return,
      })
    }
  }, [messageSent])

  return (
    <Dialog open={open} fullScreen>
      <ContactSupportPage
        setSupportDialogOpen={setSupportDialogOpen}
        setMessageSent={setMessageSent}
        isDialogWindow
      />
    </Dialog>
  )
}
