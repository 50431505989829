import { Box, Link as MUILink } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { VerificationCode } from '../../../../../features/code-verification/code-verification'
import {
  getSavedDeviceValues,
  userHasDeviceCookie,
} from '../../../../../features/cookies/cookie-helper'
import { selectLanguageContent } from '../../../../../features/translation'
import {
  addMFAPreference,
  forgetMFADevice,
  resetError,
  selectDevice,
  selectUserError,
  selectUserLoading,
  selectUserWarning,
  sendPhoneVerifyCode,
  verifyPhone,
} from '../../../../../features/user/model/userTenantSlice'
import { lsClient } from '../../../../../ls-client'
import { Button, Checkbox, ContentLayout, Typography } from '../../../../../ui'
import { selectNetworkSettings } from '../../../../network/model'
import { paths } from '../../../../paths'
import { selectProfileData } from '../../../../profile/model'
import { SecuritySettingsType, SettingsTabType } from '../../../type'

export const SetupMFASMSPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [cookies, setCookie, removeCookie] = useCookies()
  const i18n = useSelector(selectLanguageContent)
  const profile = useSelector(selectProfileData)
  const error = useSelector(selectUserError)
  const warning = useSelector(selectUserWarning)
  const loading = useSelector(selectUserLoading)
  const [submitReady, setSubmitReady] = useState(false)
  const device = useSelector(selectDevice)
  const [rememberDevice, setRememberDevice] = useState(false)
  const [mfaCode, setMfaCode] = useState('')
  const currentNetwork = useSelector(selectNetworkSettings)
  const hasRememberedDevice = userHasDeviceCookie(profile.email)

  useEffect(() => {
    const codeSent = sessionStorage.getItem('codeSent')
    if (!codeSent && profile?.contact?.mobileNumber) {
      sendCode()
    }
  }, [profile])

  useEffect(() => {
    return () => {
      setMfaCode('')
      setSubmitReady(false)
      sessionStorage.removeItem('codeSent')
    }
  }, [])

  const addSMSPreference = () => {
    dispatch(
      addMFAPreference(
        '0',
        backToSettings,
        hasRememberedDevice && !rememberDevice ? forgetDevice : undefined
      )
    )
  }

  const submitVerifyCode = () => {
    const { tenantId, accountId, email, _id } = profile
    const phone = profile.contact.mobileNumber
    const data = {
      _id,
      phone,
      tenantId,
      accountId,
      email,
      code: mfaCode,
    }
    dispatch(verifyPhone(data, addSMSPreference))
  }

  const sendCode = (reSend = false) => {
    const { _id } = profile
    const phone = profile.contact.mobileNumber
    const data = {
      _id,
      phone,
    }
    dispatch(sendPhoneVerifyCode(data, reSend, i18n))
  }

  const backToSettings = () =>
    history.push(
      paths.settingsTab(SettingsTabType.SECURITY, SecuritySettingsType.MFA)
    )

  const forgetDevice = () => {
    const callback = () => {
      removeCookie(`${btoa(profile.email)}_safe_device`, { path: '/' })
      backToSettings()
    }
    const accessToken = sessionStorage.getItem('access_token')
    const deviceParams = getSavedDeviceValues(profile.email)
    const apiParams = {
      ...deviceParams,
      accessToken,
      username: profile.email,
    }
    dispatch(forgetMFADevice(apiParams, callback, i18n))
  }

  return (
    <Box className={classes.pageWrapper}>
      <ContentLayout className={classes.form}>
        <Typography className={classes.title} variant="h2">
          {i18n.mfa_initial_setup_title}
        </Typography>
        <Typography className={classes.body}>
          {i18n.enter_code_instruction}
        </Typography>
        <VerificationCode
          setVerificationCode={setMfaCode}
          setSubmitReady={setSubmitReady}
          verifyError={error}
          warning={warning}
          clearError={() => dispatch(resetError())}
          submitting={false}
          type="Integer"
          simpleErrorView={true}
        />
        {/* {currentNetwork?.mfaSettings.enableDeviceRemembering &&
          device &&
          !hasRememberedDevice && (
            <div className={classes.checkboxWrapper}>
              <Checkbox
                label={i18n.remember_this_device}
                checked={rememberDevice}
                onChange={() => setRememberDevice(!rememberDevice)}
                name="rememberEmail"
              />
            </div>
          )} */}
        <MUILink
          role="button"
          color="primary"
          className={classes.pageLink}
          onClick={() => sendCode(true)}
        >
          {i18n.sms_verification_resend}
        </MUILink>
        <div className={classes.buttonWrapper}>
          <Button
            color="primary"
            variant="outlined"
            className={classes.actionBtn}
            onClick={backToSettings}
          >
            {i18n.back_button}
          </Button>
          <Button
            color="primary"
            className={classes.actionBtn}
            disabled={!submitReady || loading}
            onClick={submitVerifyCode}
          >
            {i18n.continue_button}
          </Button>
        </div>
      </ContentLayout>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    maxWidth: '900px',
  },
  body: {
    color: '#505358',
    fontSize: '16px',
    fontFamily: 'Avenir Next, Avenir Next Rounded medium',
    fontWeight: 500,
    lineHeight: '22px',
    alignSelf: 'flex-start',
    [theme.breakpoints.down(600)]: {
      margin: ' 0px 20px',
    },
  },
  checkboxWrapper: {
    marginLeft: '18px',
  },
  title: {
    fontWeight: 500,
    marginBottom: '16px',
    alignSelf: 'flex-start',
  },
  buttonWrapper: {
    marginTop: '75px',
    width: '100%',
    paddingBottom: '32px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  form: {
    width: '100%',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    maxWidth: '750px',
  },
  actionBtn: {
    '&:hover': {
      borderColor: '#003C72',
      color: '#003C72',
    },
    'fontSize': '16px',
    'textTransform': 'none',
    'letterSpacing': '2.25px',
    'maxWidth': 'fit-content',
    'width': '100%',
    'justifySelf': 'center',
    'maxHeight': 48,
    'paddingTop': 10,
    'paddingBottom': 10,
    'marginLeft': 24,
    '@media (max-width: 300px)': {
      minWidth: 'unset',
    },
  },
  pageLink: {
    textAlign: 'center',
    marginTop: '48px',
    cursor: 'pointer',
    fontWeight: 600,
  },
}))
