import { Box, IconButton, InputAdornment } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import React, { RefObject } from 'react'
import { TextField } from '../text-field'

export const SearchField = (props: {
  handleChange: (e: React.ChangeEvent) => void
  placeHolderText?: string
  searchValue: string
  clearSearchValue: () => void
  type: 'text' | 'numeric'
  inputRef?: RefObject<HTMLInputElement>
}) => {
  const classes = useStyles()
  const {
    handleChange,
    searchValue,
    clearSearchValue,
    placeHolderText,
    type = 'text',
    inputRef,
  } = props

  return (
    <Box className={classes.searchFieldWrapper}>
      <TextField
        id="outlined-basic"
        className={classes.searchField}
        placeholder={placeHolderText}
        value={searchValue}
        name="searchbar"
        onChange={(e) => handleChange(e)}
        inputRef={inputRef}
        inputProps={{
          inputMode: type,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: '#666666', fontSize: 20 }} />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {searchValue.length > 0 && (
                <InputAdornment position="end">
                  <IconButton onClick={() => clearSearchValue()}>
                    <CloseIcon style={{ color: '#666666', fontSize: 20 }} />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
    </Box>
  )
}
const NO_BORDER_BOTTOM = '0px solid #FFF'

const useStyles = makeStyles((theme) => ({
  searchFieldWrapper: {
    display: 'flex',
    padding: '16px 16px 16px 24px',
    alignItems: 'center',
    gap: '8px',
    flexShrink: 0,
    alignSelf: 'stretch',
    borderRadius: '100px',
    border: '1px solid #757575',
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
  searchField: {
    'textTransform': 'none',
    'width': '100%',
    'background': '#FFF',
    '& input': {
      padding: '7px 0',
    },
    '& .MuiInput-underline:after': {
      borderBottom: NO_BORDER_BOTTOM,
    },
    '& .MuiInput-underline:before': {
      borderBottom: NO_BORDER_BOTTOM,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: NO_BORDER_BOTTOM,
    },
  },
}))
