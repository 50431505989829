import styled from '@emotion/styled'
import { ButtonBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { HTMLTextComponent } from '../../libs/shared-components'
import { SHADOW } from '../../pages/dashboard/constants'
import { selectNetworkSettings } from '../../pages/network/model'
import { paths } from '../../pages/paths'
import { Typography } from '../../ui'
import { selectLanguageContent } from '../translation'
import { selectOrderStatusMessage } from './model'

export const StatusMessage = () => {
  const history = useHistory()
  const i18n = useSelector(selectLanguageContent)
  const classes = useStyles()
  const orderStatus = useSelector(selectOrderStatusMessage)
  const networkSettings = useSelector(selectNetworkSettings)
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24

  const statusLink = () => {
    if (orderStatus?.actions[0].id === 'RedirectToMobileScreen') {
      switch (orderStatus.actions[0].value) {
        case 'TestOrderDetails': {
          history.push(paths.testResultDetails('latest'))
          break
        }
        case 'Resources': {
          history.push(paths.resources())
          break
        }
        case 'TakeVirtualConsult':
        case 'SymptomChecker': {
          history.push(paths.symptomCheckerIntro())
          break
        }
        case 'GetTested': {
          history.push(paths.app.getTested())
          break
        }
        default:
          return null
      }
    }
  }

  if (!orderStatus?.statusMessage) return null

  return (
    <BtnWrapper>
      <ButtonBase
        onClick={() => statusLink()}
        className={classes.btnbase}
        style={{
          borderRadius: radius,
        }}
      >
        <InfoOutlinedIcon className={classes.icon} />
        <div className={classes.messageText}>
          {orderStatus?.statusMessage ? (
            <HTMLTextComponent HTMLString={orderStatus.statusMessage} />
          ) : (
            <Typography variant="body1">{i18n.message_no_content}</Typography>
          )}
        </div>
        <ChevronRightSharpIcon color="primary" />
      </ButtonBase>
    </BtnWrapper>
  )
}
const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '28px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    },
  },
  btnbase: {
    'marginBottom': '24px',
    'width': '100%',
    'minHeight': '63px',
    'display': 'grid',
    'padding': '2px 35px',
    'gridTemplateColumns': 'min-content 1fr min-content',
    '-webkit-box-shadow': SHADOW,
    '-moz-box-shadow': SHADOW,
    'box-shadow': SHADOW,
    'color': theme.palette.primary.main,
    'boxSizing': 'border-box',
    'backgroundColor': '#fff',
    [theme.breakpoints.down(960)]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '24px',
      padding: '4px 15px',
    },
  },
  messageText: {
    'marginLeft': '8px',
    'display': '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    '& span': {
      fontSize: '24px !important',
      color: '#757575 !important',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px !important',
      },
    },
  },
}))
const BtnWrapper = styled.div`
  margin: 8px 0px;
  @media (max-width: 600px) {
    margin-bottom: 24px;
    max-width: 90vw;
    }
  }
`
